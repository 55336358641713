import { post, get } from '@/utils/request';

// 获取盘点单列表
const getList = (data) => post({
    url: '/store/goods.stock.Taking/takingList',
    data: data
});

// 盘点单详情
const getInfo = (data) => get({
    url: `/store/goods.stock.Taking/getInfo?check_id=` + data,

});

// 盘点单货品详情
const detailsList = (data) => post({
    url: '/store/goods.stock.Taking/detailsList',
    data: data
});

// 盘点（手动/自动）
const handleTak = (data) => post({
    url: '/store/goods.stock.Taking/handleTak',
    data: data
})

// 重置盘点
const update = (data) => post({
    url: '/store/goods.stock.Taking/update',
    data: data
})

// 盘点人
const selectUser = (data) => post({
    url: '/store/authority.user/seleList',
    data: data
})
// 盘点单添加
const create = (data) => post({
    url: '/store/goods.stock.Taking/create',
    data: data
})

// 盘点单审核
const audit = (data) => post({
    url: '/store/goods.stock.Taking/audit',
    data: data
})

// 根据品类，获取属性
const getTakeGoodsItem = (data) => post({
    url: '/store/goods.stock.Taking/getTakeGoodsItem',
    data: data
})

// 盘点单（审核/盘点）
const applyBy = (data) => post({
    url: '/store/goods.stock.Taking/applyBy',
    data: data
})

// 盘点锁定列表
const lockGoodsList = (data) => post({
    url: '/store/goods.stock.Taking/lockGoodsList',
    data: data
})

// 仓库列表
const getWarehouseList = (data) => post({
    url: '/store/common.select/getWarehouseList',
    data: data
})

// 操作日志
const checkLog = (data) => post({
    url: '/store/goods.stock.Taking/checkLog',
    data: data
})

// 货品查询
const getGoodsFind = (data) => post({
    url: '/store/goods.stock.Taking/getGoodsFind',
    data: data
})

// 盘点单打印数据
const getTakingPrintReq = (params) => post({
    url: '/store/goods.stock.Taking/takingPrint',
    data: {
        ...params,
    }
})

export {
    getTakingPrintReq,
    getList,
    getInfo,
    detailsList,
    handleTak,
    update,
    create,
    selectUser,
    getTakeGoodsItem,
    applyBy,
    audit,
    lockGoodsList,
    getWarehouseList,
    checkLog,
    getGoodsFind
}