<template>
  <div>
    <el-dialog
      title="赎回列表"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <div class="info">
        <div class="info-left">
          <div class="info-left-img">
            <img
              :src="orderInfo.member_avatar"
              v-if="orderInfo.member_avatar"
            />
            <img v-else src="../../../../assets/images/no_user.png" />
          </div>
          <div>
            <div style="margin-bottom: 15px">
              会员名称：{{ orderInfo.member_name || "--" }}
            </div>
            <div>手机号：{{ orderInfo.member_phone || "--" }}</div>
          </div>
        </div>
        <div class="info-right">
          <div style="margin-bottom: 15px">
            订单号：<span>{{ orderInfo.order_number || "--" }}</span>
          </div>
          <div>
            下单时间：<span>{{ orderInfo.create_time || "--" }}</span>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        v-if="rowList.length"
        v-loading="loading"
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width"
          >
            <template slot-scope="scope">
              <template
                v-if="
                  item.field_alias !== 'goods_pic' &&
                  item.field_alias !== 'goods_number' &&
                  item.field_alias !== 'goods_name'
                "
              >
                {{
                  scope.row[item.field_alias]
                    ? scope.row[item.field_alias]
                    : "--"
                }}
              </template>
              <template v-if="item.field_alias === 'goods_number'">
                <span>{{ scope.row.goods_number }}</span>
                <!-- <span class="label" v-if="scope.row.isRaw">旧料</span> -->
                <!-- <img v-if="scope.row.isRaw"  src="https://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/goodstag/icon_old_materials.png" /> -->
              </template>
              <template v-if="item.field_alias === 'goods_pic'">
                <img
                  :src="scope.row[item.field_alias]"
                  v-if="scope.row[item.field_alias]"
                  class="img"
                />
                <img
                  v-else
                  src="../../../../assets/images/no_img.png"
                  class="img"
                />
              </template>
              <template v-if="item.field_alias === 'goods_name'">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img :src="item" :key="item" style="margin-left: 5px" />
                  </template>
                </div>
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column
          align="center"
          fixed="right"
          field_alias="operation"
          label="操作"
          width="180"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.goods_status_id === 40">
              <el-button type="text" disabled>添加</el-button>
            </template>
            <template v-else>
              <el-button
                type="text"
                @click="handleAdd(scope.row)"
                v-if="!scope.row.isAdd"
                >添加</el-button
              >
              <el-button
                type="text"
                @click="handleAdd(scope.row)"
                v-else
                disabled
                >已添加</el-button
              >
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="title">已添加的赎回货品</div>
      <el-table
        :data="returnTableData"
        style="width: 100%; border-bottom: 1px solid #ddd"
        border
      >
        <template v-for="item in returnRowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width"
          >
            <template slot-scope="scope">
              <template
                v-if="
                  item.field_alias !== 'goods_pic' &&
                  item.field_alias !== 'goods_pcs' &&
                  item.field_alias !== 'pay_ammout' &&
                  item.field_alias !== 'goods_number' &&
                  item.field_alias !== 'goods_name'
                "
              >
                {{
                  scope.row[item.field_alias]
                    ? scope.row[item.field_alias]
                    : "--"
                }}
              </template>
              <template v-if="item.field_alias === 'goods_number'">
                <span>{{ scope.row.goods_number }}</span>
                <!-- <span class="label" v-if="scope.row.isRaw">旧料</span> -->
                <!-- <img v-if="scope.row.isRaw"  src="https://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/goodstag/icon_old_materials.png" /> -->
              </template>
              <template v-if="item.field_alias === 'goods_pcs'">
                <span v-if="scope.row.goods_number_type_name === '一码一货'">
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </span>
                <div class="input" v-else>
                  <el-input v-model="scope.row.goods_pcs"></el-input>
                </div>
              </template>
              <template v-if="item.field_alias === 'pay_ammout'">
                <div class="input">
                  <el-input v-model="scope.row.pay_ammout"> </el-input>
                </div>
              </template>
              <template v-if="item.field_alias === 'goods_pic'">
                <img
                  :src="scope.row[item.field_alias]"
                  v-if="scope.row[item.field_alias]"
                  class="img"
                />
                <img
                  v-else
                  src="../../../../assets/images/no_img.png"
                  class="img"
                />
              </template>
              <template v-if="item.field_alias === 'goods_name'">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img :src="item" :key="item" style="margin-left: 5px" />
                  </template>
                </div>
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column
          align="center"
          fixed="right"
          field_alias="operation"
          label="操作"
          width="180"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              style="color: #e7541e"
              @click="handleDele(scope.row)"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="title">付款渠道</div>
      <el-form :model="form" label-width="100px">
        <el-form-item label="经办人" required>
          <el-select v-model="form.store_user_id">
            <template v-for="item in employeeList">
              <el-option
                :label="item.realname"
                :value="item.store_user_id"
                :key="item.store_user_id"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="付款方式" required>
          <el-select v-model="form.pay_channel">
            <template v-for="item in payList">
              <el-option
                :label="item.name"
                :value="item.pay_type"
                :key="item.pay_type"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="订单备注">
          <el-input type="textarea" :rows="5" v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="赎回时间" v-if="redemption_order_id">
          <el-date-picker
            v-model="create_time"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <div class="title">结算金额</div>
      <div class="list">
        <div class="list-item">
          <div>赎回金额</div>
          <div>￥{{ total }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onSubmit" :loading="loading2"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import numeral from "numeral";
import {
  getOrderInfoReq,
  getRecOrderGoodsTitleReq as getOrderGoodsTitleReq,
  resetRedemptionDetailsReq,
  resetRedemptionOrderReq,
} from "@/api/order/offlineOrder/list";
import {
  getEmployeeReq,
  createRedeemReq as createReturnReq,
} from "@/api/order/offlineOrder/add";
import { getListReq as getPayListReq } from "@/api/system/settleConfig.js";

export default {
  data() {
    return {
      create_time: "",
      payList: [],
      loading2: false,
      total: "0.00",
      loading: false,
      orderInfo: {}, // 订单信息
      employeeList: [], // 员工列表
      form: {},
      rowList: [],
      returnRowList: [
        { field_text: "序号", field_alias: "order_goods_id" },
        { field_text: "货品图片", field_alias: "goods_pic" },
        { field_text: "货号", field_alias: "goods_number" },
        { field_text: "品名", field_alias: "goods_name", t_width: 250 },
        { field_text: "品类", field_alias: "goods_type_name" },
        { field_text: "货品类型", field_alias: "goods_number_type_name" },
        { field_text: "赎回数量", field_alias: "goods_pcs" },
        // { field_text: '退还积分', field_alias: 'get_integal' },
        { field_text: "回收价", field_alias: "recycle_price" },
        // { field_text: '赎回价', field_alias: 'actual_sale_price' },
        { field_text: "赎回价", field_alias: "pay_ammout" },
      ],
      tableData: [],
      returnTableData: [],
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
    },
    order_id: {
      type: Number,
    },
    redemption_order_id: {
      type: Number,
    },
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (val) {
        }
      },
    },
    returnTableData: {
      handler() {
        this.handleTotal();
      },
      deep: true,
    },
  },
  created() {
    this.getEmployee();
    this.getOrderGoodsTitle();
    this.getPayList();
  },
  methods: {
    getPayList() {
      getPayListReq({
        status: 10,
      }).then((res) => {
        if (res.code === 1) {
          this.payList = res.data;
        }
      });
    },
    // 提交退货单
    onSubmit() {
      // 组装后端需要的数据
      const list = [];
      this.returnTableData.forEach((item) => {
        const obj = {
          goods_number: item.goods_number,
          num: item.goods_pcs,
          pay_ammout: item.pay_ammout,
        };
        list.push(obj);
      });
      // 创建退货单请求
      this.loading2 = true;
      if (!this.redemption_order_id) {
        createReturnReq({
          ...this.form,
          recycle_order_id: this.orderInfo.recycle_order_id,
          client_user_id: this.orderInfo.member_id,
          list,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading2 = false;
              this.$message.success("赎回成功");
              this.$emit("onHideRedeem", "getList");
            }
          })
          .catch(() => {
            this.loading2 = false;
          });
      } else {
        resetRedemptionOrderReq({
          ...this.form,
          recycle_order_id: this.orderInfo.recycle_order_id,
          client_user_id: this.orderInfo.member_id,
          list,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading2 = false;
              this.$message.success("赎回成功");
              this.$emit("onHideRedeem", "getList");
            }
          })
          .catch(() => {
            this.loading2 = false;
          });
      }
    },
    // 计算价钱
    handleTotal() {
      let total = 0;
      this.returnTableData.forEach((item) => {
        total += Number(item.goods_pcs) * Number(item.pay_ammout);
      });
      this.total = numeral(total).format("0.00");
    },
    // 移除
    handleDele(row) {
      const currRow = { ...row, isAdd: false };
      this.tableData.forEach((item, index) => {
        if (item.goods_id === row.goods_id) {
          this.tableData.splice(index, 1, currRow);
        }
      });
      this.returnTableData = this.returnTableData.filter(
        (item) => item !== row
      );
    },
    // 添加赎回商品
    handleAdd(row) {
      const currRow = { ...row, isAdd: true, pay_ammout: row.recycle_price };
      this.tableData.forEach((item, index) => {
        if (item.goods_id === row.goods_id) {
          this.tableData.splice(index, 1, currRow);
        }
      });
      this.returnTableData.push(currRow);
    },
    // 经办人列表
    getEmployee() {
      getEmployeeReq().then((res) => {
        if (res.code === 1) {
          this.employeeList = res.data;
        }
      });
    },
    // 关闭弹窗
    handleClose() {
      this.$emit("onHideRedeem");
    },
    // 获取订单货品表头
    getOrderGoodsTitle() {
      getOrderGoodsTitleReq().then((res) => {
        if (res.code === 1) {
          res.data.forEach((item) => {
            if (item.field_alias === "goods_number") {
              item.t_width = 150;
            } else if (item.field_alias === "goods_name") {
              item.t_width = 300;
            }
          });
          this.rowList = res.data;
          if (!this.redemption_order_id) {
            this.getOrderInfo(this.order_id);
          } else {
            // 反确认详情
            this.resetRedemptionDetails();
          }
        }
      });
    },
    // 获取反确认订单详情
    resetRedemptionDetails() {
      resetRedemptionDetailsReq({
        redemption_order_id: this.redemption_order_id,
      }).then((res) => {
        if (res.code === 1) {
          this.create_time = res.data.recycle.info.create_time;
          this.orderInfo = res.data.recycle.info;
          this.tableData = res.data.recycle.goodsList;
          this.returnTableData = res.data.redemp.goodsList;
          this.returnTableData = this.returnTableData.map((item) => {
            return { ...item, pay_ammout: item.redemption_price, goods_pcs: item.num }
          })
          this.tableData.forEach((item) => {
            this.returnTableData.forEach((data) => {
              if (item.goods_id === data.goods_id) {
                item.isAdd = true;
              }
            });
          });
          this.handleTotal();
          this.form = res.data.recycle.info;
        }
      });
    },
    // 获取订单详情
    getOrderInfo(order_id) {
      this.loading = true;
      getOrderInfoReq(order_id)
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tableData = res.data.goodsIdList.filter((item) => item.isRaw);
            this.orderInfo = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.label {
  text-align: left;
  color: white;
  background: #e7541e;
  width: 38px;
  line-height: 16px;
  padding: 2px 5px;
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: 5px;
  margin-left: 5px;
}
.input {
  /deep/ .el-input__inner {
    text-align: center;
  }
}
.img {
  width: 54px;
  height: 54px;
  margin-top: 5px;
  object-fit: cover;
}
/deep/ .el-textarea__inner {
  width: 40%;
}
.info {
  display: flex;
  align-items: center;
  &-left {
    display: flex;
    align-items: center;
    &-img {
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: #ccc;
      }
    }
  }
  &-right {
    margin-left: 100px;
    div {
      // color: #ccc;
      span {
        color: #000;
        // margin-left: 10px;
      }
    }
  }
}
.title {
  line-height: 40px;
  font-weight: bold;
}
.list {
  &-item {
    display: flex;
    align-items: center;
    div:nth-child(2) {
      margin-left: 20px;
      color: #e7541e;
      font-size: 16px;
    }
  }
}
</style>
