<template>
  <div>
    <div style="background: white" v-if="!visiable">
      <div class="card">
        <el-page-header @back="goBack" content="订单详情"> </el-page-header>
      </div>
      <div style="display: flex; justify-content: flex-end; margin: 20px">
        <!-- <el-button
          type='primary'
          plain
          @click='handleExport'
          icon='el-icon-upload2'
          :loading="exportLoading"
        >
          导出
        </el-button> -->
      </div>
      <div class="list">
        <div class="item">
          <div class="left">订单号</div>
          <div>{{ orderInfo.order_number || "--" }}</div>
        </div>
        <div class="item">
          <div class="left">会员姓名</div>
          <div>{{ orderInfo.member_name || "--" }}</div>
        </div>
        <div class="item">
          <div class="left">主销售员</div>
          <div>{{ orderInfo.main_store_name || "--" }}</div>
          <div class="red">业绩增加{{ orderInfo.main_achievement || 0 }}</div>
        </div>
        <div class="item">
          <div class="left">会员电话</div>
          <div>{{ orderInfo.member_phone || "--" }}</div>
        </div>
        <div class="item">
          <div class="left">辅销售员</div>
          <div>{{ orderInfo.vice_store_name || "--" }}</div>
          <div class="red">业绩增加{{ orderInfo.vice_achievement || 0 }}</div>
        </div>
        <div class="item">
          <div class="left">下单时间</div>
          <div>{{ orderInfo.create_time || "--" }}</div>
        </div>
        <div class="item">
          <div class="left">会员等级</div>
          <span>{{ orderInfo.member_level || "--" }}</span>
          <img
            :src="orderInfo.level_Img"
            v-if="orderInfo.level_Img"
            style="width: auto; height: 20px; margin-left: 10px"
          />
        </div>
        <div class="item">
          <div class="left">收银员</div>
          <div>{{ orderInfo.store_user_name || "--" }}</div>
        </div>
        <div class="item">
          <div class="left">收款方式</div>
          <div>{{ orderInfo.pay_type || "--" }}</div>
        </div>
        <div class="item">
          <div class="left">使用积分</div>
          <div>{{ orderInfo.use_inegral || "--" }}</div>
        </div>
        <div class="item">
          <div class="left">获得积分</div>
          <div>{{ orderInfo.get_inegral || "--" }}</div>
        </div>
        <div class="item">
          <div class="left">订单状态</div>
          <div>{{ orderInfo.order_status_text || "--" }}</div>
        </div>
        <div class="item">
          <div class="left">折扣方式</div>
          <div>{{ orderInfo.discount_type || "--" }}</div>
        </div>
        <div class="item">
          <div class="left">备注</div>
          <div>{{ orderInfo.remark || "--" }}</div>
        </div>
      </div>
      <div class="tabs">
        <el-tabs v-model="activeName">
          <el-tab-pane label="货品明细" name="first">
            <div
              style="
                background: white;
                padding-left: 20px;
                line-height: 50px;
                font-weight: bold;
                font-size: 15px;
              "
              v-if="tableData.length"
            >
              新品
            </div>
            <div class="table">
              <el-table
                v-loading="loading"
                :data="tableData"
                style="width: 100%"
                @row-dblclick="toGoodDetail"
                v-if="tableData.length"
              >
                <template v-for="item in rowList">
                  <el-table-column
                    :key="item.field_alias"
                    :prop="item.field_alias"
                    :label="item.field_text"
                    :width="item.field_alias === 'goods_name' ? 200 : 120"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <template
                        v-if="
                          item.field_alias !== 'goods_pic' &&
                          item.field_alias !== 'goods_name'
                        "
                      >
                        {{
                          scope.row[item.field_alias] ||
                          scope.row[item.field_alias] === 0
                            ? scope.row[item.field_alias]
                            : "--"
                        }}
                      </template>
                      <template v-if="item.field_alias === 'goods_pic'">
                        <el-image
                          :src="scope.row[item.field_alias]"
                          :preview-src-list="[scope.row[item.field_alias]]"
                          v-if="scope.row[item.field_alias]"
                          class="img"
                        />
                        <img
                          v-else
                          src="../../../../assets/images/no_img.png"
                          class="img"
                        />
                      </template>
                      <template v-if="item.field_alias === 'goods_name'">
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          {{ scope.row.goods_name }}
                          <div style="display: flex; margin-left: 5px">
                            <template v-for="item in scope.row.goods_tag_pic">
                              <img
                                :src="item"
                                :key="item"
                                style="margin-right: 5px"
                              />
                            </template>
                          </div>
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                </template>
                <el-table-column label="操作" align="center" fixed="right">
                  <template slot-scope="scope">
                    <el-button type="text" @click="toGoodDetail(scope.row)"
                      >详情</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              style="
                background: white;
                padding-left: 20px;
                line-height: 50px;
                font-weight: bold;
                font-size: 15px;
              "
              v-if="tableData2.length"
            >
              旧料
            </div>
            <div class="table">
              <el-table
                v-loading="loading"
                :data="tableData2"
                style="width: 100%"
                @row-dblclick="toGoodDetail"
                v-if="tableData2.length"
              >
                <template v-for="item in rowList2">
                  <el-table-column
                    :key="item.field_alias"
                    :prop="item.field_alias"
                    :label="item.field_text"
                    :width="item.field_alias === 'goods_name' ? 200 : 120"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <template
                        v-if="
                          item.field_alias !== 'goods_pic' &&
                          item.field_alias !== 'goods_name'
                        "
                      >
                        {{
                          scope.row[item.field_alias] ||
                          scope.row[item.field_alias] === 0
                            ? scope.row[item.field_alias]
                            : "--"
                        }}
                      </template>
                      <template v-if="item.field_alias === 'goods_pic'">
                        <el-image
                          :src="scope.row[item.field_alias]"
                          v-if="scope.row[item.field_alias]"
                          :preview-src-list="[scope.row[item.field_alias]]"
                          class="img"
                        />
                        <img
                          v-else
                          src="../../../../assets/images/no_img.png"
                          class="img"
                        />
                      </template>
                      <template v-if="item.field_alias === 'goods_name'">
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          {{ scope.row.goods_name }}
                          <div style="display: flex; margin-left: 5px">
                            <template v-for="item in scope.row.goods_tag_pic">
                              <img
                                :src="item"
                                :key="item"
                                style="margin-right: 5px"
                              />
                            </template>
                          </div>
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                </template>
                <el-table-column label="操作" align="center" fixed="right">
                  <template slot-scope="scope">
                    <el-button type="text" @click="toGoodDetail(scope.row)"
                      >详情</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="order" v-if="orderAmount.length">
              <div style="margin-bottom: 10px; font-weight: bold">
                订单金额信息
              </div>
              <template v-for="(item, index) in orderAmount">
                <div :key="index">
                  <template v-if="item.title !== '实收金额'">
                    <span>{{ item.title }}</span>
                    <span>￥{{ item.money }}</span>
                  </template>
                </div>
              </template>
              <el-divider></el-divider>
              <template v-for="(item, index) in orderAmount">
                <div :key="index + orderAmount.length">
                  <template v-if="item.title === '实收金额'">
                    <span>{{ item.title }}</span>
                    <span style="color: #e7541e">￥{{ item.money }}</span>
                  </template>
                </div>
              </template>
            </div>
          </el-tab-pane>
          <el-tab-pane label="调换记录" name="second" v-if="isChange">
            <div class="wrapper">
              <div class="table" style="width: 49.5%">
                <div class="title">调换前的货品</div>
                <el-table :data="beforeData" style="width: 100%">
                  <template v-for="item in exchangeRowList">
                    <el-table-column
                      :key="item.field_alias"
                      :prop="item.field_alias"
                      :label="item.field_text"
                      align="center"
                    >
                      <template slot-scope="scope">
                        {{
                          scope.row[item.field_alias]
                            ? scope.row[item.field_alias]
                            : "--"
                        }}
                      </template>
                    </el-table-column>
                  </template>
                </el-table>
              </div>
              <div class="table" style="width: 49.5%">
                <div class="title">调换后的货品</div>
                <el-table :data="afterData" style="width: 100%">
                  <template v-for="item in exchangeRowList">
                    <el-table-column
                      :key="item.field_alias"
                      :prop="item.field_alias"
                      :label="item.field_text"
                      align="center"
                    >
                      <template slot-scope="scope">
                        {{
                          scope.row[item.field_alias]
                            ? scope.row[item.field_alias]
                            : "--"
                        }}
                      </template>
                    </el-table-column>
                  </template>
                </el-table>
              </div>
            </div>
            <div class="order">
              <div style="margin-bottom: 10px; font-weight: bold">
                订单金额信息
              </div>
              <div>
                <span>原订单实收金额</span>
                <span>￥10.00</span>
              </div>
              <div>
                <span>调换后的订单金额</span>
                <span>￥10.00</span>
              </div>
              <el-divider></el-divider>
              <div>
                <span>补差价</span>
                <span style="color: #e7541e">￥10.00</span>
              </div>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="操作日志" name="third">
          <div class="table">
            <el-table
              :data="logData"
              style="width: 100%"
            >
              <template v-for="item in logRowList">
                <el-table-column
                  :key="item.field_alias"
                  :prop="item.field_alias"
                  :label="item.field_text"
                  align="center">
                  <template slot-scope="scope">
                    {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </div>
        </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
    <Details
      v-if="visiable"
      :goods_id="goods_id"
      @hide="visiable = false"
      :order_id="order_id"
      :recycle_order_id="recycle_order_id"
    />
  </div>
</template>

<script>
import {
  getOrderInfoReq,
  getOrderGoodsTitleReq,
  getOrderInfoRawReq,
} from "@/api/order/offlineOrder/list";
import Details from "@/views/goods/goodsStock/goodsDetailedlist/details/Index";

export default {
  data() {
    return {
      goods_id: "",
      recycle_order_id: "",
      visiable: false,
      exportLoading: false,
      orderAmount: [], // 订单金额信息
      loading: false,
      orderInfo: {}, // 订单信息
      activeName: "first",
      rowList: [],
      rowList2: [],
      exchangeRowList: [
        { field_text: "货品图片", field_alias: "img" },
        { field_text: "品名", field_alias: "img2" },
        { field_text: "货号", field_alias: "img3" },
        { field_text: "品类", field_alias: "img4" },
        { field_text: "标签价", field_alias: "img5" },
      ],
      logRowList: [
        { field_text: "操作时间", field_alias: "img" },
        { field_text: "操作记录", field_alias: "img2" },
        { field_text: "操作人", field_alias: "img3" },
      ],
      tableData: [{}],
      tableData2: [],
      beforeData: [],
      afterData: [],
      logData: [],
    };
  },
  props: {
    order_id: {
      type: Number,
    },
    isChange: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Details,
  },
  created() {
    this.getOrderGoodsTitle();
    this.getOrderGoodsTitle("order_recycle_goodsinfo");
  },
  methods: {
    handleExport() {},
    toGoodDetail(row) {
      this.goods_id = row.goods_id;
      this.recycle_order_id = row.recycle_order_id;
      this.visiable = true;
    },
    // 获取订单货品表头
    getOrderGoodsTitle(config_type) {
      getOrderGoodsTitleReq(config_type).then((res) => {
        if (res.code === 1) {
          if (!config_type) {
            this.rowList = res.data;
            this.getOrderInfo(this.order_id, 0);
          } else {
            this.rowList2 = res.data;
            // this.getOrderInfo(this.order_id, 1);
            this.getOrderInfoRaw(this.order_id);
          }
        }
      });
    },
    // 获取旧料详情
    getOrderInfoRaw(order_id) {
      this.loading = true;
      getOrderInfoRawReq(order_id)
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tableData2 = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getOrderInfo(order_id, isHaveRaw) {
      this.loading = true;
      getOrderInfoReq(order_id, isHaveRaw)
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            // if (!isHaveRaw) {
            //   this.tableData = res.data.goodsIdList;
            // } else {
            //   this.tableData2 = res.data.goodsIdList;
            // }
            this.tableData = res.data.goodsIdList;
            this.orderInfo = res.data;
            this.orderAmount = res.data.orderAmount;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$emit("onHideDetail");
    },
  },
};
</script>

<style lang="less" scoped>
.label {
  text-align: center;
  color: white;
  background: #e7541e;
  // width: 38px;
  line-height: 16px;
  padding: 2px 5px;
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.img {
  width: 54px;
  height: 54px;
  margin-top: 5px;
  object-fit: contain;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  .table {
    .title {
      line-height: 50px;
      text-indent: 20px;
      background: white;
      margin-top: 10px;
      font-weight: bold;
    }
  }
}
.order {
  width: 100%;
  margin-right: 30px;
  padding: 30px;
  box-sizing: border-box;
  background: white;
  div {
    margin-left: auto;
    width: 350px;
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    span:nth-child(1) {
      color: #909399;
    }
  }
}
/deep/ .el-tabs__header {
  margin: 0 !important;
  padding-left: 50px;
}
/deep/ .tabs {
  padding: 0 !important;
}
/deep/ .el-tabs__content {
  background: #ddd;
}
/deep/ .el-tabs__item {
  font-size: 16px;
  height: 60px;
  line-height: 60px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 50px 10px 50px;
  margin-top: 20px;
  .item {
    width: 25%;
    display: flex;
    margin-bottom: 20px;
    padding-right: 30px;
    box-sizing: border-box;
    .red {
      color: #e7541e;
      font-size: 12px;
      margin-left: 10px;
      margin-top: 3px;
    }
    .left::before {
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #2791ff;
      position: absolute;
      top: 10px;
      left: -15px;
    }
    div:nth-child(1) {
      width: 100px;
      color: #909399;
      position: relative;
      flex-shrink: 0;
    }
    div:nth-child(2) {
      color: #565656;
    }
  }
}
</style>
