import { post } from '@/utils/request';
import { P } from '@antv/g2plot';

// 获取入库单列表
const getList = (data) => post({
    url: '/store/goods.EntryCtl/list',
    data: data,
});

// 获取入库单号
const getEntryNumber = (data) => post({
    url: '/store/goods.EntryCtl/getEntryNumber',
    data: data
});

// 审核入库单
const updateState = (data) => post({
    url: '/store/goods.EntryCtl/updateState',
    data: data
})

// 获取入库单详情
const getEntryInfo = (data) => post({
    url: '/store/goods.EntryCtl/getEntryInfo',
    data: data
})

// 获取入库单货品详情
const getEntryGoodsList = (data) => post({
    url: '/store/goods.EntryCtl/getEntryGoodsList',
    data: data
})

// 获取店铺数据
const getMerchantList = (data) => post({
    url: '/store/common.Select/getMerchantList',
    data: data
});

// 获取品类列表
const getGoodsTypeList = (data) => post({
    url: '/store/goods.EntryCtl/getGoodsTypeList',
    data: data
});

// 获取供应商列表
const getSupplierList = (data) => post({
    url: '/store/goods.EntryCtl/getSupplierList',
    data: data
});

// 获取品类字段
const getAttrList = (data) => post({
    url: '/store/goods.AttrCtl/getAttrList',
    data: data
});

// 获取仓库数据
const getWarehouseList = (data) => post({
    url: '/store/goods.EntryCtl/getWarehouseList',
    data: data
})

// 保存入库商品
const createWareHouse = (data) => post({
    url: '/store/goods.EntryCtl/create',
    data: data
})
// 保存入库商品 追加一码多货
const createWareHouseOnemore = (data) => post({
    url: '/store/goods.EntryCtl/addGoodsOnemore',
    data: data
})

// 入库单操作日志
const entryLog = (data) => post({
    url: '/store/goods.EntryCtl/entryLog',
    data: data
})

// 审核入库单
const audit = (data) => post({
    url: '/store/goods.EntryCtl/updateState',
    data: data
})

// 下载入库导入模板
const getImportFile = (data) => post({
    url: '/store/goods.EntryCtl/getImportFile',
    responseType: 'blob',
    data: data
})

// 打印入库单单据
const printEntryBill = (data) => post({
    url: '/store/goods.EntryCtl/printEntryBill',
    data: data
})

// 批量导入
const importGoodsDataByUploadFile = (data) => post({
    url: '/store/goods.EntryCtl/importGoodsDataByUploadFile',
    data: data
})

//上传货品图片
const uploadGoodsPic = (data) => post({
    url: '/store/goods.Goods/uploadGoodsPic',
    data: data
})

// 编辑信息
const updateEntry = (data) => post({
    url: '/store/goods.EntryCtl/editEntry',
    data: data
})

// 删除入库单货品数据
const delEntryGoods = (data) => post({
    url: '/store/goods.EntryCtl/delEntryGoods',
    data: data
})

// 导出货品数据
const exportEntryGoods = (data) => post({
    url: '/store/goods.EntryCtl/exportEntryGoods',
    data: data
})

// 根据货号获取货品信息
const getEntryGoodsInfoByGoodsNumber = (data) => post({
    url: '/store/goods.EntryCtl/getEntryGoodsInfoByGoodsNumber',
    data: data
})

// 货品详情
const getAttrListDesc = (data) => post({
    url: '/store/goods.AttrCtl/getAttrListDesc',
    data: data
})

// 入库单打印数据
const getEntryPrintReq = (params) => post({
    url: '/store/goods.EntryCtl/entryPrint',
    data: {
        ...params,
    }
})
// 自动计算
const getGoodsCalcuReq = (params) => post({
    url: '/store/goods.EntryCtl/goodsCalcu',
    data: {
        ...params
    }
})

// 表头模板列表
const templateList = (data) => post({
    url: '/store/goods.EntryGoodsTitle/list',
    data: data
})

// 表头模板信息
const getTemplateInfo = (data) => post({
    url: '/store/goods.EntryGoodsTitle/getTemplateInfo',
    data: data
})

// 新增表头模板
const addTemplateInfo = (data) => post({
    url: '/store/goods.EntryGoodsTitle/add',
    data: data
})

// 解锁删除
const deleteReverseEntry = (data) => post({
    url: '/store/goods.EntryCtl/deleteReverseEntry',
    data: data
})

// 解锁编辑保存
const reverseCheckEntry = (data) => post({
    url: '/store/goods.EntryCtl/reverseCheckEntry',
    data: data
})

// 审核不通过删除
const deleteEntry = (data) => post({
    url: '/store/goods.EntryCtl/deleteEntry',
    data: data
})


//获取表格配置
const dispose=data=>post({
    // url:'/store/common.Column/indexV2',
    url:'/store/Config.EntryColumn/index',
    data,
})

//保存表格配置
const setDispose=data=>post({
    //url:'/store/common.Column/setV2',
    url:'/store/Config.EntryColumn/set',
    data,
})


//初始化表格
const restoreV2=data=>post({
    //url:'/store/common.Column/restoreV2',
    url:'/store/Config.EntryColumn/restore',
    data,
})


//详情 获取表格配置
const EntryDetailsColumnSet=data=>post({
    url:'/store/Config.EntryDetailsColumn/index',
    data,
})

//详情 表格初始化
const EntryDetailsColumnRestore=data=>post({
    url:'/store/Config.EntryDetailsColumn/restore',
    data
})

//详情 保存表格
const EntryDetailsColumnSave=data=>post({
    url:'/store/Config.EntryDetailsColumn/set',
    data,
})


//批量审批
const batchUpdateState=data=>post({
    url:'/store/goods.EntryCtl/batchUpdateState',
    data,
})

//批量删除
const batchDeleteReverseEntry=data=>post({
    url:'/store/goods.EntryCtl/batchDeleteReverseEntry',
    data,
})

//入库列表导出
const exportList=data=>post({
    url:'/store/goods.EntryCtl/export',
    data,
    responseType: 'blob',
})

const getEntryStatus=data=>post({
  url:'/store/common.select/getEntryStatus',
  data,
})
//详情列表导出
const batchExportEntryGoods=data=>post({
    //url:'/store/goods.EntryCtl/batchExportEntryGoods',
    url:'/store/goods.EntryCtl/exportExcel',
    data,
    responseType:'blob'
})

const configList=data=>post({
    url:'/store/Config.Index/index',
    data,
})

const configSave=data=>post({
    url:'/store/Config.Index/set',
    data,
})

const configRestore=data=>post({
    url:'/store/Config.Index/restore',
    data,
})

//反审核
const reverseEntryBill=data=>post({
    url:'/store/goods.EntryCtl/reverseEntryBill',
    data,
})

//打印次数
const setPrintNums=data=>post({
    url:'/store/goods.EntryCtl/setPrintNums',
    data,
})


//批量导出列表
const exportBatchList=data=>post({
    url:'store/goods.EntryCtl/batchExportEntryGoods',
    data,
    responseType:'blob'
})




export {
    exportBatchList,
    setPrintNums,
    reverseEntryBill,
    configRestore,
    configSave,
    configList,
    EntryDetailsColumnRestore,
    EntryDetailsColumnSet,
    EntryDetailsColumnSave,
    batchExportEntryGoods,
    exportList,
    batchDeleteReverseEntry,
    batchUpdateState,
    restoreV2,
    setDispose,
    dispose,
    getEntryPrintReq,
    getGoodsCalcuReq,
    getList,
    getEntryNumber,
    getMerchantList,
    getGoodsTypeList,
    updateState,
    getSupplierList,
    getAttrList,
    getWarehouseList,
    createWareHouse,
    createWareHouseOnemore,
    getEntryInfo,
    getEntryGoodsList,
    entryLog,
    audit,
    getImportFile,
    printEntryBill,
    importGoodsDataByUploadFile,
    uploadGoodsPic,
    updateEntry,
    delEntryGoods,
    exportEntryGoods,
    getEntryGoodsInfoByGoodsNumber,
    getAttrListDesc,
    templateList,
    getTemplateInfo,
    addTemplateInfo,
    deleteReverseEntry,
    reverseCheckEntry,
    deleteEntry,
    getEntryStatus
}
