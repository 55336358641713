<template>
  <div class="content" style="background: #f6f7fb">
    <template v-if="!dialogVisible && !detailVisible">
      <div class="card" style="display: flex">
        <div class="left" style="flex-shrink: 0; display: flex">
          <el-button
            style="height: 40px;"
            type="primary"
            @click="dialogVisible = true"
            icon="el-icon-plus"
            >新增订单</el-button
          >
          <!-- <el-button type="primary" @click="pickUpVisible = true"
            >提单</el-button
          >
          <el-button
            type="primary"
            plain
            style="margin-right: 10px"
            @click="handleExport"
            icon="el-icon-upload2"
            :loading="loading3"
          >
            导出
          </el-button>
          <LocalPrint
            :tableType="2"
            :show="true"
            :loading="printLoading"
            :printRows="printRows"
            :printData="printData"
            :title="title"
            :dialogVisible="localPrintVisible"
            @onClose="localPrintVisible = false"
            @onPrint="handlePrint"
          /> -->
          <!-- <el-button
            type='primary'
            plain
            icon='el-icon-printer'
          >
            打印
          </el-button> -->
        </div>
        <div class="line"></div>
        <div class="middle" style="flex: 1">
          <Cond
            @onCond="handleCond"
            function_code="orderList"
            @setCond="handleSetCond"
          />
        </div>
        <div class="right">
          <!-- <TableConf function_code="warehouse" @onRowList="handleRowList"/> -->
          <PrintConf function_code="orderPrint" @onRowList="handlePrintRow" />
        </div>
      </div>
      <div class="total">
        <div class="total-right">
          <div class="total-right-item">
            订单总数 <span>{{ orderCount.num || "0" }}</span>
          </div>
          <div class="total-right-item">
            订单总额
            <span>￥{{ orderCount.total_order_amount || "0.00" }}</span>
          </div>
          <div class="total-right-item">
            优惠总额
            <span>￥{{ orderCount.total_discount_amount || "0.00" }}</span>
          </div>
          <div class="total-right-item">
            实收总额 <span>￥{{ orderCount.total_pay_amount || "0.00" }}</span>
          </div>
        </div>
      </div>
      <div class="total" style="border-top: 1px solid #ddd; border-radius: 6px;" v-if="orderCount.goodsTotal.length">
        <div class="total-right">
          <div class="total-right-item" v-for="(item, index) in orderCount.goodsTotal" :key="index">
            {{item.title}} <span>{{ item.val || "0" }}</span>
          </div>
        </div>
      </div>
      <div
        class="table"
        v-loading="loading"
        style="border: 1px solid #ddd; border-radius: 6px; overflow: hidden"
      >
        <el-table
          @row-dblclick="handleDalclick"
          height="615"
          :data="tableData.list"
          style="width: 100%"
          border
          :row-class-name="tableRowClassName"
        >
          <!--
            :span-method="objectSpanMethod"
            @cell-mouse-enter="cellMouseEnter"
          @cell-mouse-leave="cellMouseLeave" -->
          <!-- <el-table-column
            type="selection"
            align="center"
            width="55">
          </el-table-column> -->
          <template v-for="item in rowList">
            <el-table-column
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              align="center"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <template
                  v-if="
                    item.field_alias !== 'goods' &&
                    item.field_alias !== 'member' &&
                    item.field_alias !== 'sale_price' &&
                    item.field_alias !== 'goods_pcs'
                  "
                >
                  <span
                    style="color: #f56c6c"
                    v-if="item.field_alias === 'order_number'"
                  >
                    {{
                      scope.row[item.field_alias]
                        ? scope.row[item.field_alias]
                        : "--"
                    }}
                  </span>
                  <span v-else>
                    {{
                      scope.row[item.field_alias]
                        ? scope.row[item.field_alias]
                        : "--"
                    }}
                  </span>
                </template>
                <template v-if="item.field_alias === 'goods'">
                  <template v-for="(good, goodIndex) in scope.row.goods">
                    <div
                      style="display: flex; padding: 10px"
                      :key="good.order_goods_id"
                      :class="
                        goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                      "
                    >
                      <div>
                        <el-image
                          :src="good.goods_pic"
                          :preview-src-list="[good.goods_pic]"
                          style="width: 80px; height: 80px; object-fit: contain"
                          v-if="good.goods_pic"
                        />
                        <img
                          src="../../../../assets/images/nopic.png"
                          style="width: 80px; height: 80px"
                          v-else
                        />
                      </div>
                      <div style="margin-left: 15px; text-align: left">
                        <div>品名：{{ good.goods_name }}</div>
                        <div>品类：{{ good.goods_type_name }}</div>
                        <div>货号：{{ good.goods_number }}</div>
                        <!-- <div>
                          <el-tag v-if="scope.row.isRaw" type="warning">旧料</el-tag>
                        </div> -->
                        <div style="display: flex">
                          <template v-for="item in good.goods_tag_pic">
                            <img
                              :src="item"
                              :key="item"
                              style="margin-right: 5px"
                            />
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'sale_price'">
                  <template v-for="(good, goodIndex) in scope.row.sale_price">
                    <div
                      :key="goodIndex"
                      :class="
                        goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                      "
                      style="
                        height: 107px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      {{ good }}
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'goods_pcs'">
                  <template v-for="(good, goodIndex) in scope.row.goods_pcs">
                    <div
                      :key="goodIndex"
                      :class="
                        goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                      "
                      style="
                        height: 107px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      {{ good }}
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'member'">
                  <div>{{ scope.row.member_name || "--" }}</div>
                  <div>{{ scope.row.member_phone || "--" }}</div>
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align="center"
            fixed="right"
            field_alias="operation"
            label="操作"
            width="180"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleChange($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                  <el-option label="打印" :value="2"></el-option>
                  <el-option
                    label="退货"
                    :value="3"
                    v-if="scope.row.order_status === 50"
                  ></el-option>
                  <el-option
                    label="赎回"
                    :value="4"
                    v-if="
                      scope.row.rec_number && scope.row.goods_status_id !== 40
                    "
                  ></el-option>
                  <el-option
                    label="解锁"
                    :value="20"
                    v-if="
                      scope.row.order_status === 50 &&
                      !noSockIds.includes(scope.row.order_id)
                    "
                  ></el-option>
                  <el-option
                    label="编辑"
                    :value="21"
                    v-if="noSockIds.includes(scope.row.order_id)"
                  ></el-option>
                  <el-option
                    label="删除"
                    :value="22"
                    v-if="noSockIds.includes(scope.row.order_id)"
                  ></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          :current-page="page"
          background
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.total"
          :page-size="$store.state.pageSizes[0]"
          :page-sizes="$store.state.pageSizes"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </template>
    <el-dialog
      title="打印数据"
      :visible.sync="printVisible"
      width="55%"
      v-loading="infoLoading"
      :before-close="handleClose"
    >
      <el-tabs v-model="printType">
        <el-tab-pane label="非自定义打印" name="noCustom"></el-tab-pane>
        <el-tab-pane label="自定义打印" name="custom"></el-tab-pane>
      </el-tabs>
      <template v-if="printType === 'noCustom' ? true : false">
        <div class="btns">
          <el-button type="primary" v-print="printObj">本地打印</el-button>
          <el-button
            type="primary"
            plain
            @click="yunPrint"
            :loading="yunLoading"
            >云打印</el-button
          >
        </div>
        <!-- <div v-show="false">
          <div id="custom" v-html="htmlNode" ></div>
        </div> -->
        <div id="printTest">
          <div class="title">{{ printData.hierarchy_name }}</div>
          <div class="list">
            <div class="item">
              <div class="left">分店电话：</div>
              <div class="right">{{ printData.shop_phone }}</div>
            </div>
            <div class="item">
              <div class="left">订单编号：</div>
              <div class="right">{{ printData.order_number }}</div>
            </div>
          </div>
          <div class="list">
            <div class="item">
              <div class="left">分店地址：</div>
              <div class="right">{{ printData.shop_address }}</div>
            </div>
            <div class="item">
              <div class="left">日期：</div>
              <div class="right">{{ printData.create_time }}</div>
            </div>
          </div>
          <div class="list">
            <div class="item">
              <div class="left">销售顾问：</div>
              <div class="right">
                {{ printData.main_store_name }} {{ printData.vice_store_name }}
              </div>
            </div>
            <div class="item">
              <div class="left">备注：</div>
              <div class="right">{{ printData.remark || "--" }}</div>
            </div>
          </div>
          <div class="my-table" v-if="printData1.length">
            <div class="table-header flex">
              <div style="flex: 2">货号</div>
              <div style="flex: 2">货品名称</div>
              <div style="flex: 1">金重(g)</div>
              <div style="flex: 1">数量</div>
              <div style="flex: 1">工费</div>
              <div style="flex: 1">金价</div>
              <div style="flex: 1">标签价</div>
              <div style="flex: 1">折扣</div>
              <div style="flex: 1">实收金额</div>
            </div>
            <div
              class="flex"
              v-for="(item, index) in printData1"
              :key="index + 'print'"
            >
              <div style="flex: 2">{{ item.goods_number }}</div>
              <div style="flex: 2">{{ item.goods_name }}</div>
              <div style="flex: 1">
                {{ item.sale_type === 10 ? item.goldweight : "--" }}
              </div>
              <div style="flex: 1">{{ item.goods_pcs }}</div>
              <div style="flex: 1">{{ item.sale_cutfee }}</div>
              <div style="flex: 1">
                {{ item.sale_type === 10 ? item.goldprice : "--" }}
              </div>
              <div style="flex: 1">{{ item.sale_price }}</div>
              <div style="flex: 1">{{ item.discount }}</div>
              <div style="flex: 1">{{ item.actual_sale_price }}</div>
            </div>
            <div class="table-header flex">
              <div style="flex: 2">合计</div>
              <div style="flex: 2"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1">{{ sumObj1.goods_pcs }}</div>
              <div style="flex: 1"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1">{{ sumObj1.actual_sale_price }}</div>
            </div>
          </div>
          <div
            class="my-table"
            style="margin-top: 10px"
            v-if="printData2.length"
          >
            <div class="table-header flex">
              <div style="flex: 2">旧料货号</div>
              <div style="flex: 2">材质成色</div>
              <div style="flex: 1">金重(g)</div>
              <div style="flex: 1">数量</div>
              <div style="flex: 1"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1">旧料金额</div>
            </div>
            <div
              class="flex"
              v-for="(item, index) in printData2"
              :key="index + 'print'"
            >
              <div style="flex: 2">{{ item.goods_number }}</div>
              <div style="flex: 2">{{ item.material_name }}</div>
              <div style="flex: 1">
                {{ item.sale_type === 10 ? item.goldweight : "--" }}
              </div>
              <div style="flex: 1">{{ item.goods_pcs }}</div>
              <div style="flex: 1"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1">{{ item.actual_sale_price }}</div>
            </div>
            <div class="table-header flex">
              <div style="flex: 2">合计</div>
              <div style="flex: 2"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1">{{ sumObj2.goods_pcs }}</div>
              <div style="flex: 1"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1"></div>
              <div style="flex: 1">{{ sumObj2.actual_sale_price }}</div>
            </div>
          </div>
          <!-- <div class="table" v-if="printData1.length" style="max-width: 100%; border-top: 1px solid #ddd; margin-top: 10px;">
          <el-table
            :data="printData1"
            style="width: 100%"
            stripe
            show-summary
            :summary-method="getSummaries"
          >
            <template v-for="item in printRowList1">
              <el-table-column
                :key="item.field_alias"
                :prop="item.field_alias"
                :label="item.field_text"
                align="center"
                :width="item.t_width">
                <template slot-scope="scope">
                  {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                </template>
              </el-table-column>
            </template>
          </el-table>
      </div> -->
          <!-- <div class="table"  v-if="printData2.length" style="max-width: 100%; border-top: 1px solid #ddd; margin-top: 10px;">
          <el-table
            :data="printData2"
            style="width: 100%"
            stripe
            show-summary
            :summary-method="getSummaries"
          >
            <template v-for="item in printRowList2">
              <el-table-column
                :key="item.field_alias"
                :prop="item.field_alias"
                :label="item.field_text"
                align="center"
                :width="item.t_width">
                <template slot-scope="scope">
                  {{scope.row[item.field_alias]}}
                </template>
              </el-table-column>
            </template>
          </el-table>
      </div> -->
          <div class="heji">
            <div class="item">
              <span class="left">收款方式：</span>
              <span>{{ printData.pay_type }}</span>
            </div>
            <div class="item">
              <span class="left">应收金额合计：</span>
              <span>{{ printData.paid_in_amount }}</span>
            </div>
          </div>
          <div class="heji">
            <div class="item">
              <span class="left">合计金额(大写)：</span>
              <span>{{ printData.pain_in_amount_dx }}</span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <Print :type="20" :order_id="currRow.order_id" />
      </template>
    </el-dialog>
    <Add
      v-if="dialogVisible"
      @onAddHide="handleAddHide"
      :order_number="order_number"
      :order_id="order_id"
      :reverse="reverse"
    />
    <PickUp
      :dialogVisible="pickUpVisible"
      @onHidePickUp="handleHidePickUp"
      v-if="pickUpVisible"
    />
    <Detail
      v-if="detailVisible"
      @onHideDetail="handleHideDetail"
      :order_id="currRow.order_id"
    />
    <ReturnGoods
      :dialogVisible="returnVisible"
      @onHideReturn="handleHideReturn"
      :order_id="currRow.order_id"
      v-if="returnVisible"
    />
    <RedeemGoods
      :dialogVisible="redeemVisible"
      @onHideRedeem="handleHideRedeem"
      :order_id="currRow.order_id"
      v-if="redeemVisible"
    />
  </div>
</template>

<script>
import moment from "moment";
import numeral from "numeral";
import storage from "good-storage";
import { getListReq as getPrintListReq } from '@/api/system/print/list';
import { getMyListReq as getListReq, getOrderInfoReq, printOrderReq, myOrderCountReq as orderCountReq, resetOrderDelete } from '@/api/order/offlineOrder/list';
import { resetOrderDetailReq } from "@/api/order/offlineOrder/add";
import Cond from "@/components/cond/Index.vue";
import Add from "./Add.vue";
import PickUp from "./PickUp.vue";
import Detail from "./Detail.vue";
import ReturnGoods from "./ReturnGoods.vue";
import RedeemGoods from "./RedeemGoods.vue";
import Print from '@/components/print/Index.vue';
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";
import { getIsLock } from '@/api/monthSettle';

export default {
  data() {
    const userInfo = storage.get("userInfo");
    return {
      order_id: '',
      reverse: false, // 是否反确认
      noSockIds: [], // 锁定id
      printLoading: false,
      title: '销售单',
      localPrintVisible: false,
      printData: {},
      printRows: [], // 打印的行
      printType: 'noCustom',
      htmlNode: '',
      yunLoading: false,
      sumObj2: {},
      sumObj1: {},
      orderCount: {
        goodsTotal: [],
      },
      printObj2: {
        id: "custom",
        popTitle: "销售订单",
      },
      printObj: {
        id: "printTest",
        popTitle: "销售订单",
      },
      infoLoading: false,
      printVisible: false,
      isCell: false,
      userInfo,
      redeemVisible: false,
      order_number: "", // 提单单号
      loading: false,
      currRow: {}, // 当前行
      returnVisible: false, // 退货弹框
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      cond: {}, // 搜索的配置
      detailVisible: false,
      pickUpVisible: false,
      dialogVisible: false,
      loading3: false,
      multipleSelection: [], // 多选列表
      rowList: [
        { field_text: "序号", field_alias: "index" },
        { field_text: "订单号", field_alias: "order_number", t_width: 250 },
        { field_text: "下单时间", field_alias: "create_time", t_width: 200 },
        { field_text: "货品", field_alias: "goods", t_width: 350 },
        { field_text: "标签价", field_alias: "sale_price", t_width: 120 },
        { field_text: "数量", field_alias: "goods_pcs", t_width: 120 },
        // { field_text: '订单类型', field_alias: 'type' },
        { field_text: "会员", field_alias: "member", t_width: 120 },
        { field_text: "主销售", field_alias: "main_store_name", t_width: 120 },
        { field_text: "辅销售", field_alias: "vice_store_name", t_width: 120 },
        {
          field_text: "订单状态",
          field_alias: "order_status_text",
          t_width: 120,
        },
        {
          field_text: "旧料金额",
          field_alias: "order_raw_amount",
          t_width: 120,
        },
        { field_text: "优惠金额", field_alias: "order_discount", t_width: 120 },
        { field_text: "实收金额", field_alias: "paid_in_amount", t_width: 120 },
        { field_text: "所属门店", field_alias: "hierarchy_name", t_width: 120 },
      ],
      printData: {},
      printRowList1: [
        { field_text: "货号", field_alias: "goods_number" },
        { field_text: "货品名称", field_alias: "goods_name" },
        { field_text: "金重(g)", field_alias: "goldweight" },
        { field_text: "数量", field_alias: "goods_pcs" },
        { field_text: "金价", field_alias: "goldprice" },
        { field_text: "折扣", field_alias: "discount" },
        { field_text: "实收金额", field_alias: "actual_sale_price" },
      ],
      printData1: [],
      printRowList2: [
        { field_text: "旧料货品", field_alias: "goods_number" },
        { field_text: "材质成色", field_alias: "material_name" },
        { field_text: "金重(g)", field_alias: "goldweight" },
        { field_text: "数量", field_alias: "goods_pcs" },
        { field_text: "", field_alias: "sale_price" },
        { field_text: "", field_alias: "goods_pcs1" },
        { field_text: "旧料金额", field_alias: "actual_sale_price" },
      ],
      printData2: [],
      tableData: {
        list: [],
        total: 0,
      },
      activeArr: [],
    };
  },
  components: {
    Print,
    Cond,
    // TableConf,
    Add,
    PickUp,
    Detail,
    ReturnGoods,
    RedeemGoods,
    PrintConf,
    LocalPrint,
  },
  activated() {
    this.getList();
  },
  mounted() {
    if (this.$route.params.order_id) {
      this.detailVisible = true;
      this.currRow.order_id = this.$route.params.order_id;
    }
    if (this.$route.params.type) {
      if (this.$route.params.type === "add") {
        this.dialogVisible = true;
      }
    }
    if (this.$route.params.type) {
      if (this.$route.params.type === "detail") {
        this.currRow.order_id = this.$route.params.key;
        this.detailVisible = true;
      }
    }
  },
  methods: {
    // 反确认
    getResetOrderDetail(row) {
      resetOrderDetailReq({
        order_id: row.order_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.reverse = true;
            this.dialogVisible = true;
            this.order_number = row.order_number;
            this.order_id = row.order_id;
          }
        });
    },
    handlePrint() {
      this.getOrderPrint({ ...this.cond });
    },
    getOrderPrint(cond) {
      this.printLoading = true;
      getOrderPrintReq({ ...cond })
        .then((res) => {
          if (res.code === 1) {
            this.printLoading = false;
            this.currRow.select = '';
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: '打印时间', value: data.print_time, width: '24%' },
              { txt: '门店', value: data.hierarchy_name, width: '24%' },
            ];
            res.data.bottom = [];
            res.data.top.push(arr1);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: '合计',
            };
            const decimal = {};
            const newArr = [];
            this.printData.list.forEach((item) => {
              item.goods.forEach((good, goodIndex) => {
                const newItem = { ...item, ...good };
                if (goodIndex === 0) {
                  newItem.rowspan = item.goods.length;
                }
                newArr.push(newItem);
              });
              this.printRows.forEach((row) => {
                if (row.is_sum) {
                  // 不需要合并的
                  if (!row.is_goods) {
                    if (!sum[row.field_alias]) {
                      sum[row.field_alias] = 0;
                    }
                    if (item[row.field_alias]) {
                      sum[row.field_alias] += Number(item[row.field_alias]);
                      if (!decimal[row.field_alias]) {
                        const arr = String(item[row.field_alias]).split('.');
                        if (arr.length === 2) {
                          decimal[row.field_alias] = arr[1].length;
                        } else {
                          decimal[row.field_alias] = 0;
                        }
                      }
                    }
                  } else {
                    // 需要合并的
                    if (!sum[row.field_alias]) {
                      sum[row.field_alias] = 0;
                    }
                    item.goods.forEach((good) => {
                      if (good[row.field_alias]) {
                        sum[row.field_alias] += Number(good[row.field_alias]);
                        if (!decimal[row.field_alias]) {
                          const arr = String(good[row.field_alias]).split('.');
                          if (arr.length === 2) {
                            decimal[row.field_alias] = arr[1].length;
                          } else {
                            decimal[row.field_alias] = 0;
                          }
                        }
                      }
                    });
                  }
                }
              });
            });
            // 需要保留多少位小数
            Object.keys(sum).forEach((item) => {
              if (item !== 'index') {
                sum[item] = sum[item].toFixed(decimal[item]);
              }
            });
            this.printData.list = newArr;
            this.printData.list.push(sum);
            this.localPrintVisible = true;
          }
        })
        .catch(() => {
          this.printLoading = false;
          this.currRow.select = '';
        });
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    handleCoustomPrint(print_template_local_id) {
      // 自定义打印内容
      getTemplateContentReq({
        order_id: this.currRow.order_id,
        print_template_local_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.htmlNode = res.data;
            this.handleClose();
            this.htmlVisible = true;
          }
        });
    },
    handleDalclick(row) {
      this.currRow = row;
      // 详情
      this.detailVisible = true;
    },
    cellMouseLeave() {
      this.activeArr = [];
    },
    tableRowClassName({ rowIndex }) {
      const arr = this.activeArr;
      for (var i = 0; i < arr.length; i++) {
        if (rowIndex === arr[i]) {
          return "success-row";
        }
      }
    },
    cellMouseEnter(row) {
      this.activeArr = row.activeArr;
    },
    getSums() {
      const obj = {
        goods_pcs: 0,
        actual_sale_price: 0,
      };
      this.printData1.forEach((item) => {
        obj.goods_pcs += Number(item.goods_pcs);
        obj.actual_sale_price += Number(item.actual_sale_price);
      });
      obj.actual_sale_price = numeral(obj.actual_sale_price).format("0.00");
      this.sumObj1 = obj;
    },
    getSums2() {
      const obj = {
        goods_pcs: 0,
        actual_sale_price: 0,
      };
      this.printData2.forEach((item) => {
        obj.goods_pcs += Number(item.goods_pcs);
        obj.actual_sale_price += Number(item.actual_sale_price);
      });
      obj.actual_sale_price = numeral(obj.actual_sale_price).format("0.00");
      this.sumObj2 = obj;
    },
    yunPrint() {
      this.yunLoading = true;
      printOrderReq({
        order_id: this.currRow.order_id,
      })
        .then((res) => {
          this.yunLoading = false;
          this.$notify({
            title: "云打印",
            message: res.return_data,
          });
        })
        .catch(() => {
          this.yunLoading = false;
        });
    },
    getSummaries(param) {
      const { data } = param;
      const sums = ["合计"];
      sums[3] = 0;
      sums[6] = 0;
      data.forEach((item) => {
        sums[3] += Number(item.goods_pcs);
        sums[6] += Number(item.actual_sale_price);
      });
      sums[6] = numeral(sums[6]).format("0.00");
      return sums;
    },
    handleClose() {
      this.currRow.select = "";
      this.currRow = {};
      this.printData = {};
      this.printVisible = false;
      this.printType = 'noCustom';
    },
    // 改变分页条数
    handleSizeChange(page_num) {
      this.page_num = page_num;
      this.getList();
    },
    getList() {
      this.loading = true;
      getListReq({
        ...this.cond,
        page: this.page,
        page_num: this.page_num,
        source: "pcweb",
        channel_type: 10,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            const { data } = res;
            data.order_amount = numeral(data.order_amount).format("0.00");
            data.order_discount = numeral(data.order_discount).format("0.00");
            data.paid_in_amount = numeral(data.paid_in_amount).format("0.00");
            // 处理数据
            // const newArr = [];
            // let rowIndex = 0;
            // data.list.forEach((item, index) => {
            //   if (index > 0) {
            //     rowIndex += data.list[index - 1].goods.length;
            //   }
            //   const activeArr = [];
            //   item.goods.forEach((good, goodIndex) => {
            //     const realIndex = rowIndex + goodIndex;
            //     const obj = { ...good, ...item };
            //     obj.index = index + 1;
            //     if (goodIndex === 0) {
            //       obj.span = item.goods.length;
            //     }
            //     activeArr.push(realIndex);
            //     obj.activeArr = activeArr;
            //     newArr.push(obj);
            //   });
            // });
            // this.tableData = {
            //   ...res.data,
            //   list: newArr,
            // };
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
              item.sale_price = [];
              item.goods_pcs = [];
              item.goods.forEach((good) => {
                item.sale_price.push(good.sale_price);
                item.goods_pcs.push(good.goods_pcs);
              });
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
      orderCountReq({ ...this.cond, source: "pcweb" }).then((res) => {
        if (res.code === 1) {
          this.orderCount = res.data;
        }
      });
    },
    handleHideDetail() {
      this.currRow.select = "";
      this.detailVisible = false;
    },
    handleHidePickUp(order_number) {
      this.pickUpVisible = false;
      if (order_number) {
        this.order_number = order_number;
        this.dialogVisible = true;
      }
    },
    // 新增模块隐藏事件
    handleAddHide(getList) {
      this.dialogVisible = false;
      this.reverse = false;
      // 返回时将提单号清空
      this.order_number = "";
      this.order_id = '';
      if (getList) {
        this.page = 1;
        this.getList();
      }
    },
    handleHideRedeem(getList) {
      this.currRow.select = "";
      this.redeemVisible = false;
      if (getList) {
        this.page = 1;
        this.getList();
      }
    },
    handleHideReturn(getList) {
      this.currRow.select = "";
      this.returnVisible = false;
      if (getList) {
        this.page = 1;
        this.getList();
      }
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    /*
     *  导出
     */
    handleExport() {
      // 导出时间必填且只能三个月范围
      if (!this.cond.create_time) {
        this.$message.error("下单时间必填");
        return;
      }
      const time1 = moment(this.cond.create_time[0]);
      const time2 = moment(this.cond.create_time[1]);
      const days = time2.diff(time1, "day");
      if (days > 93) {
        this.$message.error("下单时间范围最多为三个月");
        return;
      }
      this.loading3 = true;
      // getListReq({ ...this.cond, page: this.page, page_num: this.page_num, export: 2, function_code: 'orderList' })
      orderExportExcelReq({
        ...this.cond,
        source: "pcweb",
      })
        .then((res) => {
          this.loading3 = false;
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const time = new Date().getTime();
          const a = document.createElement("a");
          a.setAttribute("download", `销售订单列表${time}.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.loading3 = false;
        });
    },
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    handleCond(cond) {
      this.page = 1;
      this.cond = { ...cond };
      this.getList();
    },
    handleSetCond(cond) {
      this.page = 1;
      this.cond = { ...cond };
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 获取订单详情
    // getOrderInfo(order_id) {
    //   getOrderInfoReq(order_id)
    //     .then((res) => {
    //       if (res.code === 1) {
    //         console.log(res.data);
    //       }
    //     });
    // },
    // 操作选择触发
    handleChange(e, row) {
      this.currRow = row;
      // 详情
      if (e === 1) {
        this.detailVisible = true;
      }
      // 云打印
      if (e === 2) {
        this.printVisible = true;
        this.infoLoading = true;
        getOrderInfoReq(this.currRow.order_id)
          .then((res) => {
            this.infoLoading = false;
            if (res.code === 1) {
              this.printData = res.data;
              this.printData1 = res.data.goods.filter((item) => {
                return !item.isRaw;
              });
              this.printData2 = res.data.goods.filter((item) => {
                return item.isRaw;
              });
              this.getSums();
              this.getSums2();
            }
          })
          .catch(() => {
            this.infoLoading = false;
            this.loading = false;
          });
      }
      // 退货
      if (e === 3) {
        this.returnVisible = true;
      }
      // 赎回
      if (e === 4) {
        this.redeemVisible = true;
      }
      if (e === 20) {
        // 此处请求接口待定
        getIsLock({
          source: 'order',
          primary_key_id: row.order_id,
        })
          .then((res) => {
            if (res.code === 1) {
              // this.addVisiable = true;
              row.select = '';
              this.noSockIds.push(row.order_id);
              this.$message.success('解锁成功');
            }
          })
          .catch(() => {
            row.select = '';
          });
      }
      if (e === 21) {
        // this.reverse = true;
        // this.dialogVisible = true;
        // this.order_number = row.order_number;
        // this.order_id = row.order_id;
        this.getResetOrderDetail(row);
        row.select = '';
      }
      if (e === 22) {
        this.$confirm('确定要删除该订单吗？删除后将无法恢复，订单货品将会回库。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          resetOrderDelete({
            order_id: row.order_id,
          })
            .then((res) => {
              if (res.code === 1) {
                this.$message.success('删除成功');
                row.select = '';
                this.getList();
              }
            })
          row.select = '';
        }).catch(() => {
          row.select = '';
        });
      }
    },
    objectSpanMethod({ row, columnIndex }) {
      if (
        columnIndex === 0 ||
        columnIndex === 1 ||
        columnIndex === 2 ||
        columnIndex === 6 ||
        columnIndex === 7 ||
        columnIndex === 8 ||
        columnIndex === 9 ||
        columnIndex === 10 ||
        columnIndex === 11 ||
        columnIndex === 12 ||
        columnIndex === 13
      ) {
        if (row.span) {
          return {
            rowspan: row.span,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
  },
};
</script>;

<style lang="less" scoped>
table,
table tr th,
table tr td {
  border: 1px solid #0094ff;
}
.border {
  border-bottom: 1px solid #ddd;
}
/deep/ .el-table__row .cell {
  padding: 0;
}

/deep/.el-table .success-row {
  background: #f5f7fa;
}

.my-table {
  line-height: 42px;
  .table-header {
    background: #eee;
  }
  .flex {
    display: flex;
    div {
      text-align: center;
      font-weight: bold;
    }
  }
}
.heji {
  display: flex;
  line-height: 40px;
  font-size: 14px;
  justify-content: flex-end;
  .item {
    margin-right: 20px;
    flex-direction: end;
    .left {
      font-weight: bold;
    }
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  .item {
    width: 50%;
    // margin-right: 20px;
    display: flex;
    font-size: 14px;
    line-height: 30px;
    .left {
      width: 100px;
      font-weight: bold;
    }
    .right {
      flex: 1;
    }
  }
}
.title {
  margin-top: 20px;
  font-weight: bold;
  line-height: 40px;
  font-size: 16px;
}
/deep/ .cellClass {
  background-color: #ebf7ff;
}
.total {
  // display: flex;
  justify-content: space-between;
  line-height: 42px;
  // padding: 0 20px;
  // background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: white;
  margin-bottom: 10px;
  border-radius: 0px 0px 6px 6px;
  &-left {
    // flex-shrink: 0;
  }
  &-right {
    display: flex;
    flex: 1;
    &-item {
      font-size: 15px;
      // padding: 0 10px;
      width: 25%;
      // border-right: 1px solid #ddd;
      text-align: center;
      color: #909399;
      span {
        font-size: 16px;
        color: #e7541e;
      }
    }
  }
}
.label {
  text-align: center;
  color: white;
  background: #e7541e;
  // width: 38px;
  line-height: 16px;
  padding: 2px 5px;
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
