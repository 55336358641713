import { render, staticRenderFns } from "./ReturnGoods.vue?vue&type=template&id=6e99b5ec&scoped=true&"
import script from "./ReturnGoods.vue?vue&type=script&lang=js&"
export * from "./ReturnGoods.vue?vue&type=script&lang=js&"
import style0 from "./ReturnGoods.vue?vue&type=style&index=0&id=6e99b5ec&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e99b5ec",
  null
  
)

export default component.exports