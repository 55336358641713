<template>
  <div>
    <el-dialog
      title="添加预存"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div class="tabs">
        <div class="tabs-item" @click="currTabs = 1" :class="currTabs === 1? 'active': ''">
          可用({{preList.available.length || 0}})
        </div>
        <div class="tabs-item" @click="currTabs = 2" :class="currTabs === 2? 'active': ''">
          不可用({{preList.unavailable.length || 0}})
        </div>
      </div>
      <div class="radio" v-if="currTabs === 1">
        <el-radio-group v-model="radio">
          <template v-for="item in preList.available">
          <el-radio :label="item.store_prestore_user_id" style="margin-bottom: 10px;" :key="item.store_prestore_user_id">
              <div class="box" :style="item.store_prestore_user_id === radio? {border: '1px solid #409EFF',}: {}">
                <div class="left" :style="item.store_prestore_user_id === radio? {borderRight: '1px dashed #409EFF',}: {}">
                  <div class="price">
                    <template>
                      <div style="font-size: 12px; margin-bottom: 5px;">可抵扣</div>
                      <span>￥</span>{{item.certificate}}<span>.{{item.decimal}}</span>
                    </template>
                  </div>
                  <div class="label">{{item.disc_range}}</div>
                </div>
                <div class="right">
                  <!-- <div>新人专享</div> -->
                  <div style="margin: 10px 0;">{{item.title}}</div>
                  <div class="time">有效期：{{item.date}}</div>
                </div>
              </div>
          </el-radio>
          </template>
        </el-radio-group>
        <div v-if="!preList.available.length" style="text-align: center;">暂无数据</div>
      </div>
      <div class="radio" v-if="currTabs === 2">
        <el-radio-group v-model="radio">
          <template v-for="item in preList.unavailable">
          <el-radio :label="item.store_prestore_user_id" style="margin-bottom: 10px;" :key="item.store_prestore_user_id" disabled>
              <div class="box">
                <div class="left" :style="item.store_prestore_user_id === radio? {borderRight: '1px dashed #409EFF',}: {}">
                  <div class="price">
                    <template>
                      <div style="font-size: 12px; margin-bottom: 5px;">可抵扣</div>
                      <span>￥</span>{{item.certificate}}<span>.{{item.decimal}}</span>
                    </template>
                  </div>
                  <div class="label">{{item.disc_range}}</div>
                </div>
                <div class="right">
                  <!-- <div>新人专享</div> -->
                  <div style="margin: 10px 0;">{{item.title}}</div>
                  <div class="time">有效期：{{item.date}}</div>
                </div>
              </div>
          </el-radio>
          </template>
        </el-radio-group>
        <div v-if="!preList.unavailable.length" style="text-align: center;">暂无数据</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small ">取 消</el-button>
        <el-button type="primary" @click="handleTrue" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currTabs: 1,
      radio: '',
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    preList: {
      type: Object,
      default() {
        return {
          available: [],
          unavailable: [],
        };
      },
    },
    clearCard: {
      type: Boolean,
    },
    money: {
      type: [String, Number],
    },
    cardId: {
      type: [String, Number],
    },
  },
  created() {
    if (this.clearCard) {
      this.radio = '';
    }
    if (this.cardId) {
      this.radio = this.cardId;
    }
  },
  methods: {
    handleTrue() {
      if (!this.radio) {
        this.$message.warning('请选择预存');
        return;
      }
      if (!this.money) {
        this.$message.warning('订单金额为0不可使用预存抵扣');
        return;
      }
      if (this.currTabs === 1) {
        const arr = this.preList.available.filter((item) => item.store_prestore_user_id === this.radio);
        this.$emit('onChoosePre', arr[0]);
      }
      if (this.currTabs === 2) {
        const arr = this.preList.unavailable.filter((item) => item.store_prestore_user_id === this.radio);
        this.$emit('onChoosePre', arr[0]);
      }
      this.$emit('onHidePre');
    },
    handleClose() {
      this.$emit('onHidePre');
    },
  },
};
</script>

<style lang="less" scoped>
 /deep/ .el-button--primary {
  height: auto !important;
}
.radio {
  margin-top: 10px;
  // padding-left: 10px;
  /deep/ .el-radio {
    display: flex;
    align-items: center;
  }
  /deep/ .el-radio__label  {
    display: block;
  }
  .box {
    border: 1px solid #eee;
    width: 500px;
    height: 100px;
    border-radius: 10px;
    display: flex;
    .left {
      width: 35%;
      border: 1px dashed #eee;
      padding: 0 10px;
      box-sizing: border-box;
      .price {
        color: #409EFF;
        font-size: 25px;
        text-align: center;
        margin: 30px 0 5px 0;
        span {
          font-size: 16px;
        }
      }
      .label {
        color: #ccc;
        font-size: 12px;
        text-align: center;
      }
    }
    .right {
      width: 65%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;
      .time {
        color: #ccc;
        font-size: 12px;
      }
    }
  }
}
.tabs {
  border-bottom: 1px solid #eee;
  display: flex;
  padding-bottom: 15px;
  &-item {
    width: 50%;
    text-align: center;
    position: relative;
    cursor: pointer;
  }
  .active {
    color: #409EFF;
    &::after {
      content: '';
      width: 20px;
      height: 4px;
      background: #409EFF;
      bottom: -15px;
      left: 45%;
      position: absolute;
      border-radius: 99px;
    }
  }
}
</style>
