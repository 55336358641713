<template>
  <div>
    <el-button type="primary" @click="handleShow">发放优惠券</el-button>
    <el-dialog title="发放优惠券" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <el-form :model="form" ref="rulesForm" :rules="rules" label-width="150px">

        <el-form-item label="选择优惠券" required="" prop="coupon_ids">
          <el-table height="500px" class="table-box" ref="tabel" :data="couponList" border :fit="true"
            style="width:100%;background:#fff" @selection-change="handleSelectionChange">
            <el-table-column type="selection" fixed="left" width="55"></el-table-column>
            <el-table-column align="left" label="优惠券名称" prop="title_name" fixed="left" width="150px"></el-table-column>
            <el-table-column align="center" min-width="140px" label="编号" prop="sn"></el-table-column>
            <el-table-column align="center" label="券类型" :formatter="getCurrentType" prop="type_seq"></el-table-column>

            <el-table-column align="center" min-width="120" label="券数量（发放）" prop="issue_total"></el-table-column>
            <el-table-column align="center" label="剩余数量" prop="issue_tally">
              <template slot-scope="scope">
                {{ scope.row.issue_total == 0  ? "不限" :
                    (scope.row.issue_total - scope.row.issue_tally)
                }}
              </template>
            </el-table-column>
          </el-table>

        </el-form-item>
        <el-form-item label="每人发放数量" required="" prop="number">
          <el-input v-model="form.number" placeholder="请输入数量" type="number" style="width: 220px;"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleTrue" :loading="saveLoading">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getListReq, getCardReq } from '@/api/marketing/coupon';
import { sendCoupontNew } from "@/api/member/memberList/index.js"
import { mangePlanSelectCouponList } from "@/api/marketing/sendCoupon/index";

export default {
  data() {
    const checkCoupId = (rule, value, callback) => {
      console.log(value, "444")
      if (value.length == 0) {
        return callback(new Error("请选择优惠券"))
      } else {
        return callback();
      }
    }

    const checkNumber = (rule, value, callback) => {
      let min = [];
      if (!this.form.coupon_ids) {
        callback(new Error('请选择优惠券'));
      }
      if (!this.form.number) {
        callback(new Error('数量不能为空'));
      }
      if (!/^[0-9]*[1-9][0-9]*$/.test(this.form.number)) {
        callback(new Error('数量为正整数'));
      }

      min = this.currCoupon.map(item => {
        let num = item.issue_tally == 0 ? 99999999999999999 : (item.issue_total - item.issue_tally)
        return num;
      })

      if (Number(this.form.number) > Math.min.apply(null, min)) {
        callback(new Error('总发放数量不得超过当前可用数量'));
      }

      callback();
    }

    return {
      saveLoading: false,
      currCoupon: [], // 当前选中优惠券
      couponList: [],
      maxLimit: 99999,
      dialogVisible: false,
      form: { coupon_ids: [], number: '' },
      rules: {
        coupon_ids: [
          { validator: checkCoupId, trigger: 'blur' },
          { validator: checkCoupId, trigger: 'change' },
        ],
        number: [
          { validator: checkNumber, trigger: 'blur' },
          { validator: checkNumber, trigger: 'change' }
        ],
      },
    };
  },
  props: {
    client_user_ids: {
      type: Array,
      default() {
        return [];
      },
    },

    client_user_master_ids: {
      type: Array,
      default() {
        return [];
      },
    }
  },

  created() {
    this.getList();
  },

  methods: {
    handleChange(val) {
      this.currCoupon = this.couponList.filter((item) => {
        return val == item.coupon_id;
      })
      this.$refs.rulesForm.validateField('number');
    },

    handleSelectionChange(arr) {
      if (arr.length == 0) {
        this.currCoupon = [];
        this.form.coupon_ids = [];
      }
      this.form.coupon_ids=[];
      this.currCoupon=[];
      arr.forEach(element => {
        this.couponList.forEach(item => {
          if (element.coupon_id == item.coupon_id) {
            this.currCoupon.push(item);
            this.form.coupon_ids.push(item.coupon_id);
          }
        })
      });
      this.$refs.rulesForm.validateField('number');
      
    },

    getCurrentType(row) {
      let typeList = [
        {
          label: "全部",
          value: '0'
        },
        {
          label: "满减券",
          value: 10
        },
        {
          label: "指定折扣",
          value: 20
        },
        {
          label: "随机券",
          value: 30
        },
        {
          label: "礼品券",
          value: 40
        }
      ]
      return typeList.find(item => item.value == row.type_seq)?.label
    },

    // 使用新的优惠券列表接口
    getList() {
      mangePlanSelectCouponList({
        page: 1,
        limit: this.maxLimit,
      })
        .then((res) => {
          console.log(res, "res55636");
          if (res.code === 1) {
            this.couponList = res.data.list;
          }
        })
    },

    handleShow() {
      if (!this.client_user_master_ids.length) {
        this.$message.warning('请先勾选会员');
        return;
      }
      this.dialogVisible = true;
    },

    handleClose() {
      this.dialogVisible = false;
      this.$refs.tabel.clearSelection()
      setTimeout(() => {
        this.form = {coupon_ids:[],num:''};
        this.currCoupon = [];
        this.$refs['rulesForm'].resetFields();
      }, 500);
    },

    handleTrue() {
      this.$refs.rulesForm.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          //发放接口
          sendCoupontNew({
            coupon_ids: this.form.coupon_ids,
            num: this.form.number,
            client_user_master_ids: this.client_user_master_ids,
          })
            .then((res) => {
              this.saveLoading = false;
              if (res.code === 1) {
                this.$message.success('发放成功');
                this.handleClose();
                this.getList();
              }
            })
            .catch(() => {
              this.saveLoading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
