import { post } from '@/utils/request';
const getListReq = (data) => post({
  url: '/store/goods.GoodsType/list',
  data,
});

const getListReq2 = (data) => post({
  url: '/store/common.select/getAllGoodsType',
  data,
});

const getGoodsTypeListForTypeReq = (data) => post({
  url: '/store/goods.GoodsType/getGoodsTypeListForType',
  data,
});

const getAttrListReq = () => post({
  url: '/store/goods.AttrCtl/list',
  data: {
    pid: 0,
  },
});

// 创建
const createReq = (params) => post({
  url: '/store/goods.GoodsType/create',
  data: {
    ...params,
  },
});

// 获取分类详情
const detailReq = (goods_type_id) => post({
  url: '/store/goods.GoodsType/detail',
  data: {
    goods_type_id,
  },
});

// 删除
const deleteReq = (goods_type_id) => post({
  url: '/store/goods.GoodsType/delete',
  data: {
    goods_type_id,
  },
});

// 编辑
const editReq = (params) => post({
  url: '/store/goods.GoodsType/edit',
  data: {
    ...params,
  },
});

// 当销售方式为销售按克默认选中的属性值
const getGoodsAttrListForSaleweightReq = () => post({
  url: '/store/goods.AttrCtl/getGoodsAttrListForSaleweight',
});

// 日志
const getLogListReq = (params) => post({
  url: '/store/goods.GoodsType/getLogList',
  data: {
    ...params,
  },
});

const getAllGoodsType= (data) => post({
  url: '/store/common.Select/getAllGoodsType',
  data,
})

export {
  getListReq2,
  getGoodsTypeListForTypeReq,
  getListReq,
  getAttrListReq,
  createReq,
  detailReq,
  deleteReq,
  editReq,
  getGoodsAttrListForSaleweightReq,
  getLogListReq,
  getAllGoodsType
};
