const __tag__ = {
  '1': '退货',
  '2': '旧料',
  '3': '修改中',
  '4': '退库中',
  '5': '拆改中',
  '6': '旧转新',
  '7': '代付款',
  '8': '赠品',
  '9': '活动锁定',
  '10': '调拨在途',
  '11': '挂单',
  '12': '盘点锁定',
};

export default __tag__;
