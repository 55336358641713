import { post } from '@/utils/request';

// 货品列表数据
const getIndexReq = (params) => post({
  url: '/store/goods.Search/index',
  data: {
    ...params,
  },
});

// 入库列表数据
const getEntryListReq = (params) => post({
  url: '/store/goods.Search/entryList',
  data: {
    ...params,
  },
});

// 盘点数据列表
const getTakeListReq = (params) => post({
  url: '/store/goods.Search/takeList',
  data: {
    ...params,
  },
});

// 修改单数据列表
const getAmendListReq = (params) => post({
  url: '/store/goods.Search/amendList',
  data: {
    ...params,
  },
});

// 销库单数据列表
const getDestroyListReq = (params) => post({
  url: '/store/goods.Search/destroyList',
  data: {
    ...params,
  },
});

// 调拨单数据列表
const getAllotListReq = (params) => post({
  url: '/store/goods.Search/allotList',
  data: {
    ...params,
  },
});

// 拆改单数据列表
const getSplitListReq = (params) => post({
  url: '/store/goods.Search/splitList',
  data: {
    ...params,
  },
});

// 销售单数据列表
const getOrderListReq = (params) => post({
  url: '/store/goods.Search/orderList',
  data: {
    ...params,
  },
});

// 退货单数据列表
const getRefundListReq = (params) => post({
  url: '/store/goods.Search/refundList',
  data: {
    ...params,
  },
});

// 回收单数据列表
const getRecycleListReq = (params) => post({
  url: '/store/goods.Search/recycleList',
  data: {
    ...params,
  },
});

// 赎回单数据列表
const getRedemptionListReq = (params) => post({
  url: '/store/goods.Search/redemptionList',
  data: {
    ...params,
  },
});

// 调仓数据列表
const getAllotWarehouseListReq = (cond) => post({
  url: '/store/goods.Search/allotWarehouseList',
  data: {
    ...cond,
  },
});

export {
  getIndexReq,
  getEntryListReq,
  getTakeListReq,
  getAmendListReq,
  getDestroyListReq,
  getAllotListReq,
  getSplitListReq,
  getOrderListReq,
  getRefundListReq,
  getRecycleListReq,
  getRedemptionListReq,
  getAllotWarehouseListReq,
};
