<template>
  <el-dialog
    :fullscreen="false"
    title="筛选货品"
    :visible.sync="goodVisibel"
    width="90%"
    :before-close="handleClose"
  >
    <div style="display: flex">
      <div :style="{ height: height }" class="wrapper">
        <el-form
          :inline="true"
          :model="formInline"
          label-width="100px"
          class="demo-form-inline"
          style="overflow: hidden"
          id="middle"
        >
          <template v-for="item in formItemList">
            <el-form-item :key="item.attr" :label="item.label">
              <!-- <div style="color: #ccc" v-if="item.type === 'text'">{{
                item.text
              }}</div> -->
              <el-select
                disabled
                v-if="item.type === 'text'"
                :value="item.text"
              ></el-select>
              <el-input
                :placeholder="item.placeholder"
                v-model="formInline[item.attr]"
                v-if="item.type === 'input'"
                style="width: 222px"
                clearable
              >
              </el-input>
              <el-select
                v-model="formInline['100']"
                placeholder="请选择材质成色"
                filterable
                v-if="item.type === '100'"
                clearable
              >
                <template v-for="item in materialList">
                  <el-option
                    :label="item.attr_value"
                    :value="item.attr_value"
                    :key="item.attr_value_id"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-model="formInline['101']"
                placeholder="请选择款式"
                filterable
                v-if="item.type === '101'"
                clearable
              >
                <template v-for="item in patternList">
                  <el-option
                    :label="item.attr_value"
                    :value="item.attr_value"
                    :key="item.attr_value_id"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-model="formInline['hierarchy_id']"
                placeholder="请选择所属门店"
                filterable
                v-if="item.type === 'hierarchy_id'"
                clearable
              >
                <template v-for="item in merchantList">
                  <el-option
                    :label="item.hierarchy_name"
                    :value="item.hierarchy_id"
                    :key="item.hierarchy_id"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-model="formInline['warehouse_id']"
                placeholder="请选择仓库"
                filterable
                v-if="item.type === 'warehouse_id'"
                :disabled="item.disabled"
              >
                <template v-for="item in warehouseList">
                  <el-option
                    :label="item.warehouse_name"
                    :value="item.warehouse_id"
                    :key="item.warehouse_id"
                  >
                  </el-option>
                </template>
              </el-select>
              <div v-if="item.type === 'sale_price'" style="display: flex">
                <el-input
                  :placeholder="'请输入标签价范围'"
                  v-model="formInline.sale_price[0]"
                  type="number"
                  style="width: 222px"
                  clearable
                >
                </el-input>
                <div style="margin: 0 48px">至</div>
                <el-input
                  :placeholder="'请输入标签价范围'"
                  v-model="formInline.sale_price[1]"
                  type="number"
                  style="width: 222px"
                  clearable
                >
                </el-input>
              </div>
              <div v-if="item.type === '104'" style="display: flex">
                <el-input
                  :placeholder="'请输入净金重范围'"
                  v-model="formInline['104'][0]"
                  style="width: 222px"
                  type="number"
                  clearable
                >
                </el-input>
                <div style="margin: 0 48px">至</div>
                <el-input
                  :placeholder="'请输入净金重范围'"
                  v-model="formInline['104'][1]"
                  style="width: 222px"
                  type="number"
                  clearable
                >
                </el-input>
              </div>
              <el-select
                v-model="formInline['103']"
                placeholder="请选择品牌"
                filterable
                v-if="item.type === '103'"
                clearable
              >
                <template v-for="item in brandList">
                  <el-option
                    :label="item.band_name"
                    :value="item.band_name"
                    :key="item.band_id"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-date-picker
                v-if="item.type === 'entry_time'"
                v-model="formInline.entry_time"
                type="daterange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <SelectTree
                :disabled="item.disabled"
                v-if="item.type === 'treeSelect'"
                :props="props"
                :value="valueId"
                :options="treeData"
                :options2="treeData2"
                :clearable="isClearable"
                :accordion="isAccordion"
                label1="商品分类"
                label2="旧料分类"
                @getValue="getValue($event, item.attr)"
              />
            </el-form-item>
          </template>
          <!-- <el-form-item>
            <el-button @click="handleReset">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
          </el-form-item> -->
        </el-form>
      </div>
      <el-button
        @click="handleHeight"
        style="
          width: 40px;
          height: 40px;
          line-height: 36px;
          text-align: center;
          padding: 0;
        "
      >
        <i class="el-icon-caret-bottom" v-if="height === '55px'"></i>
        <i class="el-icon-caret-top" v-else></i>
      </el-button>
      <el-button type="primary" @click="onSubmit" style="height: 40px"
        >搜索</el-button
      >
      <el-button @click="handleReset" style="height: 40px">重置</el-button>
    </div>
    <div class="table" style="border-top: 1px solid #ddd">
      <el-table
        use-virtual
        :border="false"
        :data="goodsData.list"
        row-key="index"
        stripe
        style="width: 100%"
        v-loading="loading"
        height="300px"
        :row-class-name="handleRow"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
          fixed="left"
          :selectable="handleSelectable"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column prop="index" label="序号" align="center" width="50">
        </el-table-column>
        <el-table-column prop="warning_state" label="滞销预警" align="center">
          <template slot-scope="scope">
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="警报等级依次为：绿<黄<橙<红"
                placement="top-start"
                v-if="
                  scope.row.warning_state !== 10 &&
                  scope.row.warning_state !== 20 &&
                  scope.row.warning_state !== 30
                "
              >
                <div
                  style="
                    background-color: #21bc36;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin: 0 auto;
                  "
                ></div>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="警报等级依次为：绿<黄<橙<红"
                placement="top-start"
                v-else-if="scope.row.warning_state === 10"
              >
                <div
                  style="
                    background-color: #ffdf04;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin: 0 auto;
                  "
                ></div>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="警报等级依次为：绿<黄<橙<红"
                placement="top-start"
                v-else-if="scope.row.warning_state === 20"
              >
                <div
                  style="
                    background-color: #ff9000;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin: 0 auto;
                  "
                ></div>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="警报等级依次为：绿<黄<橙<红"
                placement="top-start"
                v-else-if="scope.row.warning_state === 30"
              >
                <div
                  style="
                    background-color: #f4390d;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin: 0 auto;
                  "
                ></div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goods_pic" label="货品图片" align="center">
          <template slot-scope="scope">
            <el-image
              style="width: 40px; height: 40px"
              :src="scope.row.goods_pic"
              :preview-src-list="[scope.row.goods_pic]"
            >
              <div slot="error" class="image-slot">
                <img :src="noPic" style="width: 40px; height: 40px" />
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="entry_number"
          label="入库单号"
          align="center"
          width="210"
        ></el-table-column>
        <el-table-column
          prop="goods_number"
          label="货号"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.goods_number || "--" }}
            <!-- <template v-for="(item, index) in scope.row.goods_tag_array">
              <el-tag type="danger" :key="index" style="margin-left: 5px">{{
                item
              }}</el-tag>
            </template> -->
          </template>
        </el-table-column>
        <el-table-column prop="goods_style_number" label="款号" align="center">
          <template slot-scope="scope">
            {{ scope.row.goods_style_number || "--" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_name"
          align="center"
          label="品名"
          width="200"
        >
          <template slot-scope="scope">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              {{ scope.row.goods_name || "--" }}
              <template v-for="item in scope.row.goods_tag_pic">
                <img
                  :src="item"
                  :key="item"
                  style="margin-left: 5px; height: 15px"
                />
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_number_type"
          align="center"
          label="货品类型"
        >
          <template slot-scope="scope">
            {{
              scope.row.goods_number_type === 10
                ? "一码一货"
                : scope.row.goods_number_type === 20
                ? "一码多货"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sales_type_name"
          label="销售方式"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.sales_type_name || "--" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="usable_count"
          label="可操作数量"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="hierarchy_name"
          align="center"
          label="所属门店"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="warehouse_name"
          align="center"
          label="仓库"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="stock"
          align="center"
          label="库存"
        ></el-table-column>
        <el-table-column prop="sale_price" align="center" label="标签价">
          <template slot-scope="scope">
            {{ scope.row.sale_price || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="184" align="center" label="销售工费">
          <template slot-scope="scope">
            {{ scope.row["184"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="185" align="center" label="销售工费类型">
          <template slot-scope="scope">
            {{ scope.row["185"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="101" align="center" label="款式">
          <template slot-scope="scope">
            {{ scope.row["101"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="100" align="center" label="材质成色">
          <template slot-scope="scope">
            {{ scope.row["100"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="104" align="center" label="净金重">
          <template slot-scope="scope">
            {{ scope.row["104"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="130" align="center" label="主石重">
          <template slot-scope="scope">
            {{ scope.row["130"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="133" align="center" label="辅石1重量">
          <template slot-scope="scope">
            {{ scope.row["133"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="112" align="center" label="证书号">
          <template slot-scope="scope">
            {{ scope.row["112"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="entry_time"
          align="center"
          label="入库时间"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row["entry_time"] || "--" }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="goods_type_name" align="center" label="货品分类">
          <template slot-scope="scope">
            {{ scope.row.goods_type_name || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="102" align="center" label="产地">
          <template slot-scope="scope">
            {{ scope.row["102"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="103" align="center" label="品牌">
          <template slot-scope="scope">
            {{ scope.row["103"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="104" align="center" label="金重(g)">
          <template slot-scope="scope">
            {{ scope.row["104"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="110" align="center" label="重量">
          <template slot-scope="scope">
            {{ scope.row["110"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="num" align="center" label="数量">
          <template slot-scope="scope">
            {{ scope.row.num || "--" }}
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    
    <div class="flex-box" style="background: #f6f7fb; padding: 0 10px">
      <!-- <el-button type="primary" icon="el-icon-plus" @click="handleClick">添加</el-button> -->
      <div class="pagina">
        <el-pagination
          background
          :current-page="formInline.page"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
          :total="goodsData.total"
          :page-size="page_num"
          :page-sizes="$store.state.pageSizes"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
    <el-button
      plain
      style="margin-top: 10px"
      size="mini"
      type="primary"
      @click="handleAll"
      :loading="allLoading"
      >所有全选</el-button
    >
    <div class="title">
      已添加的货品
      <el-button
        plain
        style="margin-left: 10px"
        size="mini"
        @click="handleClear"
        >清空货品</el-button
      >
      <span style="font-size: 12px; font-weight: 300">
        已选货品：总净金重{{ total["104"] }}g、总金重(毛)
        {{ total["130"] }}g、总主石重{{ total["1446"] }}g
      </span>
    </div>
    <div class="table" style="border-top: 1px solid #ddd">
      <el-table :data="addList" stripe style="width: 100%" height="300px" use-virtual :row-class-name="handleRow">
        <el-table-column prop="index" label="序号" align="center" width="50">
        </el-table-column>
        <el-table-column prop="warning_state" label="滞销预警" align="center">
          <template slot-scope="scope">
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="警报等级依次为：绿<黄<橙<红"
                placement="top-start"
                v-if="
                  scope.row.warning_state !== 10 &&
                  scope.row.warning_state !== 20 &&
                  scope.row.warning_state !== 30
                "
              >
                <div
                  style="
                    background-color: #21bc36;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin: 0 auto;
                  "
                ></div>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="警报等级依次为：绿<黄<橙<红"
                placement="top-start"
                v-else-if="scope.row.warning_state === 10"
              >
                <div
                  style="
                    background-color: #ffdf04;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin: 0 auto;
                  "
                ></div>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="警报等级依次为：绿<黄<橙<红"
                placement="top-start"
                v-else-if="scope.row.warning_state === 20"
              >
                <div
                  style="
                    background-color: #ff9000;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin: 0 auto;
                  "
                ></div>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="警报等级依次为：绿<黄<橙<红"
                placement="top-start"
                v-else-if="scope.row.warning_state === 30"
              >
                <div
                  style="
                    background-color: #f4390d;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin: 0 auto;
                  "
                ></div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goods_pic" label="货品图片" align="center">
          <template slot-scope="scope">
            <el-image
              style="width: 40px; height: 40px"
              :src="scope.row.goods_pic"
              :preview-src-list="[scope.row.goods_pic]"
            >
              <div slot="error" class="image-slot">
                <img :src="noPic" style="width: 40px; height: 40px" />
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="entry_number"
          label="入库单号"
          align="center"
          width="210"
        ></el-table-column>
        <el-table-column
          prop="goods_number"
          label="货号"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.goods_number || "--" }}
            <!-- <template v-for="(item, index) in scope.row.goods_tag_array">
              <el-tag type="danger" :key="index" style="margin-left: 5px">{{
                item
              }}</el-tag>
            </template> -->
          </template>
        </el-table-column>
        <el-table-column prop="goods_style_number" label="款号" align="center">
          <template slot-scope="scope">
            {{ scope.row.goods_style_number || "--" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_name"
          align="center"
          label="品名"
          width="200"
        >
          <template slot-scope="scope">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              {{ scope.row.goods_name || "--" }}
              <template v-for="item in scope.row.goods_tag_pic">
                <img
                  :src="item"
                  :key="item"
                  style="margin-left: 5px; height: 15px"
                />
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_number_type"
          align="center"
          label="货品类型"
        >
          <template slot-scope="scope">
            {{
              scope.row.goods_number_type === 10
                ? "一码一货"
                : scope.row.goods_number_type === 20
                ? "一码多货"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="usable_count"
          label="可操作数量"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="hierarchy_name"
          align="center"
          label="所属门店"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="warehouse_name"
          align="center"
          label="仓库"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="stock"
          align="center"
          label="库存"
        ></el-table-column>
        <el-table-column prop="sale_price" align="center" label="标签价">
          <template slot-scope="scope">
            {{ scope.row.sale_price || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="184" align="center" label="销售工费">
          <template slot-scope="scope">
            {{ scope.row["184"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="185" align="center" label="销售工费类型">
          <template slot-scope="scope">
            {{ scope.row["185"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="101" align="center" label="款式">
          <template slot-scope="scope">
            {{ scope.row["101"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="100" align="center" label="材质成色">
          <template slot-scope="scope">
            {{ scope.row["100"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="104" align="center" label="净金重">
          <template slot-scope="scope">
            {{ scope.row["104"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="130" align="center" label="主石重">
          <template slot-scope="scope">
            {{ scope.row["130"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="133" align="center" label="辅石1重量">
          <template slot-scope="scope">
            {{ scope.row["133"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="112" align="center" label="证书号">
          <template slot-scope="scope">
            {{ scope.row["112"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="entry_time"
          align="center"
          label="入库时间"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row["entry_time"] || "--" }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleDele(scope.row, scope.row.index)"
              style="color: #e7541e"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleTrue">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import numeral from "numeral";
import { post } from "@/utils/request";
import storage from "good-storage";
import { getGoodsTypeList } from "@/api/goods/goodsWarehousing/warehousing";
import { getWarehouseList } from "@/api/goods/goodsStock/goodsInventory/index.js";
// import goodsReq from "@/components/goods/chooseGoods/index.js";
import { getList as goodsReq } from "@/api/goods/goodsStock/goodsDetailedlist/index";
import { getMerchantList } from "@/api/goods/goodsWarehousing/warehousing.js";
const noPic = require("@/assets/images/nopic.png");

export default {
  data() {
    return {
      noPic: noPic,
      allLoading: false,
      total: {
        104: 0,
        130: 0,
        1446: 0,
      },
      merchantList: [],
      offsetHeight: "",
      height: "55px",
      brandList: [],
      patternList: [],
      materialList: [],
      treeData: [],
      treeData2: [],
      warehouseList: [],
      isClearable: true, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      valueId: 0,
      props: {
        value: "goods_type_id",
        label: "title",
        children: "child",
      },
      loading: false,
      typeList: [],
      userInfo: {},
      formInline: {
        104: [],
        sale_price: [],
        page: 1,
        page_num: this.$store.state.pageSizes[0],
      },
      page_num: this.$store.state.pageSizes[0],
      goodsData: [],
      table2Height: 110,
      table1Height: 110,
      multipleSelection: [],
      addList: [],
    };
  },
  props: {
    goods_number_type: {
      type: Number,
      default() {
        return 0;
      },
    },
    field_is_show: Number,
    goodVisibel: Boolean,
    formItemList: Array,
    allot_state: Number,
    goods_tag_id: Number,
    query_type: String,
    search_value_type: String,
    selectGoods: {
      type: Array,
      default() {
        return [];
      },
    },
    newArrival: {
      type: Array,
      default() {
        return [];
      },
    },
    gift: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    selectGoodsIds() {
      const arr = this.selectGoods;
      console.log('触发', arr);
      let selectGoodsIds = [];
      arr.forEach((item) => {
        selectGoodsIds.push(item.goods_number);
      });
      return selectGoodsIds;
    },
    newArrivalIds() {
      const arr = this.newArrival.filter(
        (item) => item.goods_number_type === 10
      );
      let newArrivalIds = [];
      arr.forEach((item) => {
        newArrivalIds.push(item.goods_number);
      });
      // newArrivalIds = newArrivalIds.join(",");
      return newArrivalIds;
    },
    giftIds() {
      const arr = this.gift.filter((item) => item.goods_number_type === 10);
      let giftIds = [];
      arr.forEach((item) => {
        giftIds.push(item.goods_number);
      });
      // giftIds = giftIds.join(",");
      return giftIds;
    },
  },
  watch: {
    multipleSelection: {
      handler(val) {
        const arr = JSON.parse(JSON.stringify(val));
        arr.forEach((item, index) => {
          item.index = index + 1;
        });
        this.addList = arr;
      },
      deep: true,
    },
    formInline: {
      handler(val) {
        this.valueId = val.goods_type_id;
      },
      immediate: true,
    },
    goodVisibel: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            const dom = document.getElementById("middle");
            this.offsetHeight = dom.offsetHeight + "px";
          });
          this.$refs.multipleTable.clearSelection();
          this.addList = [];
        }
      },
    },
    addList: {
      handler() {
        let total = {
          104: 0,
          1446: 0,
          130: 0,
        };
        this.addList.forEach((item) => {
          item["104"] && (total["104"] += Number(item["104"]));
          item["1446"] && (total["1446"] += Number(item["1446"]));
          item["130"] && (total["130"] += Number(item["130"]));
        });
        total = {
          104: numeral(total["104"]).format("0.00"),
          1446: numeral(total["1446"]).format("0.00"),
          130: numeral(total["130"]).format("0.00"),
        };
        this.total = total;
      },
      deep: true,
    },
  },
  methods: {
    handleAll() {
      // 选择全部
      this.allLoading = true;
      goodsReq({
        ...this.formInline,
        allot_state: this.allot_state,
        goods_tag_id: this.goods_tag_id,
        query_type: this.query_type,
        search_value_type: this.search_value_type,
        goods_number_type: this.goods_number_type,
        get_all_can_able: true,
        field_is_show: this.field_is_show,
        page: 1,
        page_num: 999999,
      })
        .then((res) => {
          if (res.code === 1) {
            this.allLoading = false;
            this.addList = [];
            this.addList = res.data.list;
            // this.$nextTick(() => {
            //   setTimeout(() => {
            //     res.data.list.forEach((row) => {
            //       this.$refs.multipleTable.toggleRowSelection(row);
            //     });
            //   }, 1000);
            // });
          }
        })
        .catch(() => {
          this.allLoading = false;
        });
    },
    // 门店列表
    getMerchantList() {
      getMerchantList({
        page: 1,
        page_num: 999,
      }).then((res) => {
        if (res.code === 1) {
          this.merchantList = res.data;
        }
      });
    },
    handleHeight() {
      if (parseInt(this.offsetHeight) <= 62) {
        this.$message.warning("没有更多条件");
        return;
      }
      this.height = this.height === "55px" ? this.offsetHeight : "55px";
    },
    handleClear() {
      this.$confirm("确定要清空所有货品吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.addList = [];
          this.$refs.multipleTable.clearSelection();
        })
        .catch(() => {
          console.log("取消");
        });
    },
    // 获取品牌
    getBandList() {
      post({
        url: "/store/common.Select/getBandList",
      }).then((res) => {
        if (res.code === 1) {
          this.brandList = res.data;
        }
      });
    },
    // 获取款式
    getPatternList() {
      post({
        url: "/store/common.Select/getPatternList",
      }).then((res) => {
        if (res.code === 1) {
          this.patternList = res.data;
        }
      });
    },
    /*
     *  获取仓库数据
     */
    getWarehouseList() {
      getWarehouseList().then((res) => {
        if (res.code === 1) {
          res.data.unshift({
            warehouse_id: 0,
            warehouse_name: '全部',
          })
          this.warehouseList = res.data;
        }
      });
    },
    // 获取材质成色
    getMaterialList() {
      post({
        url: "/store/common.Select/getMaterialList",
      }).then((res) => {
        if (res.code === 1) {
          this.materialList = res.data;
        }
      });
    },
    toggleSelection(rows) {
      if (rows.length) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSizeChange(page_num) {
      const formInline = { ...this.formInline };
      formInline.page = 1;
      formInline.page_num = page_num;
      this.formInline = formInline;
      this.getGoods();
    },
    // 取值
    getValue(value, attr) {
      const formInline = { ...this.formInline };
      formInline[attr] = value;
      this.formInline = formInline;
    },
    handleSelectable(row) {
      // 已选择的货品置灰
      for (let i = 0; i < this.selectGoodsIds.length; i += 1) {
        const item = this.selectGoodsIds[i];
        if (item == row.goods_number) {
          return false;
        }
      }
      // 新增订单模块。一码一货新品不能和赠品是同一个商品
      for (let i = 0; i < this.newArrivalIds.length; i += 1) {
        const item = this.newArrivalIds[i];
        if (item == row.goods_number) {
          return false;
        }
      }
      for (let i = 0; i < this.giftIds.length; i += 1) {
        const item = this.giftIds[i];
        if (item == row.goods_number) {
          return false;
        }
      }
      // 如果没有可操作数量
      if (!row.usable_count) {
        return false;
      }
      return true;
    },
    handleDele(row) {
      const goods_id = row.goods_id;
      this.goodsData.list.forEach((item, index) => {
        if (item.goods_id === row.goods_id) {
          this.$refs.multipleTable.toggleRowSelection(
            this.goodsData.list[index],
            false
          );
        }
      });
      this.addList = this.addList.filter((item) => {
        return item.goods_id !== goods_id;
      });
      const arr2 = this.addList.map((item, index) => {
        const newItem = { ...item };
        newItem.index = index + 1;
        item = newItem;
        return item;
      });
      this.addList = arr2;
      let height = this.addList.length * 42 + 50;
      if (height > 250) {
        height = 250;
      }
      if (height < 92) {
        height = 92;
      }
      this.table2Height = height;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // this.handleClick();
    },
    handleClick() {
      this.addList = [...this.addList, ...this.multipleSelection];
      // 去重
      let newArr = [];
      let obj = {};
      this.addList.forEach((item) => {
        const { goods_id } = item;
        if (!obj[goods_id]) {
          obj[goods_id] = true;
          newArr.push(item);
        }
      });
      this.addList = newArr;
      const arr2 = this.addList.map((item, index) => {
        const newItem = { ...item };
        newItem.index = index + 1;
        item = newItem;
        return item;
      });
      this.addList = arr2;
      let height = this.addList.length * 42 + 50;
      if (height > 250) {
        height = 250;
      }
      if (height < 92) {
        height = 92;
      }
      this.table2Height = height;
    },
    handleRow({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },
    changePage(page) {
      const formInline = { ...this.formInline };
      formInline.page = page;
      this.formInline = formInline;
      // const tableBodyDom = document.getElementsByClassName(
      //   "el-table__body-wrapper"
      // )[1];
      // tableBodyDom.scrollTop = 0;
      this.getGoods();
    },
    getGoods() {
      this.loading = true;
      goodsReq({
        ...this.formInline,
        allot_state: this.allot_state,
        goods_tag_id: this.goods_tag_id,
        query_type: this.query_type,
        search_value_type: this.search_value_type,
        goods_number_type: this.goods_number_type,
        field_is_show: this.field_is_show,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.goodsData = res.data;
            // 计算高度
            let height = res.data.list.length * 42 + 50;
            if (height > 250) {
              height = 250;
            }
            if (height < 92) {
              height = 92;
            }
            this.table1Height = height;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleArr(arr) {
      const newArr = [];
      function poling(arr) {
        arr.forEach((item) => {
          newArr.push(item);
          if (item.child) {
            poling(item.child);
          }
        });
      }
      poling(arr);
      return newArr;
    },
    handleGetGoodsTypeList() {
      getGoodsTypeList({
        type: [10],
        isHaveAll: 0,
      }).then((res) => {
        if (res.code === 1) {
          this.treeData = res.data;
          this.typeList = this.handleArr(res.data);
        }
      });
    },
    handleGetGoodsTypeList2() {
      getGoodsTypeList({
        type: [20],
        isHaveAll: 0,
      }).then((res) => {
        if (res.code === 1) {
          this.treeData2 = res.data;
          // this.typeList = this.handleArr(res.data);
        }
      });
    },
    handleReset() {
      let warehouse_value;
      if (this.formItemList[10].value) {
        warehouse_value = this.formItemList[10].value;
      }
      // 品类不可重置
      if (this.formItemList[0].type !== 'text') {
        this.valueId = '';
      }
      this.formInline = {
        warehouse_id: warehouse_value,
        goods_type_id: this.valueId,
        '104': [],
        sale_price: [],
        page: 1,
        page_num: 15,
      };
      // const formInline = { ...this.formInline };
      // formInline.page = 1;
      // this.formInline = formInline;
      this.getGoods();
    },
    onSubmit() {
      const formInline = { ...this.formInline };
      formInline.page = 1;
      this.formInline = formInline;
      this.getGoods();
    },
    handleClose() {
      this.$emit("close");
    },

    handleTrue() {
      if (!this.addList.length) {
        this.$message.warning("请添加货品");
        return;
      }
      this.handleClose();
      this.$emit("getGoods", this.addList);
    },
  },
  
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getMaterialList();
    this.getBandList();
    this.getPatternList();
    this.handleGetGoodsTypeList();
    this.handleGetGoodsTypeList2();
    this.getWarehouseList();
    this.getMerchantList();
    // 赋值
    const formInline = { ...this.formInline };
    this.formItemList.forEach((item) => {
      if (item.value || item.value === 0) {
        formInline[item.attr] = item.value;
      }
    });
    this.formInline = formInline;
    this.getGoods();
    console.log(this.formInline);
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  transition: all 0.3s;
  overflow: hidden;
  flex: 1;
  .middle {
    display: flex;
    overflow: hidden;
  }
}
// .el-dialog__wrapper {
//   left: 80px !important;
// }
/deep/ .el-dialog__title {
  font-weight: bold;
  font-size: 16px;
  color: rgb(80, 80, 80);
}
.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.title {
  line-height: 60px;
  font-weight: bold;
  font-size: 16px;
  color: rgb(80, 80, 80);
}
</style>
