<template>
  <div style="display: flex">
    <el-button
      type="primary"
      plain
      @click="dialogVisible = true"
      style="height: 40px"
    >
      搜索配置
    </el-button>
    <el-dialog title="搜索配置" :visible.sync="dialogVisible" width="30%">
      <span style="color: #f56c6c; margin-left: 10px"
        >*可拖拽行改变列表排序</span
      >
      <div style="height: 400px; overflow-y: auto; margin-top: 10px">
        <ux-grid border row-key ref="plxTable" :data="rowList">
          <ux-table-column
            v-for="item in tableColumn"
            align="center"
            :key="item.id"
            :resizable="item.resizable"
            :field="item.field"
            :title="item.title"
            :sortable="item.sortable"
            :width="item.minWidth"
            :fixed="item.fixed"
          >
            <template v-slot="scope" v-if="item.field === 't_width'">
              <el-input v-model="scope.row.t_width"></el-input>
            </template>
          </ux-table-column>
          <ux-table-column
            title="是否显示"
            align="center"
            field="s_is_use"
            width="300"
          >
            <template v-slot="scope">
              <el-switch
                v-model="scope.row.s_is_use"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </template>
          </ux-table-column>
        </ux-grid>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleTrue" :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/utils/bus.js";
import { commonSearchReq, setSearchReq } from "@/components/cond/index";
import Sortable from "sortablejs/modular/sortable.core.esm.js";

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      rowList: [],
      tableColumn: [
        { title: "搜索字段", field: "field_text", minWidth: "250" },
      ],
    };
  },
  props: {
    function_code: String,
    mode: Number,
    tab: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (val) {
          this.columnDrop();
          this.columnDrop2();
          this.columnDrop3();
          this.columnDrop4();
        }
      },
      immediate: true,
    },
    mode: {
      handler() {
        this.commonSearch();
      },
    },
  },
  created() {
    this.commonSearch();
  },
  methods: {
    columnDrop() {
      this.$nextTick(() => {
        let plxTable = this.$refs.plxTable;
        if (!document.getElementsByClassName("elx-table--body")[0]) {
          return;
        }
        const tbodyDom = document
          .getElementsByClassName("elx-table--body")[0]
          .getElementsByTagName("tbody")[0];
        // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
        this.sortable = Sortable.create(tbodyDom, {
          handle: ".elx-body--row", // Drag handle selector within list items
          // 拖拽结束
          onEnd: ({ item, newIndex, oldIndex }) => {
            let currRow = this.rowList.splice(oldIndex, 1)[0];
            this.rowList.splice(newIndex, 0, currRow);
          },
        });
      });
    },
    columnDrop2() {
      this.$nextTick(() => {
        let plxTable = this.$refs.plxTable;
        if (!document.getElementsByClassName("elx-table--body")[1]) {
          return;
        }
        const tbodyDom = document
          .getElementsByClassName("elx-table--body")[1]
          .getElementsByTagName("tbody")[0];
        // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
        this.sortable = Sortable.create(tbodyDom, {
          handle: ".elx-body--row", // Drag handle selector within list items
          // 拖拽结束
          onEnd: ({ item, newIndex, oldIndex }) => {
            let currRow = this.rowList.splice(oldIndex, 1)[0];
            this.rowList.splice(newIndex, 0, currRow);
          },
        });
      });
    },
    columnDrop3() {
      this.$nextTick(() => {
        let plxTable = this.$refs.plxTable;
        if (!document.getElementsByClassName("elx-table--body")[2]) {
          return;
        }
        const tbodyDom = document
          .getElementsByClassName("elx-table--body")[2]
          .getElementsByTagName("tbody")[0];
        // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
        this.sortable = Sortable.create(tbodyDom, {
          handle: ".elx-body--row", // Drag handle selector within list items
          // 拖拽结束
          onEnd: ({ item, newIndex, oldIndex }) => {
            let currRow = this.rowList.splice(oldIndex, 1)[0];
            this.rowList.splice(newIndex, 0, currRow);
          },
        });
      });
    },
    columnDrop4() {
      this.$nextTick(() => {
        let plxTable = this.$refs.plxTable;
        if (!document.getElementsByClassName("elx-table--body")[3]) {
          return;
        }
        const tbodyDom = document
          .getElementsByClassName("elx-table--body")[3]
          .getElementsByTagName("tbody")[0];
        // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
        this.sortable = Sortable.create(tbodyDom, {
          handle: ".elx-body--row", // Drag handle selector within list items
          // 拖拽结束
          onEnd: ({ item, newIndex, oldIndex }) => {
            let currRow = this.rowList.splice(oldIndex, 1)[0];
            this.rowList.splice(newIndex, 0, currRow);
          },
        });
      });
    },
    commonSearch() {
      commonSearchReq(this.function_code, this.tab, this.mode).then((res) => {
        if (res.code === 1) {
          this.rowList = JSON.parse(JSON.stringify(res.data));
        }
      });
    },
    handleTrue() {
      this.rowList.forEach((item, index) => {
        item.s_order = this.rowList.length - index;
      });
      setSearchReq({
        function_code: this.function_code,
        data: this.rowList,
        tab: this.tab,
      }).then((res) => {
        if (res.code === 1) {
          this.dialogVisible = false;
          bus.$emit('commonSearch');
        }
      });
    },
  },
};
</script>
