
import { post } from '@/utils/request';

// 标签列表
const getTagList = (data) => post({
    url: '/store/common.Select/getClientTagsList',
    data: data
});
// 新增标签
const createTab = (data) => post({
    url: '/store/customer.Tags/create',
    data: data
})

// 删除标签
const deleteTab = (data) => post({
    url: '/store/customer.Tags/del',
    data: data
})

// 编辑标签
const updateTab = (data) => post({
    url: '/store/customer.Tags/update',
    data: data
})
export {
    getTagList,
    createTab,
    deleteTab,
    updateTab

}
