import { post } from '@/utils/request';

// 管理推送计划列表

const mangeSendPlanList=(data)=>{
    return post({
        url:"/store/coupon.Giving/list",
        data
    })
}

// 管理推送计划详情
const  mangePlanDetail=(data)=>{
    return post({
        url:"/store/coupon.Giving/details",
        data
    })
}

// 管理计划推送手动领取

const mangePlanHandleCreate=(data)=>{
    return post({
        url:'/store/coupon.Giving/save',
        data
    })
}


// 管理计划推送创建 消费赠送

const mangePlanHuafeiCreate=(data)=>{
    return post({
        url:"/store/coupon.Giving/save",
        data
    })
}


// 管理计划推送 创建 生日、纪念日 任务 

const mangePlanTaskCreate=(data)=>{
    return post({
        url:"/store/coupon.Giving/save",
        data
    })
}

//管理计划推送 关注/注册送券

const mangePlanNoticeCreate=(data)=>{
    return post({
        url:"/store/coupon.Giving/save",
        data
    })
}

// 管理推送计划更新状态

const mangePlanUpdate=(data)=>{
    return post({
        url:"/store/coupon.Giving/updateStatus",
        data
    })
}


// 管理推送计划，删除

const mangePlanDelete=(data)=>{
    return post({
        url:"/store/coupon.Giving/delete",
        data
    })
}

// 管理推送计划，可用的下拉框

 const mangePlanSelectCouponList=(data)=>{
     return post({
         url:"/store/coupon.Giving/searchList",
         data
     })
 }

//  默认获取微信的下拉数据

const messageWeiXinData=(data)=>{
    return post({
        url:"/store/coupon.Giving/WeChatMessageData",
        data
    })
}


// 会员体系绑定列表
const systemBindingList = (data) => post({
    url: '/store/customer.ClientSystemBinding/list',
    data: data
});


export  {
    messageWeiXinData,
    mangePlanSelectCouponList,
    mangePlanDelete,
    mangePlanUpdate,
    mangePlanNoticeCreate,
    mangePlanTaskCreate,
    mangePlanHuafeiCreate,
    mangePlanHandleCreate,
    mangePlanDetail,
    mangeSendPlanList,
    systemBindingList
}