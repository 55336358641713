import { post } from '@/utils/request';

const getListReq = () => post({
  url: '/store/order.PutUp/list',
});

// 取消挂单
const cancelReq = (put_order_sn) => post({
  url: '/store/order.PutUp/cancel',
  data: {
    put_order_sn,
  },
});

// 提单
const getOneReq = (put_order_sn) => post({
  url: '/store/order.PutUp/getOne',
  data: {
    put_type: 'pc',
    put_order_sn,
  },
});

export {
  getListReq,
  cancelReq,
  getOneReq,
};
