import { post } from '@/utils/request';

// 会员列表
const getList = (data) => post({
    url: '/store/customer.UserList/lists',
    data: data
});

// 会员列表导出
const userListExport = (data) => post({
    url: '/store/customer.User/userListExport',
    responseType: 'blob',
    data: data
})

// 我的会员列表
const getMyList = (data) => post({
    url: '/store/customer.User/myCustomer',
    data: data
})

// 创建会员
const create = (data) => post({
    url: '/store/customer.User/create',
    data: data
})

// 修改会员
const updateUser = (data) => post({
    url: '/store/customer.User/update',
    data: data
})

// 设置积分
const setIntegral = (data) => post({
    url: '/store/customer.MineUser/setIntegral',
    data: data
})


// 获取会员提醒信息
const getOne = (data) => post({
    url: '/store/customer.Remind/getOne',
    data: data
})

// 编辑会员提醒
const updateRemind = (data) => post({
    url: '/store/customer.Remind/update',
    data: data
})

// 会员提醒设置
const createRemind = (data) => post({
    url: '/store/customer.Remind/create',
    data: data
})

// 会员详情
const getInfoReq = (data) => post({
    url: '/store/common.Select/getUserInfo',
    data: data,
})

// 标签删除
const delTags = (data) => post({
    url: '/store/customer.User/delTags',
    data: data,
})

// 购买记录
const buyRecord = (data) => post({
    url: '/store/order.Order/getCustomerRecord',
    data: data,
})

// 积分记录
const userIntegralList = (data) => post({
    url: '/store/customer.User/userIntegralList',
    data: data
})

// 跟进记录
const tractList = (data) => post({
    url: '/store/customer.User/tractList',
    data: data
})

// 添加跟进记录
const tractAdd = (data) => post({
    url: '/store/customer.User/tractAdd',
    data: data
})

// 导入模板
const getImportFile = (data) => post({
    url: '/store/customer.UserList/exportTmplate',
    data: data
})

// 会员导入
const userImport = (data) => post({
    url: "/store/customer.User/userImport",
    data: data,
})

// 储值金充值
const insertStorage = (data) => post({
    url: "/store/customer.StorageMoney/insertStorage",
    data: data,
})

// 储值金列表
const settingList = (data) => post({
    url: "/store/customer.StorageMoney/settingList",
    data: data,
})

// 储值金更新
const settingUpdate = (data) => post({
    url: "/store/customer.StorageMoney/setting",
    data: data,
})

// 储值金记录
const getStorageList = (data) => post({
    url: "/store/customer.StorageMoney/getList",
    data: data,
})

// 全部会员列表
const getWholeMemberList = (data) => post({
    url: "/store/customer.UserList/lists",
    data: data,
})



// 全部会员导出
const getWholeMemberListExport = (data) => post({
    url: '/store/customer.UserList/userListExport',
    responseType: 'blob',
    data: data
})

// 会员详情
const getMemberInfo = (data) => post({
    url: "/store/customer.UserList/getInfo",
    data: data
})

// 会员详情标签删除
const delMemberTags = (data) => post({
    url: "/store/customer.UserList/delTags",
    data: data
})

// 跟进记录
const getTractList = (data) => post({
    url: "/store/customer.UserList/tractList",
    data: data
})

// 跟进记录新增
const addTractInfo = (data) => post({
    url: "/store/customer.UserList/tractAdd",
    data: data
})

// 跟进方式
const getFollowStateList = (data) => post({
    url: "/store/common.Select/getFollowStateList",
    data: data
})

// 积分记录
const getUserIntegralList = (data) => post({
    url: "/store/customer.UserList/userIntegralList",
    data: data
})

// 跟进线索列表
const followStepLists = (data) => post({
    url: "/store/customer.ClientFollowSet/followStepLists",
    data: data,
})

// 跟进线索更新
const followStepUdate = (data) => post({
    url: "/store/customer.ClientFollowSet/followStepUdate",
    data: data,
})

// 重置跟进线索
const followReset = (data) => post({
    url: "/store/customer.ClientFollowSet/followReset",
    data: data,
})

// 重置跟进结果
const followResultReset = (data) => post({
    url: "/store/customer.ClientFollowSet/followResultReset",
    data: data,
})

// 跟进结果列表
const followResultLists = (data) => post({
    url: "/store/customer.ClientFollowSet/followResultLists",
    data: data,
})



// 跟进人下拉
const getFollowStoreUser = (data) => post({
    url: "/store/common.Select/getFollowStoreUser",
    data: data,
})

// 跟进结果更新
const followResultUdate = (data) => post({
    url: "/store/customer.ClientFollowSet/followResultUdate",
    data: data,
})

// 会员设置详情
const getClientSetting = (data) => post({
    url: "/store/customer.ClientFollowSet/getClientSetting",
    data: data,
})
// 跟进结果列表
const getResultLists = (data) => post({
    url: "/store/common.Select/followResultLists",
    data: data,
})

// 会员设置更新
const setClientSetting = (data) => post({
    url: "/store/customer.ClientFollowSet/setClientSetting",
    data: data,
})

// 待分配会员列表
const waitAssignUser = (data) => post({
    url: "/store/customer.WaitAssignUser/lists",
    data: data,
})

// 待分配会员--新增会员
const waitAssignUserCreate = (data) => post({
    url: "/store/customer.WaitAssignUser/create",
    data: data,
})

// 待分配会员--导出
const waitAssignUserExport = (data) => post({
    url: '/store/customer.WaitAssignUser/userListExport',
    responseType: 'blob',
    data: data
})

// 待分配会员--更新
const waitAssignUserUpdate = (data) => post({
    url: "/store/customer.WaitAssignUser/update",
    data: data,
})

// 会员详情-删除标签
const delTagsUser = (data) => post({
    url: "/store/customer.UserList/delTags",
    data: data,
})

// 会员详情--添加标签
const tagsUpdate = (data) => post({
    url: "/store/customer.UserList/tagsUpdate",
    data: data,
})

// 待分配会员--分配会员
const waitAssignDistrClient = (data) => post({
    url: "/store/customer.WaitAssignUser/distrClient",
    data: data,
})

// 待分配会员--会员导入
const waitAssignImport = (data) => post({
    url: "/store/customer.WaitAssignUser/userImport",
    data: data,
})

// 待分配会员--放回公海
const waitAssignDischargeClient = (data) => post({
    url: "/store/customer.WaitAssignUser/dischargeClient",
    data: data,
})

// 我的会员列表
const mineUserList = (data) => post({
    url: "/store/customer.MineUser/lists",
    data: data,
})








// 我的会员--新增
const mineUserCreate = (data) => post({
    url: "/store/customer.MineUser/create",
    data: data,
})

// 我的会员--更新
const mineUserUpdate = (data) => post({
    url: "/store/customer.MineUser/update",
    data: data,
})

// 我的会员--导出
const mineUserExport = (data) => post({
    url: '/store/customer.MineUser/userListExport',
    responseType: 'blob',
    data: data
})

// 我的会员--转让会员
const mineUserTransferClient = (data) => post({
    url: "/store/customer.MineUser/transferClient",
    data: data,
})

// 我的会员--添加协助
const mineUserAssistFollow = (data) => post({
    url: "/store/customer.MineUser/assistFollow",
    data: data,
})

// 我的会员--放回公海
const mineUserDischargeClient = (data) => post({
    url: "/store/customer.MineUser/dischargeClient",
    data: data,
})

// 我的会员--添加会员分组
const mineUserAddUserGroup = (data) => post({
    url: "/store/customer.MineUser/addUserGroup",
    data: data,
})
// 我的会员--删除分组
const delUserGroup = (data) => post({
    url: "/store/customer.MineUser/delUserGroup",
    data: data,
})
// 我的会员--获取分组
const getClientUserGroup = (data) => post({
    url: "/store/common.Select/getClientUserGroup",
    data: data,
})

// 我的会员--导入
const mineUserImport = (data) => post({
    url: "/store/customer.MineUser/userImport",
    data: data,
})
// 公海会员--列表
const commonUserList = (data) => post({
    url: "/store/customer.NotAssignUser/lists",
    data: data,
})

// 公海会员--新增会员
const commonCreateUser = (data) => post({
    url: "/store/customer.NotAssignUser/create",
    data: data,
})

// 公海会员--编辑会员
const commonUpdateUser = (data) => post({
    url: "/store/customer.MineUser/update",
    data: data,
})

// 公海会员--导出
const commonUserExport = (data) => post({
    url: '/store/customer.NotAssignUser/userListExport',
    responseType: 'blob',
    data: data
})

// 公海会员--设置待分配
const commonSetWaitAssign = (data) => post({
    url: "/store/customer.NotAssignUser/setWaitAssign",
    data: data,
})

// 公海会员--分配会员
const commonDistrClient = (data) => post({
    url: "/store/customer.NotAssignUser/distrClient",
    data: data,
})

// 公海会员--领取会员
const commonTakeUser = (data) => post({
    url: "/store/customer.NotAssignUser/takeClient",
    data: data,
})

// 协助会员--列表
const assistUserList = (data) => post({
    url: "/store/customer.AssistUser/lists",
    data: data,
})

// 协助会员--导出
const assistUserExport = (data) => post({
    url: '/store/customer.AssistUser/userListExport',
    responseType: 'blob',
    data: data
})

// 协助会员--取消协助
const assistUserCancelAssist = (data) => post({
    url: "/store/customer.AssistUser/cancelAssist",
    data: data,
})

// 已分配会员--列表
const assignedUserList = (data) => post({
    url: "/store/customer.AssignUser/lists",
    data: data
})

// 已分配会员--导出
const assignedUserExport = (data) => post({
    url: '/store/customer.AssignUser/userListExport',
    responseType: 'blob',
    data: data
})

// 已分配会员--转让会员
const assignedUserTransfer = (data) => post({
    url: "/store/customer.AssignUser/transferClient",
    data: data
})

// 已分配会员--添加协助
const assignedUserFollow = (data) => post({
    url: "/store/customer.AssignUser/assistFollow",
    data: data
})

// 已分配会员--放回公海
const assignedUserDischarge = (data) => post({
    url: "/store/customer.AssignUser/dischargeClient",
    data: data
})

// 获取跟进线索列表
const getFollowStepList = (data) => post({
    url: "/store/common.Select/getFollowStepList",
    data: data
})

// 变更会员线索
const updateClientStep = (data) => post({
    url: "/store/customer.UserList/updateClientStep",
    data: data
})

// 获取协助人列表
const getAssistStoreUser = (data) => post({
    url: "/store/common.Select/getAssistStoreUser",
    data: data
})

// 会员优惠券列表
const getCouponListReq = (data) => post({
    url: "/store/customer.ClientCardVoucher/list",
    data: data,
})

// 作废优惠券
const deleCouponReq = (data) => post({
    url: "/store/customer.ClientCardVoucher/delete",
    data: data,
})

// （新会员优惠券列表（通用的））
/***
 * client_system_id
 * client_user_master_id
 */
const getNewCouponList=(data)=>{
    return post({
        url:'/store/coupon.Record/userList',
        data
    })
}


// （新会员优惠券列表）(人情)

const getNewCouponListPerson=(data)=>{
    return post({
        url:'/store/coupon.Record/userHumanList',
        data
    })
}



// （新作废优惠券列表）
/*****
 * client_user_master_id 用户公司主表ID
 *
 * coupon_sn 优惠劵编码
 */
const deleNewCouponReq=(data)=>{
    return post({
        url:'/store/coupon.Record/invalid',
        data
    })
}



// 发放优惠券接口

/**
 *
 * @param {
 * coupon_ids    优惠劵ID 数字或数组
 * client_user_master_ids 用户ID
 * client_user_master_ids 无
 * } data
 * @returns
 */
const sendCoupontNew=(data)=>{
    return post({
        url:"/store/coupon.Record/issue",
        data
    })
}



// 编辑协助
const editAssistFollow = (data) => post({
    url: "/store/customer.MineUser/editAssistFollow",
    data: data,
})

// 下单会员列表
const getAllUserlists = (data) => post({
    url: "/store/common.Select/getAllUserlists",
    data: data,
})

// 聊天记录
const getChatList = (data) => post({
    url: "/store/customer.MineUser/chatList",
    data: data,
})
// 会员体系-门店
const getClientSystemAndMerchants = (data) => post({
    url: "/store/common.Select/getClientSystemAndMerchants",
    data: data,
})

// 企微列表
const getCustomerList = (data) => post({
  url: "/store/QiyeApp.EwechatCustomerCtl/getCustomerList",
  data: data,
})

// 企微列表导出
const exportCustomer = (data) => post({
  url: "/store/QiyeApp.EwechatCustomerCtl/exportCustomer",
  responseType: 'blob',
  data: data,
})

export {
    deleCouponReq,
    getCouponListReq,
    getList,
    getMyList,
    create,
    updateUser,
    setIntegral,
    createRemind,
    getOne,
    updateRemind,
    getInfoReq,
    userIntegralList,
    tractList,
    tractAdd,
    delTags,
    getImportFile,
    userImport,
    buyRecord,
    userListExport,
    insertStorage,
    settingList,
    settingUpdate,
    getStorageList,
    getWholeMemberList,
    getWholeMemberListExport,
    followStepLists,
    followStepUdate,
    followReset,
    followResultReset,
    followResultLists,
    followResultUdate,
    getClientSetting,
    getResultLists,
    setClientSetting,
    waitAssignUser,
    waitAssignUserCreate,
    waitAssignUserExport,
    waitAssignUserUpdate,
    delTagsUser,
    tagsUpdate,
    waitAssignDistrClient,
    waitAssignImport,
    waitAssignDischargeClient,
    mineUserList,
    mineUserCreate,
    mineUserUpdate,
    mineUserExport,
    mineUserTransferClient,
    mineUserAssistFollow,
    mineUserDischargeClient,
    mineUserAddUserGroup,
    mineUserImport,
    delUserGroup,
    getClientUserGroup,
    commonUserList,
    commonCreateUser,
    commonUpdateUser,
    commonUserExport,
    commonSetWaitAssign,
    commonDistrClient,
    commonTakeUser,
    getFollowStoreUser,
    assistUserList,
    assistUserExport,
    assistUserCancelAssist,
    assignedUserList,
    assignedUserExport,
    assignedUserTransfer,
    assignedUserFollow,
    assignedUserDischarge,
    getMemberInfo,
    delMemberTags,
    getTractList,
    getUserIntegralList,
    getFollowStepList,
    addTractInfo,
    getFollowStateList,
    updateClientStep,
    getAssistStoreUser,
    editAssistFollow,
    getAllUserlists,
    getChatList,
    getClientSystemAndMerchants,
    deleNewCouponReq,
    getNewCouponList,
    sendCoupontNew,
    getNewCouponListPerson,
    getCustomerList,
    exportCustomer
}
