import { post } from '@/utils/request';

const tableConfReq = (function_code, tab, mode) => post({
  url: '/store/common.Column/index',
  data: {
    function_code,
    tab,
    mode,
  },
});

const tableConfSet = ({ function_code, data, tab, mode }) => post({
  url: '/store/common.Column/set',
  data: {
    function_code,
    data,
    tab,
    mode,
  },
});

// 恢复默认
const restoreReq = (function_code) => post({
  url: '/store/common.Column/restore',
  data: {
    function_code
  },
});

// 恢复默认
const handleDefaultReq = (params) => post({
  url: '/store/common.Column/restore',
  data: {
    ...params,
  },
});

export {
  tableConfReq,
  tableConfSet,
  restoreReq,
  handleDefaultReq,
};
