<template>
  <div>
    <el-dialog
      title="会员列表"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose">
      <div class="form">
        <el-button type="primary" icon='el-icon-plus' style="margin-right: 20px;" @click="toMember">新增会员</el-button>
        <div class="line"></div>
        <el-form :model="form" :inline="true">
          <el-form-item label="会员名称">
            <el-input placeholder="请输入会员名称" v-model="form.realname" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input placeholder="请输入手机号" v-model="form.username" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table" style="border: none;">
        <el-table
          :data="tableData.list"
          style="width: 100%; margin: 20px 0;"
          v-loading="loading"
          height="500"
          border
        >
          <template v-for="item in rowList">
            <el-table-column
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              align="center">
              <template slot-scope="scope">
                <template>
                    {{(scope.row[item.field_alias] || scope.row[item.field_alias]  === 0)? scope.row[item.field_alias] : '--'}}
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align='center'
            fixed='right'
            field_alias='operation'
            label='操作'
            width="180"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="handleAdd(scope.row)">添加</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class='pagina' style="margin: 0;">
          <el-pagination
            background
            @size-change="handleSizeChange"
            layout='total, sizes, prev, pager, next, jumper'
            :total='tableData.total'
            :page-size='15'
            :page-sizes="[15, 30, 40, 50]"
            @current-change='handleCurrentChange'
          ></el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { getAllUserlists as getListReq } from '@/api/member/memberList/index';

export default {
  data() {
    return {
      page: 1,
      limit: 15,
      loading: false,
      tableData: {
        list: [],
        total: 0,
      },
      rowList: [
        { field_text: '会员名', field_alias: 'realname' },
        { field_text: '会员等级', field_alias: 'client_level_text' },
        { field_text: '储值金', field_alias: 'storage_money' },
        { field_text: '手机号', field_alias: 'username' },
        { field_text: '注册时间', field_alias: 'register_time' },
        { field_text: '当前剩余积分', field_alias: 'integral' },
        { field_text: '累计消费积分', field_alias: 'consume_integral' },
        { field_text: '距离结婚纪念日(天数)', field_alias: 'commemorationday' },
        { field_text: '距离生日(天数)', field_alias: 'birthday' },
      ],
      form: {},
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.getList();
  },
  methods: {
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    handleAdd(memberInfo) {
      this.$emit('onAdd', memberInfo);
      this.handleClose();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      this.loading = true;
      getListReq({ ...this.form, page: this.page, limit: this.limit })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item) => {
              // item.register_time && (item.register_time = moment(item.register_time * 1000).format('YYYY-MM-DD HH:mm:ss'));
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 新增会员
    toMember() {
      this.handleClose();
      this.$emit('onAddNumber');
      // this.$router.push('/member/memberList/list');
    },
    onSubmit() {
      this.page = 1;
      this.getList();
    },
    handleClose() {
      this.$emit('onHideNumber');
    },
    handleTrue() {},
  },
};
</script>

<style lang="less" scoped>
.form {
  display: flex;
}
.line {
  width: 1px;
  height: 40px;
  background: #ccc;
}
</style>
