import { post } from '@/utils/request';

// 优惠券列表
const getListReq = (data) => post({
  url: '/store/market.MarketStoreCard/getList',
  data: data,
});

// 获取会员体系
const getClientSystemListReq = (data) => post({
  url: '/store/common.Select/getClientSystemList',
  data: data,
});

// 根据体系获取绑定的门店
const getClientSystemHierarchyForIDReq = (data) => post({
  url: '/store/common.Select/getClientSystemHierarchyForID',
  data: data,
});

// 新增优惠券
const createReq = (data) => post({
  url: '/store/market.MarketStoreCard/create',
  data: data,
});

// 编辑优惠券
const updateReq = (data) => post({
  url: '/store/market.MarketStoreCard/update',
  data: data,
});

// 获取详情
const detailReq = (data) => post({
  url: '/store/market.MarketStoreCard/detail',
  data: data,
});

// 改变状态
const handleStatusReq = (data) => post({
  url: '/store/market.MarketStoreCard/handleStatus',
  data: data,
});

// 获取优惠券明细列表
const getClientCardListReq = (data) => post({
  url: '/store/market.MarketStoreCard/clientCardList',
  data: data,
});

// 获取默认时间
const getDefaultReq = (data) => post({
  url: '/store/common.Time/getDefult',
  data: data,
});

// 设置默认时间
const setDefultReq = (data) => post({
  url: '/store/common.Time/setDefult',
  data: data,
});

// 优惠券分析线性趋势图
const saleLineReq = (data) => post({
  url: '/store/market.MarketStoreCard/saleLine',
  data: data,
});

// 优惠券分析线性趋势图分析
const analyzingReq = (data) => post({
  url: '/store/market.MarketStoreCard/analyzing',
  data: data,
});

// 优惠券领取占比分析-饼图
const drawPieReq = (data) => post({
  url: '/store/market.MarketStoreCard/drawPie',
  data: data,
});

// 优惠券转化占比分析-饼图
const salePieReq = (data) => post({
  url: '/store/market.MarketStoreCard/salePie',
  data: data,
});

// 优惠券商品使用占比分析
const goodsAnalyzeReq = (data) => post({
  url: '/store/market.MarketStoreCard/goodsAnalyze',
  data: data,
});

// 发放优惠券
const getCardReq = (data) => post({
  url: '/store/customer.ClientCardVoucher/getCard',
  data: data,
});


export {
  getCardReq,
  goodsAnalyzeReq,
  drawPieReq,
  salePieReq,
  analyzingReq,
  saleLineReq,
  setDefultReq,
  getDefaultReq,
  getListReq,
  getClientSystemListReq,
  getClientSystemHierarchyForIDReq,
  createReq,
  updateReq,
  detailReq,
  handleStatusReq,
  getClientCardListReq,
}
