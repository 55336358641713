import { post } from '@/utils/request.js';

const goodsReq = (params) => post({
  url: '/store/goods.Goods/getGoodsList',
  data: {
    ...params,
  },
});

export default goodsReq;
