import { post } from '@/utils/request.js';

// 获取所有条件
const commonSearchReq = (function_code, tab, mode) => post({
  url: '/store/common.search/index',
  data: {
    function_code,
    tab,
    mode,
  },
});



// 获取下拉选项
const getOptionReq = (select_code, data) => post({
  url: `/store/common.select/${select_code}`,
  data,
});

// 设置条件
const setSearchReq = ({ function_code, data, tab, mode }) => post({
  url: '/store/common.search/set',
  data: {
    function_code,
    data,
    tab,
    mode,
  },
});

export {
  commonSearchReq,
  getOptionReq,
  setSearchReq
};

