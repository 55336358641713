import { post } from '@/utils/request';

// 快捷退货-搜索货品
const getOrderListByGoodsNumberReq = (data) => post({
  url: '/store/order.Order/getOrderListByGoodsNumber',
  data: {
    ...data,
  },
});

// 是否启用批量成本价销售
const getCanUseCostPriceReq = (data) => post({
  url: '/store/order.Order/getCanUseCostPrice',
  data: {
    ...data,
  },
});

// 是否显示按成本价销售按钮
const getCanSaleByCostReq = (data) => post({
  url: '/store/order.Order/getCanSaleByCost',
  data: {
    ...data,
  },
});


// 计算使用优惠券
const useDiscountReq = (data) => post({
  url: '/store/order.Order/useDiscount',
  data: {
    ...data,
  },
});


// 计算优惠使用的接口(新)
const useDiscountNewReq=(data)=>post({
  url:'/store/order.Order/getDiscountList',
  data:{
    ...data
  }
})

// 通过货号获取仓库库存
const getGoodsNumberStockReq = (data) => post({
  url: '/store/goods.Goods/getGoodsNumberStock',
  data: {
    ...data,
  },
});

// 获取员工列表（主辅销售)
const getEmployeeReq = () => post({
  url: '/store/authority.User/getEmployee',
});

// 获取旧料货品字段
const getRecycleGoodsInfoReq = (goods_type_id, goods_number_type, warehouse_id, supplier_id) => post({
  url: '/store/order.Order/getRecycleGoodsInfo',
  data: {
    goods_type_id,
    goods_number_type,
    warehouse_id,
    supplier_id,
  },
});

// 创建订单
const createOrderReq = (cond) => post({
  url: '/store/order.Order/createOrder',
  data: {
    ...cond,
  },
});

// 获取仓库列表
const getStoreListReq = () => post({
  url: '/store/goods.EntryCtl/getWarehouseList',
});

// 获取供应商列表
const getSupplierListReq = () => post({
  url: '/store/goods.EntryCtl/getSupplierList',
});

// 创建退后单
const createReturnReq = (cond) => post({
  url: '/store/order.Refund/create',
  data: {
    ...cond,
  },
});

// 创建赎回单
const createRedeemReq = (cond) => post({
  url: '/store/order.Redemption/create',
  data: {
    ...cond,
  },
});

// 根据会员积分获取抵扣金额
const getAmountByIntegralReq = (integral) => post({
  url: '/store/order.Order/getAmountByIntegral',
  data: {
    integral,
  },
});

// 获取下单货品会员折扣
const getCustomerDiscountReq = ({ client_user_id, goods_id }) => post({
  url: '/store/order.Order/getCustomerDiscount',
  data: {
    client_user_id,
    goods_id,
  },
});

// 获取货品最低折扣
const getGoodsDiscountReq = (params) => post({
  url: '/store/order.Order/getGoodsDiscount',
  data: {
    ...params,
  },
});

// 搜索已售货品(旧料)
const getSaleGoodsInfoReq = (goods_number_search) => post({
  url: '/store/order.Order/getSaleGoodsInfo',
  data: {
    goods_number_search,
  },
});

// 挂单
const mountOrderReq = (params) => post({
  url: '/store/order.PutUp/create',
  data: {
    put_type: 'pc',
    ...params,
  },
});

// 提单
const getMountOrderReq = (order_number) => post({
  url: '/store/order.Order/getMountOrder',
  data: {
    order_number,
  },
});

// 获取优惠券
const getDiscountListReq = (params) => post({
  url: '/store/order.Order/getDiscountList',
  data: {
    ...params,
  },
});

// 添加改价授权单
const creaAuthReq = (params) => post({
  url: '/store/order.Authorize/create',
  data: {
    ...params,
  },
});

// 轮询接口
const getAuthorizeReq = (code) => post({
  url: '/store/order.Authorize/getAuthorize',
  data: {
    code,
  },
});

// 获取预存列表
const getPreListReq = (client_user_id, paid_in_amount) => post({
  url: '/store/order.Order/getPrestoreList',
  data: {
    client_user_id,
    paid_in_amount,
  },
});

// 仓库列表
const getShopAndMerchantReq = () => post({
  url: 'store/common.select/getShopAndMerchant',
});

// 金额换算成积分
const getAmountReq = (params) => post({
  url: '/store/order.Order/getAmountByIntegral',
  data: {
    ...params,
  },
});

// 根据金额获取积分
const getIntegralByAmountReq = (params) => post({
  url: '/store/order.Order/getIntegralByAmount',
  data: {
    ...params,
  },
});

// 获取全部商家属性
const getAttrListReq = (params) => post({
  url: '/store/common.Select/getAttrList',
  data: {
    ...params,
  },
});

// 获取回收单价
const getRecoverGoldPriceReq = (params) => post({
  url: '/store/goods.RecoverExpression/getRecoverGoldPrice',
  data: {
    ...params,
  },
});
// 反确认编辑
const resetOrderDetailReq = (params) => post({
  url: '/store/order.Order/resetOrderDetail',
  data: {
    ...params,
  },
});

// 反确认下单
const resetOrderReq = (params) => post({
  url: '/store/order.Order/resetOrder',
  data: {
    ...params,
  },
});

// 获取品类的抵扣比例
const settingListReq = (params) => post({
  url: '/store/common.Select/settingList',
  data: {
    ...params,
  },
});

// 计算储值金折扣
const countOrderStorageReq = (params) => post({
  url: '/store/order.Order/countOrderStorage',
  data: {
    ...params,
  },
});

// 获取员工销售比例设置
const getOrderEmployeePercentReq = (params) => post({
  url: '/store/hierarchy.HierarchySetCtl/getOrderEmployeePercent',
  data: {
    ...params,
  },
});

// 员工销售比例设置
const orderPercentSetReq = (params) => post({
  url: '/store/hierarchy.HierarchySetCtl/OrderPercentSet',
  data: {
    ...params,
  },
});

// 获取质保单
const getOrderZbd = (data) => {
  return post({
      url: '/store/order.Order/getOrderZbd?order_id='+data.order_id,
      data: data
  })
}

export {
  useDiscountReq,
  getCanUseCostPriceReq,
  getCanSaleByCostReq,
  getOrderListByGoodsNumberReq,
  orderPercentSetReq,
  getOrderEmployeePercentReq,
  getGoodsNumberStockReq,
  countOrderStorageReq,
  settingListReq,
  getRecoverGoldPriceReq,
  resetOrderReq,
  resetOrderDetailReq,
  getAttrListReq,
  getEmployeeReq,
  getRecycleGoodsInfoReq,
  createOrderReq,
  getStoreListReq,
  getSupplierListReq,
  createReturnReq,
  getCustomerDiscountReq,
  getGoodsDiscountReq,
  getSaleGoodsInfoReq,
  mountOrderReq,
  getMountOrderReq,
  createRedeemReq,
  getDiscountListReq,
  creaAuthReq,
  getAuthorizeReq,
  getPreListReq,
  getShopAndMerchantReq,
  getAmountReq,
  getIntegralByAmountReq,
  useDiscountNewReq,//新的计算优惠券
  getOrderZbd
};
