import { post } from '@/utils/request';

const getListReq = (params) => post({
  url: '/store/goods.RecoverExpression/list',
  data: {
    ...params,
  },
});

// 旧料品类
const getTypeReq = (params) => post({
  url: '/store/goods.GoodsType/list',
  data: {
    ...params,
  },
});

// 变量字段
const getRecExpressionFieldReq = (params) => post({
  url: '/store/goods.RecoverExpression/getRecExpressionField',
  data: {
    ...params,
  },
});

// 提交
const addReq = (params) => post({
  url: '/store/goods.RecoverExpression/add',
  data: {
    ...params,
  },
});

// 删除
const delReq = (params) => post({
  url: '/store/goods.RecoverExpression/add',
  data: {
    ...params,
  },
});

// 获取品类回收方式
const getRecFormulaReq = (params) => post({
  url: '/store/goods.RecoverExpression/getRecFormula',
  data: {
    ...params,
  },
});

const getRecoverAmountReq = (params) => post({
  url: '/store/goods.RecoverExpression/getRecoverAmount',
  data: {
    ...params,
  },
});

export {
  getRecoverAmountReq,
  getRecFormulaReq,
  delReq,
  getListReq,
  getTypeReq,
  getRecExpressionFieldReq,
  addReq,
};
