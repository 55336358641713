<template>
  <div>
    <el-dialog
      title="添加卡券"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div class="tabs">
        <div class="tabs-item" @click="currTabs = 1" :class="currTabs === 1? 'active': ''">
          可用({{cardList.available.length || 0}})
        </div>
        <div class="tabs-item" @click="currTabs = 2" :class="currTabs === 2? 'active': ''">
          不可用({{cardList.unavailable.length || 0}})
        </div>
      </div>
      <div style="height: 500px; overflow-y: auto; overflow-x: hidden;">
        <div class="radio" v-if="currTabs === 1">
          <el-radio-group v-model="radio">
            <template v-for="item in cardList.available">
            <el-radio :label="item.client_card_voucher_id" style="margin-bottom: 10px;" :key="item.client_card_voucher_id">
                <div class="box" :style="item.client_card_voucher_id === radio? {border: '1px solid #409EFF',}: {}">
                  <div class="left" :style="item.client_card_voucher_id === radio? {borderRight: '1px dashed #409EFF',}: {}">
                    <div class="price">
                      <template  v-if="item.card_type === 10 || item.card_type === 30">
                        <span>￥</span>{{item.certificate}}<span v-if="item.decimal">.{{item.decimal}}</span>
                      </template>
                      <template  v-if="item.card_type === 20">
                        {{item.worth}}<span>折</span>
                      </template>
                      <!-- <span>.00</span> -->
                    </div>
                    <div class="label">{{item.disc_range}}</div>
                  </div>
                  <div class="right">
                    <!-- <div>新人专享</div> -->
                    <div style="margin: 10px 0;">{{item.title}}</div>
                    <div class="time">有效期：{{item.start_time}}-{{item.end_time}}</div>
                  </div>
                </div>
            </el-radio>
            </template>
          </el-radio-group>
          <div v-if="!cardList.available.length" style="text-align: center;">暂无数据</div>
        </div>
        <div class="radio" v-if="currTabs === 2">
          <el-radio-group v-model="radio">
            <template v-for="item in cardList.unavailable">
            <el-radio :label="item.client_card_voucher_id" style="margin-bottom: 10px;" :key="item.client_card_voucher_id" disabled>
                <div class="box">
                  <div class="left">
                    <div class="price">
                      <template  v-if="item.card_type === 10 || item.card_type === 30">
                        <span>￥</span>{{item.certificate}}<span>.{{item.decimal}}</span>
                      </template>
                      <template  v-if="item.card_type === 20">
                        {{item.worth}}<span>折</span>
                      </template>
                      <!-- <span>.{{item.decimal}}</span> -->
                    </div>
                    <div class="label">{{item.disc_range}}</div>
                  </div>
                  <div class="right">
                    <!-- <div>新人专享</div> -->
                    <div style="margin: 10px 0;">{{item.title}}</div>
                    <div class="time">有效期：{{item.start_time_ymd}}-{{item.end_time_ymd}}</div>
                  </div>
                </div>
            </el-radio>
            </template>
          </el-radio-group>
          <div v-if="!cardList.unavailable.length" style="text-align: center;">暂无数据</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small ">取 消</el-button>
        <el-button type="primary" @click="handleTrue" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currTabs: 1,
      radio: '',
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    cardList: {
      type: Object,
      default() {
        return {
          available: [],
          unavailable: [],
        };
      },
    },
    clearCard: {
      type: Boolean,
    },
    money: {
      type: [String, Number],
    },
    cardId: {
      type: [String, Number],
    },
  },
  created() {
    if (this.clearCard) {
      this.radio = '';
    }
    if (this.cardId) {
      this.radio = this.cardId;
    }
  },
  methods: {
    handleTrue() {
      if (!this.radio) {
        this.$message.warning('请选择优惠券');
        return;
      }
      if (!this.money) {
        this.$message.warning('订单金额为0不可使用优惠券');
        return;
      }
      if (this.currTabs === 1) {
        const arr = this.cardList.available.filter((item) => item.client_card_voucher_id === this.radio);
        this.$emit('onChooseCard', arr[0]);
      }
      if (this.currTabs === 2) {
        const arr = this.cardList.unavailable.filter((item) => item.client_card_voucher_id === this.radio);
        this.$emit('onChooseCard', arr[0]);
      }
      this.$emit('onHideCard');
    },
    handleClose() {
      this.$emit('onHideCard');
    },
  },
};
</script>

<style lang="less" scoped>
 /deep/ .el-button--primary {
  height: auto !important;
}
.radio {
  margin-top: 10px;
  // padding-left: 10px;
  /deep/ .el-radio {
    display: flex;
    align-items: center;
  }
  /deep/ .el-radio__label  {
    display: block;
  }
  .box {
    border: 1px solid #eee;
    width: 500px;
    height: 100px;
    border-radius: 10px;
    display: flex;
    .left {
      width: 35%;
      border: 1px dashed #eee;
      padding: 0 10px;
      box-sizing: border-box;
      .price {
        color: #409EFF;
        font-size: 25px;
        text-align: center;
        margin: 30px 0 5px 0;
        span {
          font-size: 16px;
        }
      }
      .label {
        color: #ccc;
        font-size: 12px;
        text-align: center;
      }
    }
    .right {
      width: 65%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;
      .time {
        color: #ccc;
        font-size: 12px;
      }
    }
  }
}
.tabs {
  border-bottom: 1px solid #eee;
  display: flex;
  padding-bottom: 15px;
  &-item {
    width: 50%;
    text-align: center;
    position: relative;
    cursor: pointer;
  }
  .active {
    color: #409EFF;
    &::after {
      content: '';
      width: 20px;
      height: 4px;
      background: #409EFF;
      bottom: -15px;
      left: 45%;
      position: absolute;
      border-radius: 99px;
    }
  }
}
</style>
