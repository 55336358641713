import { post } from '@/utils/request';

// 月结列表
const getSettlementList = (data) => post({
    url: '/store/shop.Settlement/getList',
    data: data
});

//  解锁操作
const getIsLock = (data) => post({
    url: '/store/shop.Settlement/getIsLock',
    data: data
});


// 月结操作
const handelSettlement = (data) => post({
    url: '/store/shop.Settlement/handelSettlement',
    data: data
});

export {
    getSettlementList,
    getIsLock,
    handelSettlement
}

