<template>
  <div>
    <div class="card" style="margin-bottom: 15px">
      <el-page-header @back="goBack" content="详情页面"></el-page-header>
    </div>
    <div class="content" style="border-radius: 6px; border: 1px solid #e8e8e8; padding-top: 27px">
      <div style="display: flex; width: 100%">
        <div class="head">
          <div class="head_img" :style="'background:' + 'url(' + (info.avatar || no_user) + ')'"></div>
          <div class="consume">距离下一等级还需消费</div>
          <div class="consume_money" v-if="info.margin_price !== 0">
            ￥{{ info.margin_price && info.margin_price.split(".")[0]
            }}<span style="font-size: 12px">.{{ info.margin_price && info.margin_price.split(".")[1] }}</span>
          </div>
          <div class="consume_money" v-if="info.margin_price === 0">￥0</div>
        </div>
        <div>
          <div class="infoLine">
            <div class="infoTitle">会员名称</div>
            <div class="infoAbout">{{ info.realname }}</div>
          </div>
          <div class="infoLine">
            <div class="infoTitle">性别</div>
            <div class="infoAbout">{{ gender_text[info.gender] }}</div>
          </div>
          <div class="infoLine">
            <div class="infoTitle">手机号码</div>
            <div class="infoAbout">{{ info.username || "--" }}</div>
          </div>
          <div class="infoLine">
            <div class="infoTitle">Email</div>
            <div class="infoAbout">{{ info.email || "--" }}</div>
          </div>
          <div class="infoLine">
            <div class="infoTitle">备注</div>
            <div class="infoAbout">{{ info.remark || "--" }}</div>
          </div>
        </div>
        <div>
          <div class="infoLine">
            <div class="infoTitle">出生日期</div>
            <div class="infoAbout">{{ info.birthday_time || "--" }}</div>
          </div>

          <div class="infoLine">
            <div class="infoTitle">结婚纪念日</div>
            <div class="infoAbout">{{ info.commemoration_time || "--" }}</div>
          </div>
          <div class="infoLine">
            <div class="infoTitle">当前积分</div>
            <div class="infoAbout">{{ info.integral || "--" }}</div>
          </div>
          <div class="infoLine">
            <div class="infoTitle">累计消费积分</div>
            <div class="infoAbout">{{ info.consume_integral || "--" }}</div>
          </div>
          <div class="infoLine">
            <div class="infoTitle">协助人</div>
            <div class="infoAbout" style="width: 200px; line-height: 30px">
              {{ info.assist_store_user_name }}
              <div style="display: flex; line-height: 16px; color: #2791ff" class="editFollow"
                v-if="memberType && memberType === 'mineMember'" @click="addAssignUser">
                <img src="../../../../assets/images/member/editFollow.png"
                  style="margin: 0 5px; width: 16px; height: 16px" />编辑协助人
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="infoLine">
            <div class="infoTitle">会员等级</div>
            <div class="infoAbout">{{ info.user_level_name || "--" }}</div>
          </div>
          <div class="infoLine">
            <div class="infoTitle">跟进人</div>
            <div class="infoAbout">
              {{ info.follow_store_user_name || "--" }}
            </div>
          </div>
          <div class="infoLine">
            <div class="infoTitle">储值金余额</div>
            <div class="infoAbout">{{ info.storage_money || "--" }}</div>
          </div>
          <div class="infoLine">
            <div class="infoTitle">地址</div>
            <div class="infoAbout">{{ info.address || "--" }}</div>
          </div>
        </div>
      </div>
      <div class="mline"></div>
      <div class="tabs" v-if="!client_system_id">
        <div style="line-height: 50px">会员标签</div>
        <div class="addTab" @click="handleAddTags" v-if="
          memberType !== 'waitAssignMember' &&
          userInfo.realname === info.follow_store_user_name
        ">
          新增标签
        </div>
        <template v-for="(item, index) in arr_tags_name">
          <div :key="index" class="tagsBox">
            {{ item.tag_name }}
            <i class="el-icon-close" @click="handleCloseTags(item)" v-if="memberType === 'mineMember'"></i>
          </div>
        </template>
      </div>
    </div>
    <div class="content record">
      <el-tabs v-model="activeName" @tab-click="handleTab()" style="margin: 0px 15px 0 15px">
        <el-tab-pane label="购买记录" name="first" style="padding-bottom: 15px">
          <div class="table" style="border-top: 1px solid #e8e8e8">
            <el-table :data="buyList" stripe>
              <el-table-column align="center" prop="order_number" label="订单号">
                <template slot-scope="scope">
                  {{ scope.row.order_number }}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="create_time" label="下单时间"></el-table-column>
              <el-table-column align="center" prop="main_store_user_name" label="主销售"></el-table-column>
              <el-table-column align="center" prop="order_amount" label="订单金额"></el-table-column>
              <el-table-column align="center" prop="raw_amount" label="旧料金额"></el-table-column>
              <el-table-column align="center" prop="discount_amount" label="优惠金额"></el-table-column>
              <el-table-column align="center" prop="paid_in_amount" label="实收金额"></el-table-column>
              <el-table-column align="center" prop="order_number" label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="toOrderDetails(scope.row)">
                    订单详情
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="积分记录" name="second">
          <div class="table" style="border-top: 1px solid #e8e8e8">
            <el-table :data="integralList" stripe>
              <el-table-column align="center" prop="hand_type_text" label="类型"></el-table-column>
              <el-table-column align="center" prop="integral" label="变更分值"></el-table-column>
              <el-table-column align="center" prop="now_integral" label="操作后剩余"></el-table-column>
              <el-table-column align="center" prop="hand_user_realname" label="操作人"></el-table-column>
              <el-table-column align="center" prop="create_time" label="操作时间"></el-table-column>
              <el-table-column align="center" prop="remarks" label="备注" width="400"></el-table-column>
            </el-table>
          </div>
          <div class="pagina">
            <el-pagination background layout="total, prev, pager, next, jumper" :total="integralTotal" :page-size="15"
              @current-change="handleIntCurrentChange"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="储值记录" name="third" style="padding-bottom: 15px">
          <div class="table" style="border-top: 1px solid #e8e8e8">
            <el-table :data="storageList" stripe>
              <el-table-column align="center" prop="type" label="类型">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.type === 10" style="color: #21bc36">增加</span>
                    <span v-if="scope.row.type === 20" style="color: #fd563a">扣减</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="storage_money" label="储值金变动">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.type === 10" style="color: #21bc36">+{{ scope.row.storage_money }}</span>
                    <span v-if="scope.row.type === 20" style="color: #fd563a">-{{ scope.row.storage_money }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="current_storage_money" label="储值金余额"></el-table-column>
              <el-table-column align="center" prop="realname" label="操作人"></el-table-column>
              <el-table-column align="center" prop="create_time" label="操作时间"></el-table-column>
              <el-table-column align="center" prop="log" label="日志"></el-table-column>
              <el-table-column align="center" prop="remark" label="备注" width="400"></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="优惠券" name="coupon" style="padding-bottom: 15px" v-if="showCoupon">
          <!-- 未使用 -->
          <el-tabs v-model="checkCouponType" @tab-click="handleCoupon">
            <el-tab-pane label="通用" name="1">
              <div class="coupon-list">
                <template v-for="item in couponList.unused">
                  <div class="coupon" :key="item.coupon_id">
                    <div class="coupon-left" :style="{
                      backgroundImage:
                        item.type_seq == 10||item.type_seq==30
                          ? 'url(' + coupon_type1 + ')'
                          : 'url(' + coupon_type2 + ')',
                    }">

                      <div class="coupon-left-money" v-if="item.type_seq == 10 || item.type_seq == 20|| item.type_seq ==30">
                        <span class="coupon-left-txt">￥</span>
                        <span>{{ item.type_data }}</span>
                        <span class="coupon-left-txt" v-if="item.type_seq == 20">折</span>
                      </div>

                      <div class="coupon-left-money" v-if="item.type_seq == 40">
                        <div>礼品</div>
                      </div>

                      <div class="coupon-left-desc" v-if="item.use_threshold !== 0">
                        满{{ item.use_threshold }}元可用
                      </div>
                    </div>
                    <div class="coupon-right">
                      <div class="coupon-right-title">{{ item.title_name }}</div>
                      <div class="coupon-right-time">
                        <div style="flex-shrink: 0; width: 70px">有效期：</div>
                        <div>
                          {{ item.use_start_time.split(' ')[0] }} -
                          {{ item.use_end_time.split(' ')[0] }}
                        </div>
                      </div>
                      <div class="coupon-right-create">
                        发券时间：{{ item.create_time }}
                      </div>
                      <div class="coupon-right-creator">
                        发券人：{{ item.store_user_name }}
                      </div>
                      <el-button class="coupon-btn" type="danger" size="mini" plain @click="handleDele(item)">作废优惠券
                      </el-button>
                    </div>
                    <div class="coupon-unuse">未使用</div>

                  </div>
                </template>
              </div>

              <!-- 已使用 -->
              <div class="coupon-list"  >
                <template v-for="item in couponList.has_used">
                <div class="coupon" :key="item.coupon_id">
                  <div class="coupon-left" :style="{
                    backgroundImage:
                      item.type_seq == 10||item.type_seq==30
                        ? 'url(' + coupon_type1 + ')'
                        : 'url(' + coupon_type2 + ')',
                  }">
                    <div class="coupon-left-money"
                      v-if="item.type_seq == 10 || item.type_seq == 20 || item.type_seq == 30">
                      <span class="coupon-left-txt">￥</span>
                      <span>{{ item.type_data }}</span>
                      <span class="coupon-left-txt" v-if='item.type_seq == 20'>折</span>
                    </div>
                    <div class="coupon-left-desc" v-if="item.use_threshold != 0">
                      满{{ item.use_threshold }}元可用
                    </div>
                  </div>
                  <div class="coupon-right">
                    <div class="coupon-right-title">{{ item.title_name }}</div>
                    <div class="coupon-right-time">
                      <div style="flex-shrink: 0; width: 70px">有效期：</div>
                      <div>
                        {{ item.use_start_time.split(' ')[0] }} -
                        {{ item.use_end_time.split(' ')[0] }}
                      </div>
                    </div>
                    <div class="coupon-right-create">
                      发券时间：{{ item.create_time }}
                    </div>
                    <div class="coupon-right-creator">
                      发券人：{{ item.store_user_name }}
                    </div>

                    <div class="coupon-btn2" type="text" size="mini" plain @click="toOrder(item)">
                      查看使用订单
                      <i class="el-icon-arrow-right" style="font-weight: bold"></i>
                    </div>
                  </div>
                  <div class="coupon-used">已使用</div>
                </div>
                </template>
              </div>
              <!-- 已过期 -->
              <div class="coupon-list"  >
              <template v-for="item in couponList.expired">
                <div class="coupon" :key="item.coupon_id">
                  <div class="coupon-left" :style="{
                    backgroundImage:
                        item.type_seq == 10||item.type_seq==30
                        ? 'url(' + coupon_type1 + ')'
                        : 'url(' + coupon_type2 + ')',
                  }">
                    <div class="coupon-left-money"
                      v-if="item.type_seq == 10 || item.type_seq == 20 || item.type_seq === 30">
                      <span class="coupon-left-txt">￥</span>
                      <span>{{ item.type_data }}</span>
                      <span class="coupon-left-txt" v-if="item.use_rule == 20">折</span>
                    </div>
                  </div>
                  <div class="coupon-right">
                    <div class="coupon-right-title">{{ item.title_name }}</div>
                    <div class="coupon-right-time">
                      <div style="flex-shrink: 0; width: 70px">有效期：</div>
                      <div>
                        {{ item.use_start_time.split(' ')[0] }} -
                        {{ item.use_end_time.split(' ')[0] }}
                      </div>
                    </div>
                    <div class="coupon-right-create">
                      发券时间：{{ item.create_time }}
                    </div>
                    <div class="coupon-right-creator">
                      发券人：{{ item.store_user_name }}
                    </div>
                  </div>  <div class="coupon-expires">已过期</div>
                </div>
                </template>
              </div>
              <!-- 已作废 -->
              <div class="coupon-list"  >
              <template v-for="item in couponList.destruction">
                <div class="coupon" :key="item.coupon_id">
                  <div class="coupon-left" :style="{
                    backgroundImage:
                        item.type_seq == 10||item.type_seq==30
                        ? 'url(' + coupon_type1 + ')'
                        : 'url(' + coupon_type2 + ')',
                  }">
                    <div class="coupon-left-money"
                      v-if="item.type_seq == 10 || item.type_seq == 20 || item.type_seq === 30">
                      <span class="coupon-left-txt">￥</span>
                      <span>{{ item.type_data }}</span>
                      <span class="coupon-left-txt" v-if="item.use_rule == 20">折</span>
                    </div>
                  </div>
                  <div class="coupon-right">
                    <div class="coupon-right-title">{{ item.title_name }}</div>
                    <div class="coupon-right-time">
                      <div style="flex-shrink: 0; width: 70px">有效期：</div>
                      <div>
                        {{ item.use_start_time.split(' ')[0] }} -
                        {{ item.use_end_time.split(' ')[0] }}
                      </div>
                    </div>
                    <div class="coupon-right-create">
                      发券时间：{{ item.create_time }}
                    </div>
                    <div class="coupon-right-creator">
                      发券人：{{ item.store_user_name }}
                    </div>
                  </div>  <div class="coupon-expires">已作废</div>
                </div>
                </template>
              </div>
            </el-tab-pane>

            <el-tab-pane label="人情" name="2">
              <div class="coupon-list">
                <template v-for="item in couponList.unused">
                  <div class="coupon" :key="item.coupon_id">
                    <div class="coupon-left" :style="{
                      backgroundImage:
                          item.type_seq == 10||item.type_seq==30
                          ? 'url(' + coupon_type1 + ')'
                          : 'url(' + coupon_type2 + ')',
                    }">

                      <div class="coupon-left-money" v-if="item.type_seq == 10 || item.type_seq == 20||item.type_seq==30">
                        <span class="coupon-left-txt">￥</span>
                        <span>{{ item.type_data }}</span>
                        <span class="coupon-left-txt" v-if="item.type_seq == 20">折</span>
                      </div>

                      <div class="coupon-left-money" v-if="item.type_seq == 40">
                        <div>礼品</div>
                      </div>

                      <div class="coupon-left-desc" v-if="item.use_threshold !== 0">
                        满{{ item.use_threshold }}元可用
                      </div>
                    </div>

                    <div class="coupon-right">
                      <div class="coupon-right-title">{{ item.title_name }}</div>
                      <div class="coupon-right-time">
                        <div style="flex-shrink: 0; width: 70px">有效期：</div>
                        <div>
                          {{ item.use_start_time.split(' ')[0] }} -
                          {{ item.use_end_time.split(' ')[0] }}
                        </div>
                      </div>
                      <div class="coupon-right-create">
                        发券时间：{{ item.create_time }}
                      </div>
                      <div class="coupon-right-creator">
                        发券人：{{ item.store_user_name }}
                      </div>
                      <el-button class="coupon-btn" type="danger" size="mini" plain @click="handleDele(item)">作废优惠券
                      </el-button>
                    </div>
                    <div class="coupon-unuse">未使用</div>
                  </div>
                </template>
              </div>

              <!-- 已使用 -->
              <div class="coupon-list" >
              <template v-for="item in couponList.has_turn">
                <div class="coupon" :key="item.coupon_id">
                  <div class="coupon-left" :style="{
                    backgroundImage:
                       item.type_seq == 10||item.type_seq==30
                        ? 'url(' + coupon_type1 + ')'
                        : 'url(' + coupon_type2 + ')',
                  }">
                    <div class="coupon-left-money"
                      v-if="item.type_seq === 10 || item.type_seq == 20 || item.type_seq == 30">
                      <span class="coupon-left-txt">￥</span>
                      <span>{{ item.type_data }}</span>
                      <span class="coupon-left-txt" v-if='item.type_seq == 20'>折</span>
                    </div>
                    <div class="coupon-left-desc" v-if="item.use_threshold != 0">
                      满{{ item.use_threshold }}元可用
                    </div>
                  </div>
                  <div class="coupon-right">
                    <div class="coupon-right-title">{{ item.title_name }}</div>
                    <div class="coupon-right-time">
                      <div style="flex-shrink: 0; width: 70px">有效期：</div>
                      <div>
                        {{ item.use_start_time.split(' ')[0] }} -
                        {{ item.use_end_time.split(' ')[0] }}
                      </div>
                    </div>
                    <div class="coupon-right-create">
                      发券时间：{{ item.create_time }}
                    </div>
                    <div class="coupon-right-creator">
                      发券人：{{ item.store_user_name }}
                    </div>

                    <div class="coupon-btn2" type="text" size="mini" plain @click="toOrder(item)">
                      查看使用订单
                      <i class="el-icon-arrow-right" style="font-weight: bold"></i>
                    </div>
                  </div>
                  <div class="coupon-used">已使用</div>
                </div>
                </template>
              </div>

              <!-- 已过期 -->
              <div class="coupon-list"  >
              <template v-for="item in couponList.expired">
                <div class="coupon" :key="item.coupon_id">
                  <div class="coupon-left" :style="{
                    backgroundImage:
                        item.type_seq === 10||item.type_seq==30
                        ? 'url(' + coupon_type1 + ')'
                        : 'url(' + coupon_type2 + ')',
                  }">
                    <div class="coupon-left-money"
                      v-if="item.type_seq === 10 || item.type_seq === 20 || item.type_seq == 30">
                      <span class="coupon-left-txt">￥</span>
                      <span>{{ item.type_data }}</span>
                      <span class="coupon-left-txt" v-if="item.use_rule == 20">折</span>
                    </div>
                  </div>
                  <div class="coupon-right">
                    <div class="coupon-right-title">{{ item.title_name }}</div>
                    <div class="coupon-right-time">
                      <div style="flex-shrink: 0; width: 70px">有效期：</div>
                      <div>
                        {{ item.use_start_time.split(' ')[0] }} -
                        {{ item.use_end_time.split(' ')[0] }}
                      </div>
                    </div>
                    <div class="coupon-right-create">
                      发券时间：{{ item.create_time }}
                    </div>
                    <div class="coupon-right-creator">
                      发券人：{{ item.store_user_name }}
                    </div>
                  </div>  <div class="coupon-expires">已过期</div>
                </div>
             </template>
              </div>
              <!-- 已做废 -->
              <div class="coupon-list"  >
              <template v-for="item in couponList.destruction">
                <div class="coupon" :key="item.coupon_id">
                  <div class="coupon-left" :style="{
                    backgroundImage:
                        item.type_seq === 10||item.type_seq==30
                        ? 'url(' + coupon_type1 + ')'
                        : 'url(' + coupon_type2 + ')',
                  }">
                    <div class="coupon-left-money"
                      v-if="item.type_seq === 10 || item.type_seq === 20 || item.type_seq == 30">
                      <span class="coupon-left-txt">￥</span>
                      <span>{{ item.type_data }}</span>
                      <span class="coupon-left-txt" v-if="item.use_rule == 20">折</span>
                    </div>
                  </div>
                  <div class="coupon-right">
                    <div class="coupon-right-title">{{ item.title_name }}</div>
                    <div class="coupon-right-time">
                      <div style="flex-shrink: 0; width: 70px">有效期：</div>
                      <div>
                        {{ item.use_start_time.split(' ')[0] }} -
                        {{ item.use_end_time.split(' ')[0] }}
                      </div>
                    </div>
                    <div class="coupon-right-create">
                      发券时间：{{ item.create_time }}
                    </div>
                    <div class="coupon-right-creator">
                      发券人：{{ item.store_user_name }}
                    </div>
                  </div>  <div class="coupon-expires">已做废</div>
                </div>
             </template>
              </div>
            </el-tab-pane>
          </el-tabs>

        </el-tab-pane>
        <el-tab-pane label="跟进线索" name="four" style="padding-bottom: 15px" v-if="!client_system_id">
          <div style="margin-left: 50px">
            <div class="title">跟进线索</div>
            <ul class="followStep">
              <template v-for="(item, index) in followStepList">
                <li :key="index" @click="updateClientStep(item, index)"
                  :style="item.isStep ? 'background: #2791ff;' : ''" :class="item.isStep ? 'hasStep' : ''">
                  {{ item.title }}
                </li>
              </template>
            </ul>
            <div style="color: #fd563a">
              温馨提示：可以通过点击线索按钮变更跟进线索
            </div>
            <div style="
                margin: 15px 0;
                width: 50%;
                height: 1px;
                background: #e8e8e8;
              "></div>
            <div style="margin-bottom: 15px">
              <div class="title" style="float: left; line-height: 30px">
                跟进记录（{{ tractList.length }}）
              </div>
              <el-button type="primary" size="small" style="margin-left: 80px" @click="handleAddFollow"
                v-if="memberType !== 'waitAssignMember'">添加跟进记录</el-button>
            </div>
            <template v-for="(item, index) in tractList">
              <div class="tract" :key="index">
                <div style="width: 100%; display: flex">
                  <div class="tractStateImg">
                    <img :src="item.state_image" />
                  </div>
                  <div>
                    <span>{{ item.state_title }} </span>
                    <span>{{ item.create_time }}</span>
                  </div>
                </div>
                <div class="tractInfo">
                  <div v-if="item.result_type" style="display: flex; margin-bottom: 20px">
                    <div class="resultType" v-if="item.result_type === 10">
                      <img src="../../../../assets/images/member/stisfied_yes.png" />
                    </div>
                    <div class="resultType" v-if="item.result_type === 20">
                      <img src="../../../../assets/images/member/commonly_yes.png" />
                    </div>
                    <div class="resultType" v-if="item.result_type === 30">
                      <img src="../../../../assets/images/member/dissatisfied_yes.png" />
                    </div>
                    <span style="color: #21bc36" v-if="item.result_type === 10">{{ item.result_title }}</span>
                    <span style="color: #ffae01" v-if="item.result_type === 20">{{ item.result_title }}</span>
                    <span style="color: #fd563a" v-if="item.result_type === 30">{{ item.result_title }}</span>
                  </div>
                  <div class="operator" style="
                      margin-bottom: 20px;
                      display: flex;
                      line-height: 30px;
                    " v-if="item.pic && item.pic.length !== 0">
                    <div style="font-weight: 600; margin-right: 20px">
                      跟进图片：
                    </div>
                    <template v-for="(pic, picIndex) in item.pic">
                      <el-image :key="picIndex" style="
                          margin-right: 8px;
                          border-radius: 3px;
                          width: 48px;
                          height: 48px;
                          overflow: hidden;
                        " :src="pic" fit="cover" :preview-src-list="item.pic">
                      </el-image>
                    </template>
                  </div>
                  <div class="operator" style="
                      margin-bottom: 20px;
                      display: flex;
                      line-height: 30px;
                    ">
                    <div style="font-weight: 600; margin-right: 20px">
                      操作人
                    </div>
                    <img :src="item.store_user_avatar" style="
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        margin-right: 8px;
                      " />
                    <div>{{ item.store_user_name }}</div>
                  </div>
                  <div class="operator">
                    <span style="font-weight: 600; margin-right: 30px" v-if="
                      item.follow_type &&
                      item.follow_type === 20 &&
                      item.remarks
                    ">备注</span>
                    <span>{{ item.remarks }}</span>
                  </div>
                  <div class="operator" v-if="item.next_follow_time !== ''">
                    <span style="
                        font-weight: 600;
                        margin: 15px 30px 0 0;
                        color: #2791ff;
                      ">下次跟进计划</span>
                    <span>{{ item.next_follow_time }}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </el-tab-pane>
        <el-tab-pane label="聊天记录" name="five" style="padding-bottom: 15px">
          <div style="margin-left: 30px">
            <el-form style="display: flex">
              <el-form-item>
                <el-input v-model="chatCond.keywords" placeholder="请输入内容" style="width: 220px" />
              </el-form-item>
              <el-form-item>
                <el-date-picker style="margin-left: 25px" type="daterange" v-model="chatCond.time" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="chatPickerOptions"></el-date-picker>
              </el-form-item>
              <div>
                <el-button type="primary" @click="searchData()" style="margin-left: 10px; height: 40px">搜索</el-button>
                <el-button @click="handleReset" style="height: 40px">重置</el-button>
              </div>
            </el-form>
          </div>
          <div style="margin-left: 30px; width: 50%; height: 35vh; overflow: auto" ref="chatContent"
            @scroll.passive="handleScroll($event)">
            <template v-for="(item, key, index) in chatData">
              <div :key="index" class="chatBox">
                <div class="chatTime">
                  {{ key }}
                </div>
                <div class="chatLine"></div>
                <div style="margin: 40px 30px 0 30px">
                  <template v-for="(chat, chatIndex) in item">
                    <div :key="chatIndex">
                      <div v-if="chat.send_type === 10">
                        <div style="
                            display: flex;
                            margin-bottom: 40px;
                            position: relative;
                          ">
                          <div class="avatar">
                            <img :src="chat.client_user_avatar" />
                          </div>
                          <div style="
                              position: absolute;
                              top: -8px;
                              left: 50px;
                              margin-bottom: 3px;
                              color: #b2b2b2;
                              font-size: 12px;
                            ">
                            {{ chat.client_user_realname }}
                            {{ chat.create_time.split(" ")[1] }}
                          </div>
                          <div style="margin: 0px 0 0 15px">
                            <div class="chatContentBox user" v-if="chat.is_image === 10">
                              <template v-for="(cont, contIndex) in chat.content">
                                <div :key="contIndex">{{ cont }}</div>
                              </template>
                            </div>

                            <div v-if="chat.is_image === 20">
                              <el-image style="
                                  margin-top: 15px;
                                  width: 30%;
                                  float: left;
                                  border-radius: 5px;
                                " fit="cover" :src="chat.content" :preview-src-list="[chat.content]"></el-image>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="chat.send_type === 20">
                        <div style="
                            position: relative;
                            display: flex;
                            flex-direction: row-reverse;
                            margin-bottom: 40px;
                          ">
                          <div class="avatar">
                            <img :src="chat.store_user_avatar" />
                          </div>
                          <div style="
                              position: absolute;
                              top: -15px;
                              right: 50px;
                              margin-bottom: 3px;
                              color: #b2b2b2;
                              font-size: 12px;
                              text-align: right;
                            ">
                            {{ chat.store_user_realname }}
                            {{ chat.create_time.split(" ")[1] }}
                          </div>
                          <div style="margin: -8px 15px 0 0">
                            <div class="chatContentBox store_user" v-if="chat.is_image === 10">
                              <template v-for="(cont, contIndex) in chat.content">
                                <div :key="contIndex">{{ cont }}</div>
                              </template>
                            </div>

                            <div v-if="chat.is_image === 20">
                              <el-image style="
                                  margin-top: 15px;
                                  width: 30%;
                                  float: right;
                                  border-radius: 5px;
                                " fit="cover" :src="chat.content" :preview-src-list="[chat.content]"></el-image>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog :visible.sync="tagsVisiable" title="添加标签" :destroy-on-close="true" :close-on-click-modal="false"
      width="30%" :before-close="cancelTagsDialog">
      <el-form>
        <el-form-item label="标签" label-width="120px">
          <el-autocomplete v-model="tagName" :fetch-suggestions="querySearchAsync" placeholder="请输入或者选择标签">
          </el-autocomplete>
        </el-form-item>
      </el-form>
      <div style="text-align: center">
        <el-button type="primary" @click="submitTags">保存</el-button>
        <el-button @click="cancelTagsDialog">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑协助人" :visible.sync="followVisiable" :close-on-click-modal="false" width="30%"
      :before-close="cancelFollowDialog">
      <div>
        <el-form :model="assign_form" :rules="follow_rules" ref="assign_form">
          <el-form-item label="协助人" label-width="100px">
            <el-select prop="follow_store_user_id" v-model="assign_form.follow_store_user_id" placeholder="请选择协助人"
              multiple :multiple-limit="6">
              <template v-for="(item, index) in assistStoreUserList">
                <el-option :label="item.realname" :value="item.store_user_id" :key="index">{{ item.realname }}
                </el-option>
              </template>
            </el-select>
          </el-form-item>
          <div style="text-align: center">
            <el-button type="primary" @click="submitAssignUser('assign_form')">确定</el-button>
            <el-button @click="cancelFollowDialog">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="tractVisiable" title="添加跟进记录" :destroy-on-close="true" :close-on-click-modal="false"
      width="40%" :before-close="cancelTractDialog">
      <div>
        <el-form :model="tractForm" label-width="120px">
          <el-form-item label="跟进方式" required>
            <div style="display: flex">
              <template v-for="(item, index) in followStateList">
                <div :key="index" class="followState" :style="
                  follow_state_code === item.follow_state_code
                    ? 'background: #f2f9ff;border-color: #2791ff;color: #2791ff;'
                    : null
                " @click="handleChooseState(item)">
                  {{ item.title }}
                </div>
              </template>
            </div>
          </el-form-item>
          <el-form-item label="跟进结果" required>
            <div>
              <div style="display: flex">
                <div class="resultBox" @click="chooseResult(10)" :style="
                  type === 10
                    ? 'background:#e9f8eb;color:#2BBF3F'
                    : 'color:#333333;background: #f5f5f5;'
                ">
                  <img src="../../../../assets/images/member/stisfied_yes.png" v-if="type === 10" />
                  <img src="../../../../assets/images/member/satisfied_no.png" v-else />
                  满意
                </div>
                <div class="resultBox" @click="chooseResult(20)" :style="
                  type === 20
                    ? 'background:#fff7e5;color:#FFAE01'
                    : 'color:#333333;background: #f5f5f5;'
                ">
                  <img src="../../../../assets/images/member/commonly_yes.png" v-if="type === 20" />
                  <img src="../../../../assets/images/member/commonly_no.png" v-else />
                  一般
                </div>

                <div class="resultBox" @click="chooseResult(30)" :style="
                  type === 30
                    ? 'background:#ffeeeb;color:#FD563A'
                    : 'color:#333333;background: #f5f5f5;'
                ">
                  <img src="../../../../assets/images/member/dissatisfied_yes.png" v-if="type === 30" />
                  <img src="../../../../assets/images/member/dissatisfied_no.png" v-else />
                  不满意
                </div>
              </div>
              <div class="followResultBox" v-if="type === 10">
                <template v-for="(item, index) in followResultType_satisfied">
                  <div :key="index" style="width: 33%">
                    <el-radio :label="item.crm_follow_result_id" v-model="tractForm.crm_follow_result_id">{{
                        item.result_title
                    }}</el-radio>
                  </div>
                </template>
              </div>
              <div class="followResultBox" v-if="type === 20">
                <template v-for="(item, index) in followResultType_commonly">
                  <div :key="index" style="width: 33%">
                    <el-radio :label="item.crm_follow_result_id" v-model="tractForm.crm_follow_result_id">{{
                        item.result_title
                    }}</el-radio>
                  </div>
                </template>
              </div>
              <div class="followResultBox" v-if="type === 30">
                <template v-for="(item, index) in followResultType_dissatisfied">
                  <div :key="index" style="width: 33%">
                    <el-radio :label="item.crm_follow_result_id" v-model="tractForm.crm_follow_result_id">{{
                        item.result_title
                    }}</el-radio>
                  </div>
                </template>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="上传跟进图片">
            <el-upload class="avatar-uploader" action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
              :data="{
                directory: 'AGENT_INFO',
              }" :headers="{
  authorization: token,
}" list-type="picture-card" multiple :file-list="images" :on-success="handleImagesSuccess"
              :on-remove="handleImagesFile" :limit="8">
              <i class="el-icon-plus avatar-uploader-icon" style="background-color: white"></i>
            </el-upload>
            <div style="color: #909399">
              图片格式为jpg或png格式，大小不能超过500KB
            </div>
          </el-form-item>
          <el-form-item label="备注信息">
            <el-input placeholder="请输入备注信息" v-model="tractForm.remarks" type="textarea" style="width: 60%" />
          </el-form-item>
          <el-form-item label="下次跟进时间">
            <el-date-picker v-model="tractForm.next_follow_time" type="datetime" placeholder="选择日期" style="width: 60%"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <div style="text-align: center">
            <el-button type="primary" @click="submitFollowResult" :loading="loading">保存</el-button>
            <el-button @click="cancelTractDialog">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  deleNewCouponReq,//(new)
  deleCouponReq,
  getCouponListReq,
  buyRecord,
  getStorageList,
  getMemberInfo,
  getUserIntegralList,
  getFollowStepList,
  getTractList,
  addTractInfo,
  getFollowStateList,
  getResultLists,
  tagsUpdate,
  updateClientStep,
  delMemberTags,
  getAssistStoreUser,
  getChatList,
  editAssistFollow,
  getNewCouponList,
  getNewCouponListPerson

} from "@/api/member/memberList/index.js";
import moment from "moment";
import { getTagList } from "@/api/member/tabManage/index.js";
import no_user from "../../../../assets/images/no_user.png";
import storage from "good-storage";

import Cookies from "js-cookie";
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return time.getTime() < date;
        },
      },
      chatPickerOptions: {
        disabledDate(time) {
          const date = new Date();
          date.setTime(date.getTime());
          return time.getTime() > date;
        },
      },
      couponList: [], // 优惠券列表
      coupon_type1: require("@/assets/images/marketing/coupon/reduce.png"), // 优惠券图片1
      coupon_type2: require("@/assets/images/marketing/coupon/discount.png"), // 优惠券图片2
      gender_text: {
        10: "男",
        20: "女",
        30: "未知",
      },
      info: {},
      activeName: "first",

      checkCouponType: '1',//优惠券选项时的，人情和通过区别

      buyList: [],
      integralList: [],
      integralTotal: 0,
      storageList: [],
      no_user: no_user,
      followStepList: [],
      tractList: [],
      tractVisiable: false,
      tractForm: {},
      followStateList: [],
      follow_state_code: null,
      type: 10,
      followResultType_satisfied: [],
      followResultType_commonly: [],
      followResultType_dissatisfied: [],
      loading: false,
      arr_tags_name: [],
      tagName: "",
      tagsVisiable: false,
      tagList: [],
      userInfo: {},
      assistStoreUserList: [],
      followVisiable: false,
      assis_store_user_id: [],
      follow_rules: {
        follow_store_user_id: [
          { required: true, message: "请选择人员", trigger: "change" },
        ],
      },
      assign_form: {
        follow_store_user_id: [],
      },
      chatList: [],
      chatData: {},
      chatPage: 1,
      chatLimit: 20,
      chatTotal: 0,
      chatCond: {},
      token: "",
      images: [],
      picList: [],
    };
  },
  props: {
    client_user_id: Number,
    client_system_id: Number,
    client_user_master_id: Number,
    hierarchy_id:Number,
    memberType: String,
    showCoupon: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.userInfo = storage.get("userInfo");
    this.getMemberInfo();
    this.buyRecord();
    this.getAssistStoreUser();
    if (this.showCoupon) {
      this.getCouponList();
    }
    this.token = storage.session.get("token") || Cookies.get("token");
  },
  methods: {
    // 聊天记录
    getChatList() {
      if (this.chatCond.time) {
        this.chatCond.time[0] = moment(this.chatCond.time[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.chatCond.time[1] =
          moment(this.chatCond.time[1]).format("YYYY-MM-DD") + " 23:59:59";
      }
      const data = {
        client_user_id: this.info.client_user_id,
        page: this.chatPage,
        limit: this.chatLimit,
        ...this.chatCond,
      };
      getChatList(data).then((res) => {
        if (res.code === 1) {
          if (res.data.list.length !== 0) {
            this.chatTotal = res.data.total;
            let list = res.data.list.reverse();
            this.chatList = list;
            let obj = {};
            list.forEach((item) => {
              const time = item.create_time.split(" ")[0];
              if (item.is_image === 10) {
                item.content = item.content.split(/[\r\b\t\n\f]/g);
              }
              if (!obj[time]) {
                obj[time] = [];
                obj[time].push(item);
              } else {
                obj[time].push(item);
              }
            });
            this.chatData = obj;
            this.scrollToBottom();
          } else if (res.data.list.length === 0) {
            this.chatList = [];
            this.chatData = {};
          }
        }
      });
    },
    searchData() {
      if (this.chatCond.time) {
        const time = moment(this.chatCond.time[0]).format("YYYY-MM-DD");
      }
      this.chatPage = 1;
      this.getChatList();
    },
    handleReset() {
      this.chatPage = 1;
      this.chatCond = {};
      this.getChatList();
    },
    handleScroll() {
      if (
        this.$refs.chatContent.scrollTop === 0 &&
        this.chatList.length !== this.chatTotal &&
        this.chatList.length !== 0
      ) {
        this.chatPage = this.chatPage + 1;
        const data = {
          client_user_id: this.info.client_user_id,
          page: this.chatPage,
          limit: this.chatLimit,
          ...this.chatCond,
        };
        getChatList(data).then((res) => {
          if (res.code === 1) {
            if (res.data.list.length !== 0) {
              res.data.list = res.data.list.map((item) => {
                if (item.is_image === 10) {
                  item.content = item.content.split(/[\r\b\t\n\f]/g);
                }
                return item;
              });
              if (this.chatList.length !== 0) {
                const arr = res.data.list.reverse();
                this.chatList = JSON.parse(
                  JSON.stringify(arr.concat(this.chatList))
                );
              } else {
                this.chatList = res.data.list.reverse();
              }
              let obj = {};

              this.chatList.forEach((item) => {
                const time = item.create_time.split(" ")[0];

                if (!obj[time]) {
                  obj[time] = [];
                  obj[time].push(item);
                } else {
                  obj[time].push(item);
                }
              });
              this.chatData = obj;
            }
          }
        });
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const height = this.$refs.chatContent.scrollHeight;
        this.$refs.chatContent.scrollTop = height;
      })
    },
    /*
     *  上传跟进图片
     */
    handleImagesSuccess(res, file) {
      this.images.push({
        url: res.data,
      });
      this.picList.push(res.data);
    },
    /*
     *  删除跟进图片
     */
    handleImagesFile(res, file) {
      this.images = this.images.filter((item) => {
        return item.url !== res.url;
      });
      this.picList = this.picList.filter((item) => {
        return item !== res.url;
      });
    },
    // 去订单
    toOrder(item) {
      console.log(item,"items")
      const routes = storage.get("name_keys");
      // 判断权限跳转
      if (routes["/order/offlineOrder/salesOrder"]) {
        this.$router.replace({
          name: "offlineOrderSalesOrder",
          query: {
            t: Date.now(),
          },
          params: {
            type: "detail",
            key: item.order_id,//跳转销售订单页面（待确认）
          },
        });
        return;
      }

      if (routes["/order/offlineOrder/mySalesOrder"]) {
        this.$router.replace({
          name: "offlineOrderMySalesOrder",
          query: {
            t: Date.now(),
          },
          params: {
            type: "detail",
            key: item.order_id,
          },
        });
      }
    },

    // 作废优惠券（新）*******
    handleDele(item) {
      this.$confirm("确定要作废该优惠券吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleNewCouponReq({
            client_user_master_id: this.client_user_master_id,
            coupon_sn: item.coupon_sn,
          }).then((res) => {
            if (res.code === 1) {
              this.$message.success("作废成功");
              this.getCouponList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消作废",
          });
        });
    },

    // 获取优惠券列表(更正为新的,通用的)
    getCouponList() {
      getNewCouponList({
        client_system_id: this.client_system_id,
        client_user_master_id: this.client_user_master_id,
        hierarchy_id:this.hierarchy_id

      }).then((res) => {
        console.log(res, "res,会员优惠券列表");
        if (res.code === 1) {
          this.couponList = res.data;
        }
      });
    },

    //  获取协助人列表
    getAssistStoreUser() {
      getAssistStoreUser({
        client_user_id: this.client_user_id,
        is_all: 0,
      }).then((res) => {
        if (res.code === 1) {
          this.assistStoreUserList = res.data;
        }
      });
    },
    /*
     *  获取标签分类数据
     */
    getTagList() {
      getTagList({ hierarchy_id: this.userInfo.hierarchy_id }).then((res) => {
        if (res.code === 1) {
          this.tagList = res.data.map((item) => {
            item.value = item.tag_name;
            return item;
          });
        }
      });
    },
    handleAddTags() {
      this.tagsVisiable = true;
      this.getTagList();
    },
    handleCloseTags(item) {
      this.$confirm("是否确定删除改标签？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delMemberTags({
          client_user_id: this.client_user_id,
          client_tags_id: item.e_client_tags_id,
        }).then((res) => {
          if (res.code === 1) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.getMemberInfo();
          }
        });
      });
    },
    submitTags() {
      if (this.tagName === "") {
        this.$message({
          message: "请输入或者选择标签",
          type: "warning",
        });
      } else {
        let tag_name = [];
        if (this.tagList.some((item) => item.tag_name === this.tagName)) {
          tag_name = this.tagList
            .filter((item) => item.tag_name === this.tagName)
            .map((item) => {
              return {
                value: item.tag_name,
                key: item.e_client_tags_id,
              };
            });
        } else {
          tag_name = [{ value: this.tagName, key: 0 }];
        }
        const data = {
          client_user_id: this.client_user_id,
          tag_name: tag_name,
        };
        tagsUpdate(data).then((res) => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.tagsVisiable = false;
            this.getMemberInfo();
          }
        });
      }
    },
    cancelTagsDialog() {
      this.tagsVisiable = false;
      this.tagName = "";
    },
    updateClientStep(item, i) {
      if (this.memberType === "waitAssignMember") {
        this.$message({
          message: "待分配会员不可操作",
          type: "info",
        });
      } else {
        const data = {
          client_user_id: this.client_user_id,
          crm_follow_step_id:
            i === 0 && item.crm_follow_step_id === this.info.follow_step_id
              ? 0
              : item.crm_follow_step_id,
        };
        updateClientStep(data).then((res) => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.getMemberInfo();

            this.getTractList();
          }
        });
      }
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.tagList;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    cancelTractDialog() {
      this.follow_state_code = null;
      this.type = 10;
      this.tractVisiable = false;
      this.tractForm = {};
    },
    getMemberInfo() {
      const data = {
        client_user_id: this.client_user_id,
      };
      if (this.client_system_id && this.client_system_id !== 0) {
        data.client_system_id = this.client_system_id;
      }
      getMemberInfo(data).then((res) => {
        console.log(res, "获取用户信息");
        if (res.code === 1) {
          this.info = res.data.user_info;
          this.arr_tags_name = res.data.user_info.arr_tags_name;
          if (res.data.user_info.assist_store_user_id) {
            this.assign_form.follow_store_user_id =
              res.data.user_info.assist_store_user_id.split(",");
          }
          this.getFollowStepList();
        }
      });
    },
    goBack() {
      this.$emit("hide");
    },
    handleTab() {
      if (this.activeName === "first") {
        this.buyRecord();
      } else if (this.activeName === "second") {
        this.userIntegralList();
      } else if (this.activeName === "third") {
        this.getStorageList();
      } else if (this.activeName === "four") {
        this.getFollowStepList();
        this.getTractList();
        this.getFollowStateList();
        this.getFollowResultList();
      } else if (this.activeName === "five") {
        this.chatPage = 1;
        this.getChatList();
      }
    },
    getFollowStepList() {
      getFollowStepList().then((res) => {
        if (res.code === 1) {
          if (this.info.follow_step_id) {
            let index = null;
            res.data.map((item, i) => {
              item.isStep = false;
              if (item.crm_follow_step_id === this.info.follow_step_id) {
                index = i;
              }
              return item;
            });
            for (var i = 0; i <= index; i++) {
              res.data[i].isStep = true;
            }
          }

          this.followStepList = res.data;
          this.$set(this.followStepList);
        }
      });
    },
    /*
     *  购买记录
     */
    buyRecord() {
      buyRecord({ client_user_id: this.client_user_id }).then((res) => {
        if (res.code === 1) {
          res.data.list = res.data.list.map((item) => {
            item.create_time = moment(item.create_time * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            return item;
          });
          this.buyList = res.data.list;
        }
      });
    },
    /*
     *  积分记录
     */
    userIntegralList() {
      getUserIntegralList({ client_user_id: this.client_user_id }).then(
        (res) => {
          if (res.code === 1) {
            this.integralList = res.data.list;
            this.integralTotal = res.data.total;
          }
        }
      );
    },
    /*
     *  积分记录，分页操作
     */
    handleIntCurrentChange(val) {
      getUserIntegralList({
        client_user_id: this.client_user_id,
        page: val,
      }).then((res) => {
        if (res.code === 1) {
          this.integralList = res.data.list;
          this.integralTotal = res.data.total;
        }
      });
    },
    /*
     *  储值金记录
     */
    getStorageList() {
      getStorageList({ client_user_id: this.client_user_id }).then((res) => {
        if (res.code === 1) {
          this.storageList = res.data.list;
        }
      });
    },
    getTractList() {
      getTractList({ client_user_id: this.client_user_id }).then((res) => {
        if (res.code === 1) {
          this.tractList = res.data.list.map((item) => {
            // if (item.pic) {
            //   item.pic = item.pic.split(",");
            // }
            return item;
          });
        }
      });
    },
    handleAddFollow() {
      this.tractVisiable = true;
    },
    getFollowStateList() {
      getFollowStateList().then((res) => {
        if (res.code === 1) {
          this.followStateList = res.data;
        }
      });
    },
    handleChooseState(item) {
      this.follow_state_code = item.follow_state_code;
    },
    chooseResult(type) {
      this.type = type;
    },
    /*
     * 跟进结果
     */
    getFollowResultList() {
      getResultLists().then((res) => {
        if (res.code === 1) {
          this.followResultType_satisfied = res.data.filter(
            (item) => item.result_type === 10
          );
          this.followResultType_commonly = res.data.filter(
            (item) => item.result_type === 20
          );
          this.followResultType_dissatisfied = res.data.filter(
            (item) => item.result_type === 30
          );
        }
      });
    },
    toOrderDetails(row) {
      this.$router.push({
        name: "offlineOrderSalesOrder",
        query: {
          t: Date.now(),
        },
        params: {
          order_id: row.order_id,
        },
      });
    },
    /*
     *  新增跟进记录
     */
    submitFollowResult() {
      if (!this.follow_state_code) {
        this.$message({
          type: "warning",
          message: "请选择跟进方式",
        });
      } else if (!this.tractForm.crm_follow_result_id) {
        this.$message({
          type: "warning",
          message: "请选择跟进结果",
        });
      } else {
        if (this.tractForm.next_follow_time) {
          this.tractForm.next_follow_time = moment(
            this.tractForm.next_follow_time
          ).format("YYYY-MM-DD HH:mm:ss");
        }
        const data = {
          ...this.tractForm,
          follow_state_code: this.follow_state_code,
          client_user_id: this.client_user_id,
        };
        if (this.picList.length !== 0) {
          data.pic = this.picList.join(",");
        }
        addTractInfo(data).then((res) => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.cancelTractDialog();
            this.getTractList();
            this.images = [];
            this.picList = [];
          }
        });
      }
    },
    cancelFollowDialog() {
      this.followVisiable = false;
    },
    addAssignUser() {
      this.followVisiable = true;
    },

    handleCoupon() {
      if (this.checkCouponType == '1') {
        getNewCouponList({
          client_system_id: this.client_system_id,
          client_user_master_id: this.client_user_master_id,
            hierarchy_id:this.hierarchy_id
        }).then((res) => {
          if (res.code === 1) {
            this.couponList = res.data;
          }
        });

      } else {
        getNewCouponListPerson({
          client_system_id: this.client_system_id,
          client_user_master_id: this.client_user_master_id,
          hierarchy_id:this.hierarchy_id
        }).then(res => {
          if (res.code === 1) {
            this.couponList = res.data;
          }
        })
      }
    },
    /*
     *  编辑协助
     */
    submitAssignUser() {
      this.$refs.assign_form.validate((valid) => {
        if (valid) {
          const data = {
            arr_client_user_id: [this.client_user_id],
            assis_store_user_id: this.assign_form.follow_store_user_id,
          };
          editAssistFollow(data).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.activeName = "four";
              this.cancelFollowDialog();
              this.getMemberInfo();
              this.getFollowStepList();
              this.getTractList();
              this.getFollowStateList();
              this.getFollowResultList();
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.coupon-list {
  background: rgba(245, 246, 250, 1);
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  position: relative;

  .coupon {
    width: 450px;
    height: 186px;
    padding: 20px;
    background: white;
    box-sizing: border-box;
    display: flex;
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;

    &-btn {
      position: absolute;
      right: 20px;
      bottom: 20px;
      width: 88px;
      height: 32px;
    }

    &-btn2 {
      position: absolute;
      left: 179px;
      bottom: 18px;
      color: #1991ff;
      font-size: 12px;
      cursor: pointer;
    }

    &-label {
      position: absolute;
      right: 0;
      top: 0;
      width: 64px;
      height: 28px;
      border-radius: 0 6px 0 10px;
      opacity: 1;
      color: white;
      text-align: center;
      line-height: 28px;
    }




    .label1 {
      background: rgba(246, 198, 81, 1);
    }

    .label2 {
      background: rgba(25, 145, 255, 1);
    }

    .label3 {
      background: rgba(144, 147, 153, 1);
    }

    &-left {
      width: 140px;
      height: 147px;
      background-size: 100% 100%;
      color: white;

      &-money {
        font-size: 35px;
        font-weight: bold;
        text-align: center;
        margin-top: 45px;
      }

      &-txt {
        font-size: 16px;
      }

      &-desc {
        text-align: center;
        margin-top: 10px;
        font-size: 16px;
      }
    }

    &-right {
      flex: 1;
      margin-left: 20px;

      &-title {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        margin-top: 10px;
      }

      &-time {
        color: #909399;
        font-size: 14px;
        margin-top: 3px;
        display: flex;
      }

      &-create {
        color: #565656;
        font-size: 14px;
        margin-top: 3px;
      }

      &-creator {
        color: #565656;
        font-size: 14px;
        margin-top: 3px;
      }
    }
  }
}
    .coupon-unuse {
        position:absolute;
        top:0;
        right:0;
        width: 64px;
        height: 28px;
        background: rgba(246, 198, 81, 1);
        color:#fff;
        text-align: center;
        line-height: 28px;
        border-radius: 0 11px 0 11px;
        font-size: 14px;
        font-weight: 400;
    }

    .coupon-used {
        position:absolute;
        top:0;
        right:0;
        width: 64px;
        height: 28px;
        background:#1991FF;
        color:#fff;
        text-align: center;
        line-height: 28px;
        border-radius: 0 11px 0 11px;
        font-size: 14px;
        font-weight: 400;
    }

    .coupon-expires {
        position:absolute;
        top:0;
        right:0;
        width: 64px;
        height: 28px;
        background:#909399;
        color:#fff;
        text-align: center;
        line-height: 28px;
        border-radius: 0 11px 0 11px;
        font-size: 14px;
        font-weight: 400;
    }

    .looks-order {
      position: absolute;
      color:#1991FF;
      font-size: 14px;
      font-weight: 400;
      bottom: 20px;
      right:0;
      cursor: pointer;
    }
.head {
  width: 20%;
  text-align: center;
  font-size: 14px;
  color: #909399;

  .head_img {
    margin: 0 auto;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover !important;
    border: 1px solid #e8e8e8;
  }

  .consume {
    margin-top: 5px;
  }

  .consume_money {
    margin-top: 10px;
  }
}

.infoLine {
  font-size: 14px;
  display: flex;
  margin-bottom: 15px;
}

.infoTitle {
  font-size: 14px;
  width: 200px;
  color: #909399;
}

.infoAbout {
  width: 250px;
}

.mline {
  margin-top: 23px;
  width: 100%;
  height: 1px;
  background-color: #e8e8e8;
}

.tabs {
  display: flex;
  padding-left: 33px;
  height: 50px;

  .addTab {
    margin: auto 0 auto 19px;
    width: 82px;
    height: 28px;
    background: #2791ff;
    border: 1px solid #2791ff;
    border-radius: 6px;
    text-align: center;
    line-height: 28px;
    color: white;
  }

  .addTab:hover {
    cursor: pointer;
  }

  .tagsBox {
    margin: auto 0 auto 5px;
    padding-left: 8px;
    padding-right: 8px;
    width: auto;
    height: 28px;
    background: rgba(39, 145, 255, 0.06);
    border: 1px solid #2791ff;
    border-radius: 6px;
    font-size: 14px;
    color: #2791ff;
    text-align: center;
    line-height: 28px;
  }
}

.record {
  margin: 10px 0 0 0;
  padding-top: 15px;
  border: 1px solid #e8e8e8;
  border-radius: 7px;
}

.followStep {
  margin-bottom: 20px;
}

.followStep li {
  padding: 0px 20px 0 30px;
  line-height: 40px;
  background: #c4cfdf;
  display: inline-block;
  color: white;
  position: relative;
  margin-right: 10px;
}

.followStep li:hover {
  cursor: pointer;
}

.hasStep:after {
  border-left-color: #2791ff !important;
}

.followStep li:after {
  content: "";
  display: block;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #c4cfdf;
  position: absolute;
  right: -20px;
  top: 0;
  z-index: 10;
}

.followStep li:before {
  content: "";
  display: block;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid white;
  position: absolute;
  left: 0px;
  top: 0;
}

.followStep li:first-child {
  border-radius: 4px 0 0 4px;
}

.followStep li:first-child:before {
  border: none;
}

.followStep li:last-child {
  border-radius: 0 4px 4px 0;
}

.followStep li:last-child:after {
  border: none;
}

.title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.followState {
  margin-right: 10px;
  width: 82px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #c4cfdf;
  border-radius: 6px;
  text-align: center;
  line-height: 36px;
  color: #909399;
}

.followState:hover {
  cursor: pointer;
}

.resultBox {
  margin-right: 11px;
  padding: 0px 28px;
  background: #f5f5f5;
  border-radius: 6px;

  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
}

.resultBox:hover {
  cursor: pointer;
}

.followResultBox {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 21px 0 21px 20px;
  width: 72%;
  background: #f9f9f9;
  border-radius: 6px;
}

.tract {
  width: 50%;

  .tractStateImg {
    margin-right: 16px;
    width: 22px;
    height: 22px;

    img {
      width: 22px;
      height: 22px;
      border-radius: 50%;
    }
  }

  .tractInfo {
    margin: 10px 0 20px 35px;
    padding: 15px 0 15px 35px;
    background: #f5f6fa;
    border-radius: 6px;

    .resultType {
      margin-right: 8px;
      width: 20px;
      height: 20px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.editFollow:hover {
  cursor: pointer;
}

.chatBox {
  padding-bottom: 20px;
  margin-bottom: 10px;
  width: 99%;
  background: #f5f6fa;
  border-radius: 6px;

  .chatTime {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    height: 40px;
  }

  .chatLine {
    width: 100%;
    height: 1px;
    background: #e8e8e8;
  }

  .avatar {
    width: 34px;
    height: 34px;
    border-radius: 3px;

    img {
      width: 34px;
      height: 34px;
      border-radius: 3px;
    }
  }

  .chatContentBox {
    margin-top: 15px;
    position: relative;
    padding: 10px;
    max-width: 224px;
    border-radius: 5px;
    font-size: 14px;
    word-wrap: break-word;
  }

  .user {
    background: #ffffff;
    color: #333333;
  }

  .user::after {
    content: "";
    position: absolute;
    right: 100%;
    top: 10px;
    background-color: #cdcdcd;
    width: 0px;
    height: 0px;
    border-top: 4px solid #f7f7f7;
    border-right: 5px solid #ffffff;
    border-bottom: 4px solid #f7f7f7;
    border-width: 0.5px solid #b6b6b6;
  }

  .store_user {
    background: #4a8ff6;
    color: white;
  }

  .store_user:before {
    content: "";
    position: absolute;
    left: 100%;
    top: 10px;
    background-color: #cdcdcd;
    color: #4a8ff6;
    width: 0px;
    height: 0px;
    border-top: 4px solid #f7f7f7;
    border-left: 5px solid #4a8ff6;
    border-bottom: 4px solid #f7f7f7;
  }
}

/deep/ .el-upload--picture-card {
  width: 70px;
  height: 70px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 70px;
  height: 70px;
}

/deep/.avatar-uploader .el-upload {
  width: 70px;
  height: 70px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

// /deep/ .el-upload--picture-card {
//   line-height: 115px !important;
// }
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
}

/deep/ .avatarOther {
  width: 70px;
  height: 70px;
  display: block;
}
</style>
