import { post } from '@/utils/request';

// 退货反确认删除
const resetRefundOrderDelReq = (data) => post({
  url: '/store/order.Refund/resetRefundOrderDel',
  data: {
    ...data,
  },
});
// 回收订单反确认详情
const resetRecycleDetailsReq = (data) => post({
  url: '/store/order.Recycle/resetRecycleDetails',
  data: {
    ...data,
  },
});

// 退货单反确认确定
const resetRefundOrderReq = (data) => post({
  url: '/store/order.Refund/resetRefundOrder',
  data: {
    ...data,
  },
});
// 退货反确认详情
const resetRefundOrderDetailReq = (data) => post({
  url: '/store/order.Refund/resetRefundOrderDetail',
  data: {
    ...data,
  },
});
// 回收订单反确认删除
const recoverDeleReq = (data) => post({
  url: '/store/order.Recycle/resetRecycleOrderDelete',
  data: {
    ...data,
  },
});

// 获取订单列表
const getListReq = (cond) => post({
  // url: '/store/order.Order/list',
  url: '/store/order.Order/orderList',
  data: {
    ...cond,
  },
});

// 获取我的订单列表
const getMyListReq = (cond) => post({
  url: '/store/order.Order/myOrderList',
  data: {
    ...cond,
  },
});

// 获取订单详情
const getOrderInfoReq = (order_id, isHaveRaw) => post({
  url: '/store/order.Order/getOrderInfo',
  data: {
    order_id,
    isHaveRaw,
  },
});

// 获取退货订单列表
const getRefundListReq = (cond) => post({
  url: '/store/order.Refund/list',
  data: {
    ...cond,
  },
});

// 退货单-货品明细
const getDetailReq = (refund_order_id) => post({
  url: '/store/order.Refund/getDetail',
  data: {
    refund_order_id,
  },
});

// 退货单基本信息
const getBasicInfoReq = (refund_order_id) => post({
  url: '/store/order.Refund/getBasicInfo',
  data: {
    refund_order_id,
  },
});

// 获取赎回订单列表
const getRedemptionListReq = (cond) => post({
  url: '/store/order.Redemption/list',
  data: {
    ...cond,
  },
});

// 获取我的赎回订单列表
const getMyRedemptionListReq = (cond) => post({
  url: '/store/order.Redemption/myList',
  data: {
    ...cond,
  },
});

// 获取订单货品表头
const getOrderGoodsTitleReq = (config_type) => post({
  url: '/store/common.Select/getOrderGoodsTitle',
  data: {
    config_type,
  },
});

// 获取赎回订单货品表头
const getRecOrderGoodsTitleReqMoon = () => post({
  url: '/store/common.Select/getRecOrderGoodsTitleMoon',
});
const getRecOrderGoodsTitleReq = () => post({
  url: '/store/common.Select/getRecOrderGoodsTitle',
});
// 获取回收单列表
const getRecycleOrderlistReq = (cond) => post({
  url: '/store/order.Recycle/getRecycleOrderlist',
  data: {
    ...cond,
  },
});

// 获取我的回收单列表
const getMyRecycleOrderlistReq = (cond) => post({
  url: '/store/order.Recycle/getMyRecycleOrderlist',
  data: {
    ...cond,
  },
});

// 回收订单详情
const getRecycleOrderData = (recycle_order_id) => post({
  url: '/store/order.Recycle/getRecycleOrderData',
  data: {
    recycle_order_id,
  },
});

// 提单列表
const mountOrderListReq = () => post({
  url: '/store/order.Order/mountOrderList',
});

// 赎回单货品明细
const getRedemptionDetailReq = (redemption_order_id) => post({
  url: '/store/order.Redemption/getDetail',
  data: {
    redemption_order_id,
  },
});

// 赎回单基本信息
const getRedemptionBasicInfoReq = (redemption_order_id) => post({
  url: '/store/order.Redemption/getBasicInfo',
  data: {
    redemption_order_id,
  },
});

// 云打印
const printOrderReq = (params) => post({
  url: '/store/order.Order/printOrder',
  data: {
    ...params,
  },
});

// 云打印回收单
const printRecycleOrderReq = (params) => post({
  url: '/store/order.Order/printRecycleOrder',
  data: {
    ...params,
  },
});

// 云打印回收单
const printRefundOrderReq = (params) => post({
  url: '/store/order.Order/printRefundOrder ',
  data: {
    ...params,
  },
});

// 订单统计
const orderCountReq = (params) => post({
  url: '/store/order.Order/orderCount',
  data: {
    ...params,
  },
});

// 我的订单统计
const myOrderCountReq = (params) => post({
  url: '/store/order.Order/myOrderCount',
  data: {
    ...params,
  },
});

// 获取旧料
const getOrderInfoRawReq = (order_id) => post({
  url: '/store/order.Order/getOrderInfoRaw',
  data: {
    order_id,
  },
});


// 我的退货订单
const getMyRefundListReq = (params) => post({
  url: '/store/order.Refund/myList',
  data: {
    ...params,
  },
});

// 销售订单导出
const orderExportExcelReq = (params) => post({
  url: '/store/order.Order/exportExcel',
  responseType: 'blob',
  data: {
    ...params,
  },
});

// 回收单导出
const recycleOrderExportExcelReq = (params) => post({
  url: '/store/order.Recycle/recycleExportExcel',
  data: {
    ...params,
  },
});

// 自定义打印
const getTemplateContentReq = (params) => post({
  url: '/store/goods.PrintLocalTemplate/getTemplateContent',
  data: {
    ...params,
  },
});

// 补开订单
const repairOrderReq = (params) => post({
  url: '/store/order.Order/repairOrder',
  data: {
    ...params,
  },
});

// 销售订单打印数据
const getOrderPrintReq = (params) => post({
  url: '/store/order.Order/orderPrint',
  data: {
    ...params,
  },
});

// 退货单打印数据
const getRefundPrintReq = (params) => post({
  url: '/store/order.Refund/refundPrint',
  data: {
    ...params,
  },
});

// 回收单打印数据
const getRecoveryPrintReq = (params) => post({
  url: '/store/order.Recycle/recoveryPrint',
  data: {
    ...params,
  },
});

// 赎回单打印数据
const getRedemptionPrintReq = (params) => post({
  url: '/store/order.Redemption/redemptionPrint',
  data: {
    ...params,
  },
});

// 删除订单
const resetOrderDelete = (params) => post({
  url: '/store/order.Order/resetOrderDelete',
  data: {
    ...params,
  },
});

// 赎回单反确认删除
const delRedemptionOrderReq = (params) => post({
  url: '/store/order.Redemption/delRedemptionOrder',
  data: {
    ...params,
  },
});

// 赎回反确认详情
const resetRedemptionDetailsReq = (params) => post({
  url: '/store/order.Redemption/resetRedemptionDetails',
  data: {
    ...params,
  },
});

// 赎回反确认编辑
const resetRedemptionOrderReq = (params) => post({
  url: '/store/order.Redemption/resetRedemptionOrder',
  data: {
    ...params,
  },
});

//-----------定金单
//定金单搜索配置
const searchpz = (params) => post({
  url: '/store/common.search/index',
  data: {
    ...params,
  },
});

//定金单列表
const frontList= params => post({
  url:'/store/order.DepositSingle/list',
  data:{
    ...params
  }
})

//定金单导出
const exportForont=params=>post({
  url:'/store/order.DepositSingle/export',
  responseType: 'blob',
  data:{
    ...params
  }
})

//创建定金单
const createFront=params=>post({
  url:'/store/order.DepositSingle/create',
  data:{
    ...params
  }
})

//定金单详情页
const frontDetail=params=>post({
  url:'/store/order.DepositSingle/detail',
  data:{
    ...params
  }
})

//定金审核
const depositSingle=params=>post({
  url:'/store/order.DepositSingle/audit',
  data:{
    ...params
  }
})

//更新定金单
const updateSingle=params=>post({
  url:'/store/order.DepositSingle/update',
  data:{
    ...params
  }
})

//删除定金单
const deleteSingle=params=>post({
  url:'/store/order.DepositSingle/delete',
  data:{
    ...params
  }
})

//详情页导出
const detailExport=params=>post({
  url:'/store/order.DepositSingle/detailExport',
  responseType: 'blob',
  data:{
    ...params
  }
})

//可退定金单
const singleBackList=data=>post({
  url:'/store/order.DepositSingle/singleBackList',
  data,
})


export {
  singleBackList,
  detailExport,
  deleteSingle,
  updateSingle,
  depositSingle,
  frontDetail,
  createFront,
  exportForont,
  searchpz,
  frontList,
  resetRedemptionOrderReq,
  resetRedemptionDetailsReq,
  delRedemptionOrderReq,
  resetRefundOrderDelReq,
  resetRefundOrderReq,
  resetRefundOrderDetailReq,
  resetRecycleDetailsReq,
  recoverDeleReq,
  resetOrderDelete,
  getRedemptionPrintReq,
  getRecoveryPrintReq,
  getRefundPrintReq,
  getOrderPrintReq,
  repairOrderReq,
  getTemplateContentReq,
  recycleOrderExportExcelReq,
  orderExportExcelReq,
  getMyRecycleOrderlistReq,
  myOrderCountReq,
  getMyListReq,
  getMyRedemptionListReq,
  getMyRefundListReq,
  getOrderInfoRawReq,
  orderCountReq,
  printRefundOrderReq,
  printRecycleOrderReq,
  getListReq,
  getOrderInfoReq,
  getRefundListReq,
  getDetailReq,
  getBasicInfoReq,
  getRedemptionListReq,
  getOrderGoodsTitleReq,
  getRecOrderGoodsTitleReq,
  getRecOrderGoodsTitleReqMoon,
  getRecycleOrderlistReq,
  mountOrderListReq,
  getRedemptionDetailReq,
  getRedemptionBasicInfoReq,
  getRecycleOrderData,
  printOrderReq,
};
